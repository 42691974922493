import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {useNavigate, useParams} from "react-router-dom";
import {Selection} from "../../types";
import {cacheableAxios} from "../../funcs/cacheableAxios";
import UserAvatar from "../UserAvatar";
import {timeAgoTextNew} from "../../funcs/time";
import AnimeElementIdsBlock from "../AnimeElementIdsBlock";
import UserEmoji from "../UserEmoji";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import TogglerButton from "../TogglerButton";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import axios from "../../core/axios";
import Error from "../Error";

const SelectionPage = () => {
    let [err, setErr] = useState('')
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let nav = useNavigate()
    let params = useParams()

    let [loading, setLoading] = useState(true)
    let [info, setInfo] = useState<Selection>()

    let [saved, setSaved] = useState(false)

    async function changeSaved(set: boolean) {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        await axios({
            url: `selections/favourites/${info?.id}`,
            method: set ? 'put' : 'delete'
        }).then(r => setSaved(set)).catch(e => {
            if (typeof e?.response?.data?.message === 'object') {
                setErr(e?.response?.data?.message?.[0])
            } else {
                setErr(e?.response?.data?.message)
            }
        })
    }

    async function fetchData() {
        setLoading(false)
        let infoO = await cacheableAxios({
            method: 'get',
            url: `selections/id/${params.id}`
        }, 60 * 5)
            .then(r => r)
            .catch(ignored => undefined)

        if (infoO) {
            setInfo(infoO)
            setLoading(false)
        }

        await axios({
            url: `selections/favourites/me/${infoO.id}`
        }).then(r => setSaved(true)).catch(e => setSaved(false))
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div style={{padding: "80px 15px 20px 15px"}}>
            <Error message={err} setMessage={setErr}/>

            <div className="auto_fade_top_bar selection_top_bar">
                <div className="auto_fade_top_bar_back auto_fade_top_bar_part" onClick={() => nav(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>

                <div className={"selection_user upper_page_header_bg_el"} onClick={() => nav(`/profile/${info?.user?.username}`)}>
                    <div className="selection_user_info">
                        <p className={info?.user?.sub ? 'username_sub' : ''}>
                            {info?.user?.username}
                            <UserEmoji user={info?.user}/>
                        </p>
                        <p>Автор коллекции</p>
                    </div>
                    <UserAvatar user={info?.user}/>
                </div>
            </div>


            <div className="selection_banner" style={{
                backgroundImage: `url('${'https://s2.anitype.site/media/selection/' + info?.id}')`
            }}></div>

            <div className="selection_title_block">
                <div>
                    <p className="selection_title">{info?.title}</p>
                    <p className="selection_time">опубликована {timeAgoTextNew(info?.createdAt ?? '')}</p>
                </div>
                <TogglerButton title={'Сохранить'} active={saved} setActive={changeSaved} styles={{
                    width: 'max-content',
                    height: 30
                }}/>
            </div>

            <div className="selection_comments_button" onClick={() => nav(`/comments/selection/${info?.id}`)}>
                <div>
                    <p>Комментарии</p>
                    <p>Поделитесь мнением о подборке с другими пользователями</p>
                </div>
                <FontAwesomeIcon icon={faAngleRight}/>
            </div>
            <p className="selection_desc">
                {info?.text}
            </p>
            {/*<div className="selection_desc">{*/}
            {/*    info?.text.split('\n').map(e => <p>{e}</p>)*/}
            {/*}</div>*/}

            <AnimeElementIdsBlock ids={info?.items.map(e => +e.itemId) ?? []} limit={10} pagination={true} blockStyles={{
                padding: '15px 30px 0 0'
            }} hideTab={true}/>
        </div>
    );
};

export default SelectionPage;