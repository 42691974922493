import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons/faHouse";
import {faCompass} from "@fortawesome/free-solid-svg-icons/faCompass";
import {faBookmark} from "@fortawesome/free-solid-svg-icons/faBookmark";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {useNavigate} from "react-router-dom";
import {faMugHot} from "@fortawesome/free-solid-svg-icons/faMugHot";
import {faPersonThroughWindow} from "@fortawesome/free-solid-svg-icons/faPersonThroughWindow";
import {faTv} from "@fortawesome/free-solid-svg-icons/faTv";
import {isUsingIphoneAndPWA} from "../funcs/tech";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons/faNewspaper";
import {faFaceSmile} from "@fortawesome/free-solid-svg-icons";
import ConinueWatchNavBar from "./ConinueWatchNavBar";
import AdBlock from "./yandex/AdBlock";

type TabName = 'home' | 'streams' | 'my' | 'profile' | 'feed' | 'event'

const Tabs: {
    name: TabName,
    text: string,
    link: string,
    iconComponent: any
}[] = [
    {
        name: 'home',
        text: 'Главная',
        link: '/',
        iconComponent: (isActive: boolean) => {
            return (<FontAwesomeIcon icon={faHouse} className={isActive ? "tab_svg_icon tab_svg_icon_active" : "tab_svg_icon"} />)
        }
    },
    // {
    //     name: 'event',
    //     text: 'Событие',
    //     link: '/event',
    //     iconComponent: (isActive: boolean) => {
    //         return (<FontAwesomeIcon icon={faFaceSmile} className={isActive ? "tab_svg_icon tab_svg_icon_active" : "tab_svg_icon"} />)
    //     }
    // },
    {
        name: 'feed',
        text: 'Новое',
        link: '/feed',
        iconComponent: (isActive: boolean) => {
            return (<FontAwesomeIcon icon={faNewspaper} className={isActive ? "tab_svg_icon tab_svg_icon_active" : "tab_svg_icon"} />)
        }
    },
    // {
    //     name: 'streams',
    //     text: 'Стримы',
    //     link: '/streams',
    //     iconComponent: (isActive: boolean) => {
    //         return (<FontAwesomeIcon icon={faTv} className={isActive ? "tab_svg_icon tab_svg_icon_active" : "tab_svg_icon"} />)
    //     }
    // },
    {
        name: 'my',
        text: 'Библиотека',
        link: '/my',
        iconComponent: (isActive: boolean) => {
            return (<FontAwesomeIcon icon={faLayerGroup} className={isActive ? "tab_svg_icon tab_svg_icon_active" : "tab_svg_icon"} />)
        }
    },
]

function getDefaultActive(): TabName {
    let l = window.location.href
    if (l.includes('/my')) {
        return 'my'
    }
    if (l.includes('/streams')) {
        return 'streams'
    }
    // if (l.includes('/profile')) {
    //     return 'profile'
    // }
    return 'home'
}

const NavBar = ({setSearchOpen}: any) => {
    let [active, setActive] = useState<TabName>(getDefaultActive())

    let nav = useNavigate()

    return (
        <div className={isUsingIphoneAndPWA() ? "nav_bar_cont nav_bar_cont_iphone" : "nav_bar_cont"}>
            {/*<div className="twalert">Введуться тех. работы. Данные с 13:15 не сохраняются</div>*/}

            <ConinueWatchNavBar/>

            <div className={isUsingIphoneAndPWA() ? "nav_bar_background iphone" : "nav_bar_background"}>
                <div className={"nav_bar"}>
                    {
                        Tabs.map((el, index) =>
                            <div key={index} onClick={() => {
                                setSearchOpen(false)
                                setActive(el.name)
                                nav(el.link)
                            }} className={active === el.name ? "active_tab to" : "to"} style={{
                                width: `${100 / Tabs.length - 2}%`
                            }}>
                                <div className="tab_icon">
                                    {el.iconComponent(el.name === active)}
                                </div>
                                <p>{el.text}</p>
                            </div>)
                    }
                </div>
                <AdBlock className={"nav_ads_cont"} renderTo={"yandex_rtb_R-A-2987609-13"} blockId={"R-A-2987609-13"}/>

            </div>

        </div>

    );
};

export default NavBar;