import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faSignature} from "@fortawesome/free-solid-svg-icons/faSignature";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons/faTrashCan";
import {faBroom} from "@fortawesome/free-solid-svg-icons/faBroom";
import {useCookies} from "react-cookie";
import {BACKEND} from "../consts";
import {useNavigate} from "react-router-dom";
import {deleteUserFolder, renameUserFolder, truncateUserFolder} from "../api/common";

const ManageFolderDropBox = ({id, title}: any) => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [open, setOpen] = useState(false)
    let [action, setAction] = useState('')
    let [newName, setNewName] = useState(title)

    function cleanCache() {
        localStorage.removeItem(encodeURIComponent('app2/folders/my' + '_cache'))
        localStorage.removeItem(encodeURIComponent('app2/folders/my' + '_cache'))
        localStorage.removeItem(encodeURIComponent('app2/folders/my' + '_exp'))
        localStorage.removeItem(encodeURIComponent('app2/folders/my' + '_exp'))
    }

    async function rename() {
        await renameUserFolder(id, newName)
        cleanCache()
        nav(-1)
    }

    async function truncate() {
        await truncateUserFolder(title)
        cleanCache()
        nav(-1)
    }

    async function deleteF() {
        await deleteUserFolder(id)
        cleanCache()
        nav(-1)
    }

    return (
        <>
            {
                action &&
                <div className="accept_popup_cont">
                    <div className="accept_popup_popup">
                        <p>
                            {action === 'rename' && 'Введите новое название:'}
                            {action === 'truncate' && 'Отчистить папку?'}
                            {action === 'delete' && 'Удалить папку?'}
                        </p>
                        <div style={{
                            marginTop: 15
                        }}>
                            {
                                action === 'rename' &&
                                <input type="text" value={newName} onChange={e => setNewName(e.target.value)}/>
                            }
                            <button className={"msb"} onClick={() => {
                                if (action === 'truncate') {
                                    truncate()
                                }
                                if (action === 'delete') {
                                    deleteF()
                                }
                                if (action === 'rename') {
                                    rename()
                                }
                            }}>Продолжить</button>
                            <button className={"msb msb2"} style={{marginLeft: 10}} onClick={() => {
                                setOpen(false)
                                setAction('')
                            }}>Назад</button>
                        </div>
                    </div>
                </div>
            }

            <div className="anime_folder_select" style={{minWidth: 100, marginRight: 0}}>
                <div className={"anime_folder_select_current to"} onClick={() => setOpen(!open)}>
                    <p className={"anime_folder_select_current_text"}>Управление</p>
                    <FontAwesomeIcon icon={faChevronDown} className={"anime_folder_select_current_icon"} />
                </div>
                <div className={open ? "anime_folder_select_list" : "anime_folder_select_list hidden"}>

                    <div className={"anime_folder_select_list_el to"} onClick={() => {
                        setAction('rename')
                        setOpen(false)
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="anime_folder_select_list_el_icon_icon">
                                <FontAwesomeIcon icon={faSignature} className={"anime_folder_select_list_el_icon"}/>
                            </div>
                            <span>{'Переименовать'}</span>
                        </div>
                    </div>
                    <div className={"anime_folder_select_list_el to"} onClick={() => {
                        // truncate()
                        setAction('truncate')
                        setOpen(false)
                    }}>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 15}}>
                            <div className="anime_folder_select_list_el_icon_icon">
                                <FontAwesomeIcon icon={faBroom} className={"anime_folder_select_list_el_icon"}/>
                            </div>
                            <span>{'Отчистить'}</span>
                        </div>
                    </div>
                    <div className={"anime_folder_select_list_el to"} onClick={() => {
                        // deleteF()
                        setAction('delete')
                        setOpen(false)
                    }}>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 15}}>
                            <div className="anime_folder_select_list_el_icon_icon">
                                <FontAwesomeIcon icon={faTrashCan} className={"anime_folder_select_list_el_icon"}/>
                            </div>
                            <span>{'Удалить'}</span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default ManageFolderDropBox;