import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/colors.css'
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import {Providers} from "./lib/providers";

import '@material/web/all.js';
import './styles/m3.css'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Providers>
            <CookiesProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </CookiesProvider>
        </Providers>
    </React.StrictMode>
);
