import React, {useState} from 'react';
import '../styles/globaladv.css'
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

const GlobalAdv = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [hide, setHide] = useState(!!(localStorage.getItem('hide_beta_adv1')) || !(cookies.access))

    return (
        <>
            {
                !hide && <>
                    <div className="globaladv_close" onClick={() => {
                        setHide(true)
                    }}></div>

                    <div className="globaladv_cont">
                        <div className="globaladv_box">
                            <div className="globaladv_box_img beta_test_img" style={{
                                backgroundImage: `url('https://s3.timeweb.com/24399392-anitype-videos/images/betatest1.png')`
                            }}></div>

                            <p>Оставь заявку на бета-тест мобильного приложения, чтобы
                                начать пользоваться им одним из первых!</p>

                            <div className="anime_fast_button globaladv_box_button" onClick={() => {
                                nav('/menu/beta')
                                localStorage.setItem('hide_beta_adv1', 'true')
                                setHide(true)
                            }}>
                                <span>Оставить заявку</span>
                            </div>
                            <div className="anime_fast_button globaladv_box_button" onClick={() => {
                                localStorage.setItem('hide_beta_adv1', 'true')
                                setHide(true)
                            }}>
                                <span>Не интересно</span>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    );
};

export default GlobalAdv;