import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Anime} from "../../types";
import {cacheableAxios} from "../../funcs/cacheableAxios";
import Home2AnimeEl from "./Home2AnimeEl";
import {useDispatch, useSelector} from "react-redux";
import {dataSlice, selectHomeContent} from "../../lib/redux";

interface Props {
    title: string,
    desc: string,
    url: string,
    moreUrl: string
}

const Home2Block = (props: Props) => {
    let nav = useNavigate()

    let content = useSelector(selectHomeContent(props.url ?? ''))
    let dispatch = useDispatch()

    async function fetchData() {
        if (content && content?.length) return;

        let data = await cacheableAxios({
            url: props.url
        }, 60 * 60)
            .then(r => r)
            .catch(e => ([]))

        dispatch(dataSlice.actions.addHomeContent({
            url: props.url,
            content: data?.filter((v: Anime) => v.titles.russian)
        }))
    }

    useEffect(() => {
        fetchData()
    }, [props.url]);

    return (
        <div className={"home2block"}>
            <div className="home2block_header">
                <div className="home2block_title golos">
                    <p>{props.title}</p>
                    <p className={"home2block_title_desc"}>{props.desc}</p>
                </div>
                {
                    props.moreUrl !== '#' &&
                    <p className="home2block_more" onClick={() => nav(props.moreUrl)}>Все</p>
                }

            </div>
            <div className="home2block_content">
                {
                    content?.map((e: Anime) => <Home2AnimeEl anime={e} key={`home2block_content_${e?.id + e?.titles?.russian}`}/> )
                }

                {
                    content?.length === 0 && <>
                        <div className={"home2_anime_el home2_anime_el_skeleton"}>
                            <div className="home2_anime_el_body skeleton"></div>
                        </div>
                        <div className={"home2_anime_el home2_anime_el_skeleton"}>
                            <div className="home2_anime_el_body skeleton"></div>
                        </div>
                        <div className={"home2_anime_el home2_anime_el_skeleton"}>
                            <div className="home2_anime_el_body skeleton"></div>
                        </div>
                        <div className={"home2_anime_el home2_anime_el_skeleton"}>
                            <div className="home2_anime_el_body skeleton"></div>
                        </div>
                        <div className={"home2_anime_el home2_anime_el_skeleton"}>
                            <div className="home2_anime_el_body skeleton"></div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Home2Block;