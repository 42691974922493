import React from 'react';
import {Anime} from "../../types";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments, faReceipt, faShare} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";

const AnimeActionsBlock = ({anime, serialPage}: {
    anime: Anime | undefined,
    serialPage: boolean
}) => {
    let nav = useNavigate()
    let params = useParams()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    const shareData = {
        title: `AniType | ` + anime?.titles.russian,
        text: anime?.description,
        url: window.location.href ?? '',
    };

    async function share() {
        try {
            await navigator?.clipboard?.writeText(shareData.url);
            await navigator?.share(shareData);
        } catch (ignored) {
        }
    }

    return (
        <div className={"anime_actions_block"}>
            <div className="anime_actions_block_circle_button" onClick={() => {
                if (!cookies.access) {
                    let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                    window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                    return
                }

                nav(`/anime/${anime?.id}/folders`)
            }}>
                <div className={"anime_actions_block_circle_button_circle"}>
                    <FontAwesomeIcon icon={faFolder}/>
                </div>
                <p>Добавить в список</p>
            </div>
            <div className="anime_actions_block_circle_button" onClick={() => {
                nav(`/comments/${serialPage ? 'serial' : 'anime'}/${anime?.id}`)
            }}>
                <div className={"anime_actions_block_circle_button_circle"}>
                    <FontAwesomeIcon icon={faComments}/>
                </div>
                <p>Оставить комментарий</p>
            </div>
            <div className="anime_actions_block_circle_button" onClick={share}>
                <div className={"anime_actions_block_circle_button_circle"}>
                    <FontAwesomeIcon icon={faShare}/>
                </div>
                <p>Поделиться</p>
            </div>
            <div className="anime_actions_block_circle_button" onClick={() => nav(`/anime/${anime?.id}/info`)}>
                <div className={"anime_actions_block_circle_button_circle"}>
                    <FontAwesomeIcon icon={faReceipt}/>
                </div>
                <p>Подробнее</p>
            </div>
        </div>
    );
};

export default AnimeActionsBlock;