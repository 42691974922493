import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Anime, KodikFullData} from "../../../types";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {BACKEND} from "../../../consts";
import {getKodikData} from "../../../api/kodik";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import AnimeMoreButton from "../../anime-components/AnimeMoreButton";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../../../lib/redux";
import Loader from "../../Loader";
import AnimeFixedBlock from "../../anime-components/AnimeFixedBlock";
import AnimeTitlesBlock from "../../anime-components/AnimeTitlesBlock";
import AnimePlayButtonBlock from "../../anime-components/AnimePlayButtonBlock";
import AnimeActionsBlock from "../../anime-components/AnimeActionsBlock";
import {checkAnimeBan} from "../../../funcs/anime";
import AnimeMultiBlock from "../../anime-components/AnimeMultiBlock";
import AnimeFlyPlayBlock from "../../anime-components/AnimeFlyPlayBlock";

const SerialPage = () => {
    let nav = useNavigate()
    let params = useParams()
    let [serial, setSerial] = useState<Anime>()
    let [serials, setSerials] = useState<Anime[]>([])
    // let [kodikData, setKodikData] = useState<KodikFullData>()
    let [loading, setLoading] = useState(false)

    let userInfo = useSelector(selectUserInfo)

    useEffect(() => {
        (async () => {
            setLoading(true)
            let data = await cacheableAxios({
                url: BACKEND + `anime/ids?ids=${params.id}&type=kp`,
                method: 'get'
            }, 60 * 60)
                .then(r => r)
                .catch(ignored => [])

            setSerials([...data].sort((s1, s2) => {
                if (s1?.season_number > s2?.season_number) return 1;
                return -1;
            }))

            setSerial([...data].sort((s1, s2) => {
                if (s1?.season_number > s2?.season_number) return 1;
                return -1;
            }).shift())


            if (data?.length) setLoading(false)
        })()
    }, []);

    return (
        <>
            <AutoFadeTopBar title={serial?.titles?.russian_kp ?? ''} children={
                <AnimeMoreButton anime={serial} sub={userInfo?.sub ?? false}/>
            }/>

            {
                (loading || !serial) ? <div className="ccp">
                    <Loader/>
                </div> : <>
                    <AnimeFixedBlock anime={serial} serials={serials} serialPage={true}/>

                    <div className="anime_info_cont">
                        <div className="anime_info_cont_top"></div>
                        <div className="anime_info_cont_cont">
                            <AnimeTitlesBlock anime={serial} serials={serials} serialPage={true}/>

                            <AnimeFlyPlayBlock anime={serial}/>
                            <AnimePlayButtonBlock anime={serial}/>


                            <AnimeActionsBlock anime={serial} serialPage={true}/>

                            {/*<AnimeAddToFolderBlock/>*/}

                            {
                                checkAnimeBan(serial) ?
                                    <>
                                        <p className={"anime_bm"}>Доступ ограничен в связи с обращением правообладателя.</p>

                                        {
                                            checkAnimeBan(serial)?.by === 'kp' &&
                                            <p className={"anime_bm"}>Просмотр в Российской Федерации и в некоторых странах СНГ доступен на стриминговом сервисе Кинопоиск.</p>
                                        }
                                        {
                                            checkAnimeBan(serial)?.by === 'imdb' &&
                                            <p className={"anime_bm"}>Просмотр доступен на стриминговом сервисе IMDB.</p>
                                        }
                                    </> : <>
                                        {/*<AnimeDescriptionBlock anime={anime}/>*/}

                                        {serial?.status !== 'anons' &&
                                            <AnimeMultiBlock anime={serial} serialPage={true}/>
                                        }

                                    </>
                            }
                        </div>

                        <div style={{height: 100}}></div>
                    </div>
                </>
            }
        </>
    );
};

export default SerialPage;