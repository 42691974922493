import React, {useEffect, useRef, useState} from 'react';
import {Anime, UserInfo} from "../../types";
import {cacheableAxios} from "../../funcs/cacheableAxios";
import {BACKEND} from "../../consts";
import AnimeElement from "../AnimeElement";
import Loader from "../Loader";
import UserAvatar from "../UserAvatar";
import {timeAgoText} from "../../funcs/time";
import {useNavigate} from "react-router-dom";
import UserEmoji from "../UserEmoji";
import {searchAnimes, searchUsers} from "../../api/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons/faClockRotateLeft";
import useSearch from "../../hooks/useSearch";

const SearchPage = ({text, setText, searchRef, textRef, lastSearchRef}: {
    text: string,
    setText: (value: string) => void,
    searchRef: any,
    textRef: any,
    lastSearchRef: any
}) => {

    const [searchResult, isSearchFind, searchText, setSearchText] = useSearch()

    let [mode, setMode] = useState<'anime' | 'users'>('anime')
    let [users, setUsers] = useState<UserInfo[]>([])
    let [isLoading, setIsLoading] = useState(false)

    let nav = useNavigate()

    let timeOutRef = useRef<null | NodeJS.Timeout>(null)

    let [searchHistory, setSearchHistory] = useState((localStorage.getItem('search_history') ?? '').split('[;;;]').filter(v => v.length).reverse())

    useEffect(() => {
        setIsLoading(isSearchFind)
    }, [isSearchFind]);

    function onChange(value: string) {
        setText(value);
        textRef.current = value;

        if (!value || !value.length) {
            setUsers([])
            return
        }

        if (timeOutRef.current) clearTimeout(timeOutRef.current)
        timeOutRef.current = setTimeout(async () => {
            if (!textRef.current || textRef.current === lastSearchRef.current) return;
            lastSearchRef.current = textRef.current;

            // search
            await searchGo()

            let searchs = (localStorage.getItem('search_history') ?? '').split('[;;;]')
            searchs = [...searchs.filter(v => !v.includes(textRef.current) && v.length), textRef.current]
            localStorage.setItem('search_history', searchs.join('[;;;]'))
            setSearchHistory(searchs.reverse())
        }, 900)
    }

    async function searchGo() {
        
        if (mode === 'anime') {
            setSearchText(encodeURIComponent(text))

        } else if (mode === 'users') {
            setIsLoading(true)
            let response = await searchUsers(text)
            setUsers(response.content.reverse())
            setIsLoading(false)
        }


    }

    useEffect(() => {
        // searchGo()
        onChange(text)
        // console.log('change', text)
    }, [mode, text])

    return (
        <>
            <div style={{height: 70}}></div>
            <div className="search_page_selector">
                <button className={mode === 'anime' ? "active to" : "to"} onClick={() => {
                    setMode('anime')
                    if (searchRef.current) {
                        searchRef.current.focus()
                    }
                }}>Аниме
                </button>
                <button className={mode === 'users' ? "active to" : "to"} onClick={() => {
                    setMode('users')
                    if (searchRef.current) {
                        searchRef.current.focus()
                    }
                }}>Пользователи
                </button>
            </div>
            {
                isLoading ? <div className="ccp">
                        <Loader/>
                    </div> :
                    <div
                        className={(localStorage.getItem('list_view_mode') === 'cards' ? 'cards' : 'column') === 'cards' && mode === 'anime' ? "anime_list cards_mode" : "anime_list"}
                        style={{
                            padding: '0 15px'
                        }}>
                        {
                            (text.length === 0 && searchHistory.length === 0) &&
                            <p style={{
                                width: '100%',
                                textAlign: 'center',
                                marginTop: 50,
                                fontWeight: 500,
                                color: '#666'
                            }}>История поиска пуста :(</p>
                        }
                        {
                            text.length === 0 &&
                            <>
                                <div style={{height: 10}}></div>
                                {
                                    searchHistory.map(el =>
                                        <div className="select_page_content_item_w_icon to" onClick={() => {
                                            setText(el)
                                            onChange(el)
                                        }}>
                                            <FontAwesomeIcon icon={faClockRotateLeft}/>
                                            <p>{el}</p>
                                        </div>)
                                }
                            </>
                        }
                        {
                            mode === 'anime' && searchResult && searchResult?.length > 0 &&
                            searchResult.map(el => <AnimeElement anime={el} key={el.id}/>)
                        }
                        {
                            mode === 'users' && users && users?.length > 0 &&
                            users.map(el =>
                                <div className="home_page_top_user search_user_el to" key={el.id}
                                     onClick={() => nav(`/profile/${el?.username}`)}>

                                    <div style={{marginRight: 15}}>
                                        <UserAvatar user={el as UserInfo | undefined}/>
                                    </div>
                                    <div>
                                        <p className="home_page_top_user_username">
                                            {el.username}
                                            <UserEmoji user={el}/>
                                        </p>
                                        <p className="home_page_top_user_level">{el.level} уровень</p>
                                        <p className="home_page_top_reg_time">зарегистрировался {timeAgoText(el?.createdDate ?? [])}</p>
                                    </div>

                                </div>)
                        }
                    </div>
            }

        </>
    );
};

export default SearchPage;