import React, {useEffect, useRef, useState} from 'react';
import {useCookies} from "react-cookie";
import '../styles/event_page.css'
import {useDispatch} from "react-redux";
import {dataSlice} from "../lib/redux";

const Ws = ({wsRef}: {
    wsRef: React.MutableRefObject<WebSocket | null>
}) => {
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [currentItem, setCurrentItem] = useState('')

    let dispatch = useDispatch()

    useEffect(() => {
        wsRef.current = new WebSocket(`wss://anitype.site/events/ws/connect?authorization=Bearer ${cookies?.access}`)

        if (wsRef.current) {
            wsRef.current.onerror = () => {
                wsRef.current = new WebSocket(`wss://anitype.site/events/ws/connect?authorization=Bearer ${cookies?.access}`)
            }

            wsRef.current.onclose = () => {
                wsRef.current = new WebSocket(`wss://anitype.site/events/ws/connect?authorization=Bearer ${cookies?.access}`)
            }

            wsRef.current.onmessage = (event) => {
                try {
                    let json = JSON.parse(event.data)
                    if (json.key === 'receive_watch_data' && cookies.access) {
                        dispatch(dataSlice.actions.setContinueWatchData(json.value))
                    }
                } catch (ignored) {}
            }
        }

        return () => {
            if (wsRef.current) wsRef.current?.close()
        }
    }, []);

    return (
        <>
            {/*{*/}
            {/*    currentItem === 'muted_screamer' &&*/}
            {/*    <div className="scr">*/}
            {/*        <p className="scr_title" onClick={() => setCurrentItem('')}>Кто-то вас разыграл. С 1 апреля!*/}
            {/*            НАЖМИТЕ ЧТОБЫ ЗАКРЫТЬ.</p>*/}

            {/*        <div className="scrscr" style={{*/}
            {/*            backgroundImage: `url('${'https://s3.timeweb.com/24399392-anitype-videos/test/event1/1.gif'}')`*/}
            {/*        }}></div>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    );
};

export default Ws;