import React from 'react';
import {BACKEND} from "../consts";
import {UserInfo} from "../types";
import '../styles/user_avatar.css'
import {useNavigate} from "react-router-dom";

const UserAvatar = ({user, children, scale}: { user?: UserInfo, children?: any, scale?: number }) => {
    let nav = useNavigate()

    return (
        <div className={"user_avatar"} style={{
            scale: scale ? scale : 1
        }}>
            {/*<div className="user_avatar_append" style={{*/}
            {/*    backgroundImage: `url('https://s3.timeweb.com/24399392-anitype-videos/test/Без имени-3.png')`,*/}
            {/*    zIndex: 56*/}
            {/*}}></div>*/}

            <div style={{
                backgroundImage: `url('${getAvatar(user, scale ?? 1)}')`,
                // outline: `2px solid ${getLevelColor(user?.level ?? 0)}`,
                border: '2px solid black'
            }} className={"user_avatar_img"}>

            </div>

            {/*<div className="user_avatar_circle" style={{*/}
            {/*    background: `linear-gradient(180deg, ${getLevelColor(user?.level ?? 0)}, ${LEVEL_COLORS[ LEVEL_COLORS.indexOf(getLevelColor(user?.level ?? 0)) + 1 ]})`*/}
            {/*}}></div>*/}

            <div className="avatar_badge_cont">
                <div className="avatar_badge"
                     style={{background: getLevelColor((user?.level ?? 0) + 5)}}>{user?.level ?? 0}</div>
            </div>

            {children}
        </div>
    );
};

export default UserAvatar;

export const LEVEL_COLORS = [
    '#6c6060',
    '#5f9ad5',
    '#2a66fd',
    '#7e2afd',
    '#3d0b86',
    '#af0a8d',
    '#af0a3c',
    '#3bce09',
    '#c27e04',
    '#b6b34b',
    '#b6b34b',
]

export function getLevelColor(level: number) {
    if (level < 5) {
        return LEVEL_COLORS[0];
    }
    if (level < 10) {
        return LEVEL_COLORS[1];
    }
    if (level < 20) {
        return LEVEL_COLORS[2];
    }
    if (level < 30) {
        return LEVEL_COLORS[3];
    }
    if (level < 40) {
        return LEVEL_COLORS[4];
    }
    if (level < 50) {
        return LEVEL_COLORS[5];
    }
    if (level < 60) {
        return LEVEL_COLORS[6];
    }
    if (level < 70) {
        return LEVEL_COLORS[7];
    }
    if (level < 80) {
        return LEVEL_COLORS[8];
    }
    if (level < 90) {
        return LEVEL_COLORS[9];
    }
    return LEVEL_COLORS[0];
}

export function getAvatar(user?: UserInfo, scale?: number) {
    return `https://s2.anitype.site/media/user/${user?.username}/${60 * (scale ?? 1)}`
}

export function getUserBanner(user?: UserInfo) {
    return `https://s2.anitype.site/media/userbanner/${user?.username}`
}