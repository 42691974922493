import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons/faClockRotateLeft";
import {Anime, EpisodeSave} from "../types";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import {getAnimeIds, getEpisodesHistory} from "../api/common";
import {useCookies} from "react-cookie";
import {faFaceFrownOpen} from "@fortawesome/free-solid-svg-icons";
import M3OutlinedButton from "./m3/M3OutlinedButton";

const MyPageHistoryBlock = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [items, setItems] = useState<EpisodeSave[]>([])
    let [animes, setAnimes] = useState<Anime[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!cookies.access) return;
            setIsLoading(true)

            let itemsData = await getEpisodesHistory(0, 15);

            let animesData: Anime[] = await getAnimeIds(itemsData?.map(e => e?.episode?.anime_id).join(','))
            setAnimes(animesData)

            setItems(itemsData.filter(v => animesData.find(a => a.id === v?.episode?.anime_id)))

            setIsLoading(false)
        })()


    }, [cookies.access])

    return (
        <div className={"my_page_block my_page_history_block"}>
            <p className="my_page_block_title">
                <div>
                    <FontAwesomeIcon icon={faClockRotateLeft}/>
                    <span className={"my_page_block_title_text"}>История</span>
                </div>
                {/*<p className="my_page_block_title_show_all blue_button" style={{filter: 'blur(1px)'}}>Посмотреть все</p>*/}
                <M3OutlinedButton onClick={() => nav('/my/history')} text={'Посмотреть все'}/>
            </p>
            {
                isLoading ? <div className="my_page_block_list_loading">
                        <Loader/>
                    </div> :
                    <div className="my_page_block_list">
                        {
                            items.map(el =>
                                <div className="my_page_block_list_history_el to" onClick={() => {
                                    let add = el ? `?episode=${el?.episode?.number}&season=${el?.episode?.season}&last=${el.time + '_' + el?.episode?.number}&translation=${el.translation}` : ''
                                    nav(`/anime/${el?.episode?.anime_id}/play` + add)
                                }} key={`${el.ID}my_page_block_list_history_el`}>
                                    <div className="my_page_block_list_history_el_poster" style={{
                                        backgroundImage: `url('${el?.episode?.picture}')`
                                    }}>
                                        <div className="my_page_block_list_history_el_poster_progress_el">
                                            <div style={{
                                                width: +(el?.time / el?.duration) * 100 + '%'
                                            }} className="my_page_block_list_history_el_poster_progress_el_progress">
                                            </div>
                                        </div>
                                    </div>
                                    <p className="my_page_block_list_history_el_title">{animes.find(anime => anime?.id === el?.episode?.anime_id)?.titles.russian}</p>
                                    <p className="my_page_block_list_history_el_desc">{el?.episode?.season} сезон, {el?.episode?.number} эпизод</p>

                                    <div style={{display: 'none'}}>{JSON.stringify(el)}</div>
                                </div>)
                        }
                        {
                            items?.length === 0 &&
                            <div className="my_page_block_list_not_found">
                                <FontAwesomeIcon icon={faFaceFrownOpen} />
                                <p>Нет элементов</p>
                            </div>
                        }
                    </div>
            }

        </div>
    );
};

export default MyPageHistoryBlock;