import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {BACKEND} from "../../consts";
import '../../styles/notifications.css'
import Loader from "../Loader";
import AutoFadeTopBar from "../AutoFadeTopBar";
import {getNotifications, readNotificationsAll, readNotificationsIds} from "../../api/common";
import {timeAgoText} from "../../funcs/time";
import M3FilledTonalButton from "../m3/M3FilledTonalButton";

const NotificationsPage = () => {
    let nav = useNavigate()

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let [notifications, setNotifications] = useState<any>([])
    let [loading, setLoading] = useState(false)

    async function fetchData() {
        setLoading(true)
        if (cookies.access) {
            let info = await getNotifications(0, 10)

            setNotifications(info.content)
            setLoading(false)
        } else {
            window.location.href = `https://anitype.fun/auth?redirectTo=${encodeURIComponent('https://m.anitype.fun/')}`
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>

            <AutoFadeTopBar title={'Уведомления'} alwaysShown={true}/>


            <div className="select_page_content" style={{paddingTop: 15}}>
                {
                    loading ? <div className="ccp">
                        <Loader/>
                    </div> : <>
                        <div className="notifications_read_all">
                            {
                                (notifications.filter((e: any) => !e.read).length > 0) &&
                                <>
                                    <M3FilledTonalButton text={'Прочитать все'} onClick={() => {
                                        (async () => {
                                            if (notifications.filter((e: any) => !e.read).length == 0) return;

                                            // await readNotificationsIds(notifications.filter((e: any) => !e.read).map((e: any) => e.id).join(';'))
                                            await readNotificationsAll()
                                            localStorage.removeItem(encodeURIComponent(`app2/notifications?size=10&page=0_exp`))
                                            localStorage.removeItem(encodeURIComponent(`app2/notifications?size=10&page=0_cache`))
                                            await fetchData()
                                        })()
                                    }}/>
                                </>
                            }
                        </div>

                        {
                            notifications.length > 0 ?
                                notifications.map((el: any) =>
                                    <div
                                        className={!el.read ? "notifications_notification notifications_notification_not_read" : "notifications_notification"}
                                        key={el.id}>
                                        <span>{el.text}</span>
                                        <p className="notifications_notification_status">{el.read ? 'Прочитано' : 'Не прочитано'}, {timeAgoText(el?.createdDate)}</p>
                                    </div>) :

                                <p style={{
                                    textAlign: 'center',
                                    marginTop: 50
                                }}>Уведомлений пока нет</p>
                        }
                    </>
                }
            </div>
        </>
    );
};

export default NotificationsPage;