import React, {useState} from 'react';
import AniTypeCoin from "./assets/AniTypeCoin";
import axios from "../core/axios";

interface Props {
    title: string,
    desc: string,
    price: number,
    userInfo: any,
    show: boolean,
    setShow: (value: boolean) => void,
    buyActionCb: () => void
}

const PurchaseByCoins = (p: Props) => {
    let [block, setBlock] = useState<'main' | 'buyac'>('main')

    let [email, setEmail] = useState(p?.userInfo?.email ?? '')
    let [count, setCount] = useState(p?.price > 100 ? p?.price : 100)

    return (
        <>
            {
                p.show &&
                <div className="coins_purchase_close" onClick={() => p.setShow(false)}></div>
            }

            <div className={p.show ? "coins_purchase_dialog" : "coins_purchase_dialog hidden"}>
                {
                    block === 'main' && <>
                        <div className="coins_purchase_dialog_good">
                            <div className="coins_purchase_dialog_good_pic"></div>
                            <div className="coins_purchase_dialog_good_pic_info">
                                <p>{p.title}</p>
                                <p>{p.desc}</p>
                            </div>
                        </div>
                        <div className="coins_purchase_dialog_methods">
                            <div className="coins_purchase_dialog_method" onClick={() => {
                                p?.buyActionCb()
                                p?.setShow(false)
                            }}>
                                <p>с баланса</p>
                                <p>у вас {p.userInfo?.coins} AC</p>
                            </div>
                            <div className="coins_purchase_dialog_method" onClick={() => setBlock('buyac')}>
                                <p>пополнить</p>
                                <p>купить AC</p>
                            </div>
                        </div>
                    </>
                }
                {
                    block === 'buyac' && <>
                        {/*<div className="coins_purchase_dialog_good">*/}
                        {/*    <div className="coins_purchase_dialog_good_pic"></div>*/}
                        {/*    <div className="coins_purchase_dialog_good_pic_info">*/}
                        {/*        <p>{p.title}</p>*/}
                        {/*        <p>{p.desc}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <input type="number" className="coins_purchase_dialog_input" value={count} onChange={e => setCount(+e.target.value)}/>
                        <input type="email" className="coins_purchase_dialog_input" value={email} onChange={e => setEmail(e.target.value)}/>

                        <div className="coins_purchase_dialog_methods">
                            <div className="coins_purchase_dialog_method" onClick={() => {
                                axios({
                                    url: `https://anitype.site/yoo/v2/aaio/new?itemId=${100}&note=${p?.userInfo?.username}&email=${email}&amount=${count}`
                                })
                                    .then(r => {
                                        window.location.href = r.data.payment_url
                                    })
                                    .catch(e => {
                                    })
                            }}>
                                <p>продолжить</p>
                                <p>на страницу оплаты</p>
                            </div>
                            <div className="coins_purchase_dialog_method" onClick={() => setBlock('main')}>
                                <p>назад</p>
                                <p>на предыдущую страницу</p>
                            </div>
                        </div>
                    </>
                }


                <p className="coins_purchase_dialog_bp">
                    AniType Coins ("AC") - виртуальная валюта сайта, не представляющая никакой реальной ценности.
                    AniType Coins на сайте можно обменивать на украшения профиля, смену имени пользователя, подарки
                    другим пользователям.
                </p>
            </div>
        </>
    );
};

export default PurchaseByCoins;