import {createSlice, type PayloadAction} from '@reduxjs/toolkit'
import {Anime, UserInfo} from "../../../../types";

interface InitialState {
    data: {
        userInfo: UserInfo | undefined,
        animes: Anime[],
        animePageableContent: {
            tag: string,
            page: number,
            content: Anime[]
        }[],
        pages: {
            home: {
                anime: Anime | null,
                idsContents: {
                    url: string,
                    content: Anime[]
                }[]
            },
            anime: {
                play_page_link: string
            }
        },
        continueWatch: {
            data?: {
                anime_id: number,
                episode: number,
                translation: string,
                time: number,
                agent: string
            },
            anime?: Anime
        }
    }
}

const initialState: InitialState = {
    data: {
        userInfo: undefined,
        animes: [],
        animePageableContent: [],
        continueWatch: {},
        pages: {
            home: {
                anime: null,
                idsContents: []
            },
            anime: {
                play_page_link: ''
            }
        },
    }
}

export const dataSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        addAnime: (state, action: PayloadAction<Anime>) => {
            state.data.animes = [...state.data.animes.filter(v => v.id !== action.payload.id),
                action.payload]
        },
        addHomeContent: (state, action: PayloadAction<{
            url: string,
            content: Anime[]
        }>) => {
            state.data.pages.home.idsContents = [
                ...state.data.pages.home.idsContents.filter(v => v.url != action.payload.url),
                action.payload
            ]
        },
        addAnimePageableContent: (state, action: PayloadAction<{
            tag: string,
            page: number,
            content: Anime[]
        }>) => {
            state.data.animePageableContent = [
                ...state.data.animePageableContent.filter(v => v.tag != action.payload.tag),
                action.payload
            ]
        },
        setHomeAnime: (state, action: PayloadAction<Anime>) => {
            state.data.pages.home.anime = action.payload;
        },
        setUserInfo: (state, action: PayloadAction<UserInfo>) => {
            state.data.userInfo = action.payload;
        },
        setContinueWatchData: (state, action: PayloadAction<{
            anime_id: number,
            episode: number,
            translation: string,
            time: number,
            agent: string
        } | undefined>) => {
            state.data.continueWatch.data = action.payload
        },
        setContinueWatchAnime: (state, action: PayloadAction<Anime>) => {
            state.data.continueWatch.anime = action.payload
        },
        setAnimePagePlayLink: (state, action: PayloadAction<string>) => {
            state.data.pages.anime.play_page_link = action.payload
        },
    },
})