import React from 'react';

const Wink = ({size}: {size: number}) => {
    return (
        <>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width={size} height={size} viewBox="0 0 48.000000 48.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                   fill="#FF4F12" stroke="none">
                    <path d="M125 410 l-59 -60 54 -55 54 -55 -54 -55 -55 -55 63 -63 62 -62 117
118 118 117 -115 115 c-63 63 -117 115 -120 115 -3 0 -32 -27 -65 -60z"/>
                </g>
            </svg>

        </>
    );
};

export default Wink;