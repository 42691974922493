import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import Toggler from "./Toggler";
import {getSet} from "../funcs/users";
import axios from "../core/axios";

interface Props {
    children: any,
    settings: any,
    title: string,
    keyT: string
}

const UserSettingsToggler = (p: Props) => {
    return (
        <div className="select_page_content_item_w_icon to" style={{justifyContent: 'space-between'}}>
            <div style={{
                display: "flex",
                alignItems: 'center'
            }}>
                {p.children}
                <p>{p.title}</p>
            </div>
            <Toggler def={
                getSet(p.settings, p.keyT, 'true') === 'true'
            } cb={(e: boolean) => {
                axios({
                    method: 'put',
                    url: `app2/users/settings?key=${p.keyT}&value=${e ? 'true' : 'false'}`,
                })
            }}/>
        </div>
    );
};

export default UserSettingsToggler;