import {Anime} from "../../types";
import axios from "axios";
import {cacheableAxios} from "../cacheableAxios";
import {BACKEND} from "../../consts";
import {distinct} from "../anime";

export function searchAnime(query: string) {

    let controller =  new AbortController()

    let anitypeSearch: Promise<Anime[]> = axios({
        method: "get",
        url: BACKEND + `anime/search?keyword=${query}`, signal: controller.signal
    }).then((res) => {
        return res.data
    }).catch(() => [])

    let shikimoriSearch: Promise<Anime[]> = axios({
        method: "get",
        url: `https://shikimori.one/api/animes?limit=5&search=${query}`, signal: controller.signal
    }).then((res) => {
        return res.data.map((i: any) => shikimoriAnimeMap(i))
    }).catch(() => [])


    let prom: Promise<Anime[]> = new Promise(async (resolve, reject) => {
        let [dataAnitypeSearch, dataShikimoriSearch] = await Promise.all([anitypeSearch, shikimoriSearch])

        console.log(dataAnitypeSearch)

        let combinedAnimeList = [...dataShikimoriSearch, ...dataAnitypeSearch]

        let idsList = [...dataShikimoriSearch.map(i => i.id), ...dataAnitypeSearch.map(i => i.id)]

        idsList = distinct(idsList)


        resolve([...idsList.map(i => combinedAnimeList.find(j => i == j.id))] as Anime[])
    })
    return {abortController: controller, animeData: prom}
}

export function shikimoriAnimeMap(shikimoriAnime: any): Anime {
    return {
        kp_id: 0,
        rating: "",
        season_number: 0,
        id: shikimoriAnime.id,
        titles: {
            original: shikimoriAnime.name,
            russian: shikimoriAnime.russian,
            english: "",
            russian_alternative: "",
            japanese: "",
            russian_kp: ""
        },
        poster: "https://shikimori.one/" + (shikimoriAnime.image?.original ?? shikimoriAnime.image?.preview ?? shikimoriAnime.image?.x96),
        kind: shikimoriAnime.kind,
        score: shikimoriAnime.score ? +shikimoriAnime.score :  0,
        status: shikimoriAnime.status,
        episodes: shikimoriAnime.episodes,
        episodes_aired: shikimoriAnime.episodes_aired,
        aired_on: shikimoriAnime.aired_on,
        released_on: shikimoriAnime.released_on,
        banned: "",
        season: "",
        uhd: false,
        countries: "",
        description: "",
        genres: [], episode_duration: -1,
        next_episode_at: "",
        similar: [],
        studios: [],
        relations: [],
        external_links: []
    }
}