import React, {useEffect, useState} from 'react';
import BackQuadButton from "../../ui/BackQuadButton";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {BACKEND} from "../../../consts";
import AnimeElementIdsBlock from "../../AnimeElementIdsBlock";
import Loader from "../../Loader";

const HomeSuggestionsNewestPage = () => {
    let [ids, setIds] = useState<number[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let idss: any[] = await cacheableAxios({
                method: 'get',
                url: BACKEND + 'app1/anime/find/last'
            }, 60 * 60)
                .then(r => r.map((e: any) => e?.id))
                .catch(ignored => [])

            setIds(idss)
            setIsLoading(false)
        })()
    }, [])

    return (
        <>
            <div style={{padding: "50px 15px 20px 15px"}}>
                <div className="upper_page_header">
                    <BackQuadButton/>
                    <p className="upper_page_header_title">Новое</p>
                </div>

                {isLoading ?
                    <div className="ccp">
                        <Loader/>
                    </div> : <AnimeElementIdsBlock ids={ids} limit={10} pagination={false}/>

                }

            </div>


        </>
    );
};

export default HomeSuggestionsNewestPage;