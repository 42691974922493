import React, {useEffect} from 'react';
import '../../../styles/my.css'
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import MyPageHistoryBlock from "../../MyPageHistoryBlock";
import MyPageFoldersBlock from "../../MyPageFoldersBlock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmileBeam} from "@fortawesome/free-solid-svg-icons/faFaceSmileBeam";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import MyPageSelectionsSavedBlock from "../../MyPageSelectionsSavedBlock";

const MyPage = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }
        })()
    }, [])

    return (
        <div className={"my_page"}>
            <MyPageHistoryBlock/>
            <div className="my_page_separator"></div>
            <MyPageFoldersBlock/>
            <div className="my_page_separator"></div>
            <MyPageSelectionsSavedBlock/>


            {/*<div className="my_page_separator" style={{marginBottom: 10}}></div>*/}
            {/*<div style={{padding: '0 20px'}}>*/}

            {/*    <div className="select_page_content_button2 to disabled" onClick={() => {}}>*/}
            {/*        <div className="svg">*/}
            {/*            <FontAwesomeIcon icon={faNotesMedical} />*/}
            {/*        </div>*/}
            {/*        <p className={"select_page_content_button_title"}>Ваши рекомендации</p>*/}
            {/*        /!*<FontAwesomeIcon icon={faAngleRight}/>*!/*/}
            {/*    </div>*/}

            {/*    <div className="select_page_content_button2 to disabled"  onClick={() => {}}>*/}
            {/*        <div className="svg">*/}
            {/*            <FontAwesomeIcon icon={faLayerGroup} />*/}
            {/*        </div>*/}
            {/*        <p className={"select_page_content_button_title"}>Ваши подборки</p>*/}
            {/*        /!*<FontAwesomeIcon icon={faAngleRight}/>*!/*/}
            {/*    </div>*/}

            {/*    <div className="select_page_content_button2 to disabled"  onClick={() => {}}>*/}
            {/*        <div className="svg">*/}
            {/*            <FontAwesomeIcon icon={faFaceSmileBeam} />*/}
            {/*        </div>*/}
            {/*        <p className={"select_page_content_button_title"}>Ваши моменты</p>*/}
            {/*        /!*<FontAwesomeIcon icon={faAngleRight}/>*!/*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
};

export default MyPage;