import React from 'react';

const KinoPoisk = ({size}: {size: number}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
                <g>
                    <rect width="16" height="16" rx="2.52632" fill="black"/>
                    <path
                        d="M12.8148 8.88227V7.11796L6.53968 7.53547L12.8148 4.94961V3.1853L5.83119 6.98328L9.51821 3.1853H7.34938L4.9492 6.64658V3.1853H3.18521V12.8149H4.9492V9.35365L7.34938 12.8149H9.51821L5.97578 9.1651L12.8148 12.8149V11.0506L6.52522 8.4513L12.8148 8.88227Z"
                        fill="#FF6600"/>
                </g>
            </svg>

        </>
    );
};

export default KinoPoisk;