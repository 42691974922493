import React, {useEffect, useRef, useState} from 'react';
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import {Anime, EpisodeSave} from "../../../types";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {getAnimeIds, getEpisodesHistory} from "../../../api/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceFrownOpen} from "@fortawesome/free-solid-svg-icons";

const MyPageHistory = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let forLoadDiv = useRef<HTMLDivElement>(null)
    let [isLoading, setIsLoading] = useState(false)
    let page = useRef(0)
    let loading = useRef(false)

    let [items, setItems] = useState<EpisodeSave[]>([])
    let [animes, setAnimes] = useState<Anime[]>([])

    async function fetchPage() {
        if (page.current < 0 || loading.current) return;

        loading.current = true

        let itemsData = await getEpisodesHistory(page.current, 15);

        if (!itemsData.length) {
            page.current = -99
            return
        }

        let animesData: Anime[] = await getAnimeIds(itemsData?.map(e => e?.episode?.anime_id).join(','))
        setAnimes(a => [...a, ...animesData])

        setItems(i => [...i, ...itemsData.filter(v => animesData.find(a => a.id === v?.episode?.anime_id))])

        page.current += 1
        loading.current = false
    }

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }

            // await fetchPage()

        })()
    }, [cookies.access])

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
            fetchPage()
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '500px', // Область видимости - верх страницы
            threshold: 0.1,    // Порог видимости
        });

        if (forLoadDiv.current) {
            observer.observe(forLoadDiv.current);
        }

        return () => {

            if (forLoadDiv.current && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <>
            <AutoFadeTopBar title={'История'} alwaysShown={true}/>

            <div className="full_list_page_list" style={{
                padding: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 15
            }}>
                {
                    (isLoading && items?.length === 0) ?
                        <div className={"ccp"}>
                            <Loader/>
                        </div> :
                        <>
                            {
                                items.map(el =>
                                    <div className="my_page_block_list_history_page_el my_page_block_list_history_el to" onClick={() => {
                                        let add = el ? `?episode=${el?.episode?.number}&season=${el?.episode?.season}&last=${el.time + '_' + el?.episode?.number}&translation=${el.translation}` : ''
                                        nav(`/anime/${el?.episode?.anime_id}/play` + add)
                                    }} key={`${el.ID}my_page_block_list_history_el`}>
                                        <div className="my_page_block_list_history_el_poster" style={{
                                            backgroundImage: `url('${el?.episode?.picture}')`
                                        }}>
                                            <div className="my_page_block_list_history_el_poster_progress_el">
                                                <div style={{
                                                    width: +(el?.time / el?.duration) * 100 + '%'
                                                }} className="my_page_block_list_history_el_poster_progress_el_progress">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my_page_block_list_history_el_info">
                                            <p className="my_page_block_list_history_el_title">{animes.find(anime => anime?.id === el?.episode?.anime_id)?.titles.russian}</p>
                                            <p className="my_page_block_list_history_el_desc">{el?.episode?.season} сезон, {el?.episode?.number} эпизод</p>
                                        </div>
                                    </div>)
                            }
                            {
                                items?.length === 0 &&
                                <div className="my_page_block_list_not_found">
                                <FontAwesomeIcon icon={faFaceFrownOpen} />
                                    <p>Нет элементов</p>
                                </div>
                            }
                            <div ref={forLoadDiv}></div>
                        </>
                }
            </div>
        </>
    );
};

export default MyPageHistory;