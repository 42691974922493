import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {UserInfo} from "../../../types";
import Loader from "../../Loader";
import {getLevelColor} from "../../UserAvatar";
import '../../../styles/circle_progress_bar.css'
import {calcProcLevel} from "../../../funcs/users";
import {getUserMyInfo} from "../../../funcs/api/users";
import AutoFadeTopBar from "../../AutoFadeTopBar";

const UserMenuLevelPage = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [loading, setLoading] = useState(false)
    let [info, setInfo] = useState<UserInfo>()

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }

            let infoO = await getUserMyInfo();
            if (!infoO) return;
            setInfo(infoO)
        })()
    }, [])

    return (
        <>

            <AutoFadeTopBar title={`Уровень`} alwaysShown={true}/>


            {loading ? <div className="ccp">
                <Loader/>
            </div> : <>
                <div className="profile_page" style={{marginTop: 0}}>

                    <p className="level_page_level">
                        <span style={{
                            color: getLevelColor(info?.level ?? 0),
                            textShadow: `0 0 150px ${getLevelColor(info?.level ?? 0)}`
                        }}>{info?.level}</span>
                        <div className="level_page_level_progress" style={{
                            width: `${calcProcLevel(info?.level, info?.exp)}%`,
                        }}></div>
                        <div className="level_page_level_progress_bg"></div>
                    </p>

                    <div className="level_page_content">
                        <p>Уровни и награды:</p>

                        {
                            levelAwards.map(el =>
                                <div className="level_page_content_level" key={el.level + 'level'}>

                                    <p className="level_page_content_level_level" style={{
                                        color: getLevelColor(el.level),
                                        textShadow: `1px 1px 45px ${getLevelColor(el.level)}`
                                    }}>{el.level}</p>
                                    <div className="level_page_content_level_awards">
                                        {
                                            el.awards.map(el2 =>
                                                <div className="level_page_content_level_award" key={el2 + 'award' + el.level}>{el2}</div>)
                                        }

                                    </div>

                                </div>)
                        }

                    </div>

                </div>
            </>}

        </>
    );
};

export default UserMenuLevelPage;

// ((info?.exp ?? 0) - targetValue((info?.level ?? 0) - 1)) /
// (targetValue((info?.level ?? 0)) - (info?.exp ?? 0)) * 100

export const levelAwards = [
    {
        level: 1,
        awards: [
            'Возможность оставлять до 2 комментариев к аниме в день',
            'Возможность оставлять до 1 реакции на эпизод в день'
        ]
    },
    {
        level: 3,
        awards: [
            'Возможность оставлять до 1 комментария к профилям в день',
            'Возможность оставлять до 3 реакций на эпизод в день'
        ]
    },
    {
        level: 5,
        awards: [
            'Возможность добавить до 1 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 5 реакций на эпизод в день'
        ]
    },
    {
        level: 10,
        awards: [
            'Возможность оставлять до 3 комментариев к аниме в день',
            'Возможность оставлять до 2 комментариев к профилям в день',
            'Возможность добавить до 2 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 10 реакций на эпизод в день'
        ]
    },
    {
        level: 20,
        awards: [
            'Возможность оставлять до 4 комментариев к аниме в день',
            'Возможность оставлять до 3 комментариев к профилям в день',
            'Возможность добавить до 3 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 20 реакций на эпизод в день'
        ]
    },
    {
        level: 30,
        awards: [
            'Возможность оставлять до 5 комментариев к аниме в день',
            'Возможность оставлять до 4 комментариев к профилям в день',
            'Возможность добавить до 4 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 30 реакций на эпизод в день'
        ]
    },
    {
        level: 40,
        awards: [
            'Возможность оставлять до 6 комментариев к аниме в день',
            'Возможность оставлять до 5 комментариев к профилям в день',
            'Возможность добавить до 5 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 40 реакций на эпизод в день'
        ]
    },
    {
        level: 50,
        awards: [
            'Возможность оставлять до 7 комментариев к аниме в день',
            'Возможность оставлять до 6 комментариев к профилям в день',
            'Возможность добавить до 6 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 50 реакций на эпизод в день'
        ]
    },
    {
        level: 60,
        awards: [
            'Возможность оставлять до 8 комментариев к аниме в день',
            'Возможность оставлять до 7 комментариев к профилям в день',
            'Возможность добавить до 7 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 60 реакций на эпизод в день'
        ]
    },
    {
        level: 70,
        awards: [
            'Возможность оставлять до 9 комментариев к аниме в день',
            'Возможность оставлять до 8 комментариев к профилям в день',
            'Возможность добавить до 8 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 70 реакций на эпизод в день'
        ]
    },
    {
        level: 80,
        awards: [
            'Возможность оставлять до 10 комментариев к аниме в день',
            'Возможность оставлять до 10 комментариев к профилям в день',
            'Возможность добавить до 10 видео с YouTube в свой профиль (в разработке)',
            'Возможность оставлять до 80 реакций на эпизод в день'
        ]
    }
]