import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faChevronUp, faCopy, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import UserAvatar from "../UserAvatar";
import {UserInfo} from "../../types";
import axios from "../../core/axios";
import {useSelector} from "react-redux";
import {selectHubInfo, selectWatchData} from "../../lib/redux";
import {secondsToTime} from "../../funcs/time";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

const W2MembersModule = () => {
    let [addMembersOpen, setAddMembersOpen] = useState(false)

    let [copied, setCopied] = useState(false)

    let hubInfo = useSelector(selectHubInfo)
    let watchDataState = useSelector(selectWatchData)

    let [users, setUsers] = useState<UserInfo[]>([])

    useEffect(() => {
        if (users.map(v => v.username).join('') !== hubInfo.users.map(v => v.username).join('')) {
            axios({
                url: `users/uns/${hubInfo.users.map(v => v.username).join(',')}`
            }).then(r => setUsers(r.data)).catch(ignored => ignored)
        }
    }, [hubInfo]);

    return (
        <div className="w2_members_module ws_right_module">

            <div className="w2_members_new_member_button">
                <div className="w2_members_new_member_button_button" onClick={() => setAddMembersOpen(v => !v)}>
                    <FontAwesomeIcon icon={faUserPlus} />
                    <span>Пригласить участников</span>
                    {
                        addMembersOpen ?
                            <FontAwesomeIcon icon={faChevronUp} /> :
                            <FontAwesomeIcon icon={faChevronDown} />
                    }
                </div>
                {
                    addMembersOpen &&
                    <div className="w2_members_new_member_button_content">
                        <div className="w2_manage_module_set">
                            <p className={"w2_manage_module_set_title"}>СКОПИРУЙТЕ ССЫЛКУ И ОТПРАВЬТЕ ДРУЗЬЯМ</p>
                            <div className="w2_manage_module_set_changer">
                                <input type="text" value={`https://anitype.fun/watch_together/${watchDataState?.anime_id}?hub=${hubInfo?.key}`}/>

                                <div className="copy_input" onClick={() => {
                                    navigator?.clipboard?.writeText(`https://anitype.fun/watch_together/${watchDataState?.anime_id}?hub=${hubInfo?.key}`)
                                        .then(r => {
                                            setCopied(true)
                                        }).catch(e => console.error(e))
                                }}>
                                    {
                                        copied ?
                                            <FontAwesomeIcon icon={faCheck} /> :
                                            <FontAwesomeIcon icon={faCopy} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            {
                hubInfo.users.map(u =>
                    <div className="w2_members_member">
                        <UserAvatar user={users.find(v => v.username === u.username)}/>

                        <div className="w2_members_member_info">
                            <p>{u.username}</p>
                            <p>{
                                [
                                    secondsToTime(u.watch_data.time),
                                    `${u.watch_data.episode} эпизод`,
                                    u.watch_data.translation,
                                    u.watch_data.is_paused ? 'На паузе' : '',
                                    hubInfo.owner === u.username ? 'Создатель комнаты' : 'Участник'
                            ].filter(v => v).join(' / ')
                        }</p>
                    </div>
                </div>)
            }

        </div>
    );
};

export default W2MembersModule;