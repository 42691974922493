import React from 'react';
import {Anime} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import {engSeasonToRu, engStatusToRu, getEpisodes, getRateColorText, getSeasonsCountText} from "../../funcs/anime";
import {pluralizeEpisodes} from "../../funcs/ru_lang";
import {faArrowRight, faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const AnimeTitlesBlock = ({anime, serials, serialPage}: {anime: Anime | undefined, serials: Anime[], serialPage: boolean}) => {
    let nav = useNavigate()

    return (
        <>
            <div className="anime_title_block">
                <p className={serialPage ? "anime_title_block_texts_title" : "anime_title_block_texts_title season_page"} style={{
                    fontWeight: serialPage ? 600 : 500,
                }}>
                    <span className={"golos"}>{anime?.titles.russian ?? 'Название отсутствует'}</span>
                </p>
                {
                    (anime?.kind === 'tv' && anime?.season_number && serialPage) &&
                    <div className="anime_title_block_texts_under_title golos">
                        <p className="golos">{getSeasonsCountText(serials)}</p>

                        <p className="anime_title_block_texts_under_title_serial_go" onClick={() => nav(`/serial/${anime?.kp_id}`)}>
                            <span>сериал {anime?.season.split('_').pop()}</span>
                        </p>
                    </div>
                }
                {
                    (anime?.kind === 'tv' && anime?.season_number && !serialPage) &&
                    <div className="anime_title_block_texts_under_title golos season_page">
                        <p className="golos">{anime?.season_number} сезон</p>

                        <p className="anime_title_block_texts_under_title_serial_go" onClick={() => nav(`/serial/${anime?.kp_id}`)}>
                            <span style={{marginRight: 3}}>к сериалу</span>
                            <FontAwesomeIcon icon={faArrowRight} style={{fontSize: 12}}/>
                        </p>
                    </div>
                }

                <div className={serialPage ? "anime_title_block_texts_under_title golos" : "anime_title_block_texts_under_title golos season_page"}>
                    <p className="golos">{anime?.titles.original}</p>

                    {
                        anime?.rating !== '' && anime?.rating !== 'none' &&
                        <p className="anime_title_block_texts_under_title_age_tag">
                            {anime?.rating === 'pg_13' && '13+'}
                            {anime?.rating === 'r' && '18+'}
                            {anime?.rating === 'r_plus' && '18+'}
                            {anime?.rating === 'rx' && '18+'}
                        </p>
                    }
                </div>


            </div>
        </>
    );
};

export default AnimeTitlesBlock;