import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTableCellsLarge} from "@fortawesome/free-solid-svg-icons/faTableCellsLarge";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";

const ChangeViewDropBox = ({selected, setSelected}: any) => {
    let [open, setOpen] = useState(false)

    return (
        <div className="anime_folder_select" style={{minWidth: 100, marginRight: 0}}>
            <div className={"anime_folder_select_current to"} onClick={() => setOpen(!open)}>
                <p className={"anime_folder_select_current_text"}>Вид</p>
                <FontAwesomeIcon icon={faChevronDown} className={"anime_folder_select_current_icon"} />
            </div>
            <div className={open ? "anime_folder_select_list" : "anime_folder_select_list hidden"}>

                <div className={"anime_folder_select_list_el to"} onClick={() => {
                    setSelected('cards')
                    localStorage.setItem('list_view_mode', 'cards')
                    setOpen(false)
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="anime_folder_select_list_el_icon_icon">
                            <FontAwesomeIcon icon={faTableCellsLarge} className={"anime_folder_select_list_el_icon"}/>
                        </div>
                        <span>{'Карточки'}</span>
                    </div>
                    {
                        selected === 'cards' &&
                        <FontAwesomeIcon icon={faCheck} className={"anime_folder_select_list_el_check_active"} />
                    }
                </div>
                <div className={"anime_folder_select_list_el to"} onClick={() => {
                    setSelected('column')
                    localStorage.setItem('list_view_mode', 'column')
                    setOpen(false)
                }}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 15}}>
                        <div className="anime_folder_select_list_el_icon_icon">
                            <FontAwesomeIcon icon={faList} className={"anime_folder_select_list_el_icon"}/>
                        </div>
                        <span>{'Список'}</span>
                    </div>
                    {
                        selected === 'column' &&
                        <FontAwesomeIcon icon={faCheck} className={"anime_folder_select_list_el_check_active"} />
                    }
                </div>

            </div>
        </div>
    );
};

export default ChangeViewDropBox;