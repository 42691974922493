import React, {useRef, useState} from 'react';
import PageLayout from "./PageLayout";
import TopBarSearch from "../TopBarSearch";
import NavBar from "../NavBar";
import SearchPage from "../pages/SearchPage";

const PageLayoutWithTopBarAndBotBar = ({children, searchOpen, setSearchOpen}: any) => {
    let [searchText, setSearchText] = useState('')

    let searchRef = useRef(null)
    let textRef = useRef('')
    let lastSearchRef = useRef('')

    return (
        <>
            <TopBarSearch searchRef={searchRef} searchText={searchText} setSearchText={setSearchText} searchOpen={searchOpen} setSearchOpen={setSearchOpen}/>

            {
                !searchOpen ? <>
                    {children}
                </> : <>
                    <SearchPage text={searchText} setText={setSearchText} searchRef={searchRef} textRef={textRef} lastSearchRef={lastSearchRef}/>
                </>
            }
        </>
    );
};

export default PageLayoutWithTopBarAndBotBar;