import axios from "axios";
import {KodikFullData} from "../types";
import {KODIK_API_URL, KODIK_PUBLIC_TOKEN} from "../consts";

export async function getKodikTranslations() {
    // return axios({
    //     url: `https://kodikapi.com/translations/v2?token=3bd0a27dfccd284c54f4889f4a7d6453&types=anime,anime-serial&sort=count`
    // })
    return [
        {
            "id": 609,
            "title": "AniDUB",
            "count": 3321
        },
        {
            "id": 767,
            "title": "SHIZA Project",
            "count": 1645
        },
        {
            "id": 610,
            "title": "AniLibria.TV",
            "count": 1607
        },
        {
            "id": 869,
            "title": "Субтитры",
            "count": 1422
        },
        {
            "id": 910,
            "title": "AniStar",
            "count": 846
        },
        {
            "id": 827,
            "title": "Persona99",
            "count": 745
        },
        {
            "id": 739,
            "title": "Animedia",
            "count": 729
        },
        {
            "id": 923,
            "title": "AnimeVost",
            "count": 702
        },
        {
            "id": 1291,
            "title": "Crunchyroll.Subtitles",
            "count": 620
        },
        {
            "id": 654,
            "title": "AniFilm",
            "count": 539
        },
        {
            "id": 963,
            "title": "Amazing Dubbing",
            "count": 434
        },
        {
            "id": 674,
            "title": "Люб. Одноголосый",
            "count": 363
        },
        {
            "id": 761,
            "title": "Onibaku",
            "count": 318
        },
        {
            "id": 643,
            "title": "Studio Band",
            "count": 300
        },
        {
            "id": 825,
            "title": "AniMaunt",
            "count": 215
        },
        {
            "id": 1978,
            "title": "Dream Cast",
            "count": 215
        },
        {
            "id": 559,
            "title": "Kansai",
            "count": 206
        },
        {
            "id": 2228,
            "title": "Freedub Studio",
            "count": 192
        },
        {
            "id": 1895,
            "title": "FumoDub",
            "count": 191
        },
        {
            "id": 1060,
            "title": "MiraiDUB",
            "count": 186
        },
        {
            "id": 1068,
            "title": "Crunchyroll",
            "count": 184
        },
        {
            "id": 1442,
            "title": "ХЗ Лол",
            "count": 180
        },
        {
            "id": 1043,
            "title": "Animy",
            "count": 177
        },
        {
            "id": 958,
            "title": "AniRise",
            "count": 177
        },
        {
            "id": 1215,
            "title": "Fronda Studio",
            "count": 168
        },
        {
            "id": 873,
            "title": "AniPLague",
            "count": 156
        },
        {
            "id": 1500,
            "title": "FSG YakuSub Studio.Subtitles",
            "count": 152
        },
        {
            "id": 605,
            "title": "Люб. Двухголосый",
            "count": 150
        },
        {
            "id": 1863,
            "title": "КОМНАТА ДИДИ",
            "count": 142
        },
        {
            "id": 704,
            "title": "Дублированный",
            "count": 141
        },
        {
            "id": 2822,
            "title": "AniBaza",
            "count": 137
        },
        {
            "id": 1062,
            "title": "LE-Production",
            "count": 135
        },
        {
            "id": 651,
            "title": "Люб. Многоголосый",
            "count": 132
        },
        {
            "id": 1284,
            "title": "ПВА ШОУ",
            "count": 131
        },
        {
            "id": 1009,
            "title": "AniStar Многоголосый",
            "count": 126
        },
        {
            "id": 757,
            "title": "MC Entertainment",
            "count": 119
        },
        {
            "id": 957,
            "title": "youmiteru",
            "count": 119
        },
        {
            "id": 1273,
            "title": "Wakanim.Subtitles",
            "count": 110
        },
        {
            "id": 1811,
            "title": "OnWave",
            "count": 104
        },
        {
            "id": 557,
            "title": "JAM",
            "count": 101
        },
        {
            "id": 1467,
            "title": "SovetRomantica.Subtitles",
            "count": 101
        },
        {
            "id": 1781,
            "title": "AniFame",
            "count": 92
        },
        {
            "id": 1099,
            "title": "Anything Group",
            "count": 91
        },
        {
            "id": 1070,
            "title": "MedusaSub.Subtitles",
            "count": 91
        },
        {
            "id": 879,
            "title": "TVShows",
            "count": 91
        },
        {
            "id": 1711,
            "title": "DubClub",
            "count": 90
        },
        {
            "id": 2295,
            "title": "Shikoku Studio",
            "count": 85
        },
        {
            "id": 1646,
            "title": "HaronMedia",
            "count": 79
        },
        {
            "id": 1709,
            "title": "Ушастая озвучка",
            "count": 79
        },
        {
            "id": 820,
            "title": "animereactor",
            "count": 77
        }
    ]
}

export async function getKodikData(id: number): Promise<KodikFullData | undefined> {
    if (id <= 0) return;

    let kodikFull: KodikFullData = {
        episodes: [],
        isFilm: false,
        season: -1,
        walink: '',
        kId: '',
        kTitle: ''
    }

    let kr = await fetch(KODIK_API_URL + `search?token=${KODIK_PUBLIC_TOKEN}&shikimori_id=${id}&with_episodes=true`)
    let kodikResponse = await kr.json();

    let kodikData = kodikResponse?.results ?? []

    if (kodikData.length === 0) return;

    for (let kResult of kodikData) {
        kodikFull.walink = kResult.worldart_link

        let translation = kResult.translation.title

        if (kResult.type === 'anime') {
            // film
            kodikFull.isFilm = true
            kodikFull.episodes.push({
                kodikLink: kResult.link,
                episode: 1,
                season: -1,
                translation: translation
            })

        } else {
            let seasons = kResult.seasons

            let maxSeasonNumber = -1;
            for (const seasonNumber in seasons) {
                if (+seasonNumber > maxSeasonNumber) maxSeasonNumber = +seasonNumber;

                const season = seasons[seasonNumber];
                const episodes = season.episodes;

                for (const episodeNumber in episodes) {

                    const episodeLink = episodes[episodeNumber];

                    kodikFull.episodes.push({
                        kodikLink: episodeLink,

                        episode: +episodeNumber,
                        season: +seasonNumber,
                        translation: translation
                    })
                }
            }
            kodikFull.season = maxSeasonNumber;

        }
    }
    return kodikFull
}