import React from 'react';

const Dot = () => {
    return (
        <span style={{marginLeft: 5, marginRight: 5}}>
            ·
        </span>
    );
};

export default Dot;