import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate} from "react-router-dom";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";

const StreamsCreateSelectNamePage = () => {
    let nav = useNavigate()

    let [name, setName] = useState(localStorage.getItem('new_stream_name') ?? '')
    useEffect(() => {
        localStorage.setItem('new_stream_name', name)
    }, [name])

    return (
        <>
            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>

            <div className="select_page_content spc">
                <input type="text" placeholder={"Введите название стрима"} value={name}
                onChange={e => setName(e.target.value)}/>

                <p className={"select_page_content_warning"} style={{marginTop: 20}}>В названии стрима рекомендуем указать полезную информацию, которая позволит выбрать именно ваш стрим!</p>
            </div>
        </>
    );
};

export default StreamsCreateSelectNamePage;