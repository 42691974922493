import React from 'react';

const ViewCardsImg = ({size}: {size: number}) => {
    return (
        <>
            <svg width={size} height={size} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_52_40)">
                    <rect x="9" y="27" width="381" height="346" fill="#171717"/>
                    <rect x="37" y="49" width="153" height="110" rx="12" fill="#222222"/>
                    <rect x="37" y="190" width="153" height="110" rx="12" fill="#222222"/>
                    <rect x="213" y="49" width="153" height="110" rx="12" fill="#222222"/>
                    <rect x="213" y="190" width="153" height="110" rx="12" fill="#222222"/>
                    <rect x="37" y="331" width="153" height="110" rx="12" fill="#222222"/>
                    <rect x="213" y="331" width="153" height="110" rx="12" fill="#222222"/>
                </g>
                <defs>
                    <clipPath id="clip0_52_40">
                        <rect x="9" y="27" width="381" height="346" rx="15" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default ViewCardsImg;