import React from 'react';
import {Anime} from "../../types";
import {proxyImg} from "../../funcs/poster";
import {getEpisodes, getRateColor} from "../../funcs/anime";
import {useNavigate} from "react-router-dom";
import {pluralizeEpisodes} from "../../funcs/ru_lang";

interface Props {
    anime: Anime
}

const Home2AnimeEl = ({anime}: Props) => {
    let nav = useNavigate()

    return (
        <div className={"home2_anime_el"} onClick={() => nav(`/anime/${anime?.id}`)}>
            <div className="home2_anime_el_body">
                <div className="home2_anime_el_body_poster" style={{
                    backgroundImage: `url('${proxyImg(anime?.id, anime?.poster)}')`
                }}></div>

                <div className="home2_anime_el_tags">
                    {
                        anime?.score > 0 &&
                        <div className="home2_anime_el_rate" style={{
                            background: getRateColor(anime?.score)
                        }}>{(anime?.score ?? 0).toFixed(1)}</div>
                    }


                    {
                        anime?.uhd &&
                        <div className="home2_anime_el_rate" style={{
                            background: "crimson"
                        }}>4K</div>
                    }

                </div>


            </div>

            <p className="home2_anime_el_title">{anime?.titles.russian}</p>

            {
                anime?.status === 'anons' && <p className="home2_anime_el_desc">Скоро</p>
            }
            {
                (anime?.status === 'ongoing' || anime?.status === 'released') && anime?.kind !== 'movie' && <p className="home2_anime_el_desc">{getEpisodes(anime)} {pluralizeEpisodes(getEpisodes(anime))}</p>
            }
            {
                anime?.kind === 'movie' && <p className="home2_anime_el_desc">Фильм</p>
            }
            {/*{*/}
            {/*    anime?.status === 'anons' ?*/}
            {/*         :*/}
            {/*        <p className="home2_anime_el_desc">{getEpisodes(anime)} {pluralizeEpisodes(getEpisodes(anime))}</p>*/}
            {/*}*/}
            {/*{anime.status}*/}
        </div>
    );
};

export default Home2AnimeEl;