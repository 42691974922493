import React, {useEffect, useState} from 'react';
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCookies} from "react-cookie";
import {BACKEND} from "../../consts";
import {Anime} from "../../types";
import {useNavigate} from "react-router-dom";
import {createWatchTogetherHub, getFolderItems} from "../../api/common";
import axios from "../../core/axios";

const AnimeMoreButton = ({anime, sub}: {anime: Anime | undefined, sub: boolean}) => {
    let [follow, setFollow] = useState(false)

    let [shown, setShown] = useState(false)

    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    async function goWatchTogether() {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        let res = await createWatchTogetherHub();
        // window.location.href = `https://anitype.fun/watch_together/${anime?.id}?hub=${res.id}&redirectTo=${encodeURIComponent('https://m.anitype.fun/')}`
        nav(`/hub?id=${res.id}&anime_id=${anime?.id}`)

    }
    const shareData = {
        title: `AniType | ` + anime?.titles.russian,
        text: anime?.description,
        url: window.location.href ?? '',
    };
    async function share() {
        try {
            await navigator?.clipboard?.writeText(shareData.url);
            await navigator?.share(shareData);
        } catch (ignored) {}
    }

    async function changeFollow() {
        // follow anime
        if (!sub) {
            nav('/menu/premium')
            return
        }
        setFollow(!follow)
        axios({
            method: 'post',
            url: `app2/folders/${!follow ? 'add' : 'remove'}`,
            data: {
                folderTitle: 'Отслеживаемое',
                releaseId: anime?.id
            }
        })
            .then(r => {

            })
            .catch(e => {
                setFollow(!follow)
            })
    }

    useEffect(() => {
        (async () => {
            if (!cookies.access) return;
            let fItems = await getFolderItems('Отслеживаемое')


            if (fItems) {
                setFollow(fItems.filter((v: any) => +v.releaseId === +(anime?.id ?? 0)).length > 0)
            }

        })()
    }, [cookies.access, anime]);

    return (
        <>
            <FontAwesomeIcon icon={faEllipsisVertical} onClick={() => setShown(!shown)}/>

            <div className={shown ? "top_right_abs_menu" : "top_right_abs_menu hidden"}>
                <div className="top_right_abs_menu_el" onClick={() => {
                    changeFollow()
                    setShown(false)
                }}>
                    <p>{follow ? 'Не отслеживать' : 'Отслеживать'}</p>
                </div>
                <div className="top_right_abs_menu_el" onClick={() => {
                    setShown(false)
                    goWatchTogether()
                }}>
                    <p>Совместный просмотр</p>
                </div>
                <div className="top_right_abs_menu_el" onClick={() => {
                    setShown(false)
                    share()
                }}>
                    <p>Поделиться</p>
                </div>
            </div>
        </>
    );
};

export default AnimeMoreButton;