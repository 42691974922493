import React, {useEffect} from 'react';
import Analytics from "../Analytics";
import axios from "../../core/axios";
import {useDispatch} from "react-redux";
import {dataSlice} from "../../lib/redux";
import AdsInitializer from "../yandex/AdsInitializer";

const PageLayout = ({children}: any) => {
    let dispatch = useDispatch()

    useEffect(() => {
        axios({
            url: 'users/me',
        }).then(r => {
            dispatch(dataSlice.actions.setUserInfo(r.data))
        }).catch(ignored => ignored)
    }, []);

    return (
        <div className={"page"}>
            <Analytics/>
            <AdsInitializer/>
            {children}
        </div>
    );
};

export default PageLayout;