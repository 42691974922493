import React from 'react';
import '../styles/circle_avatar_receiver.css'

const CircleAvatarReceiver = ({changeAvatar}: any) => {
    return (
        <div className={"avatar_receiver"}>
            <input className={"avatar_receiver_input"} type="file" style={{marginBottom: 20}} accept="image/png, image/jpeg, image/gif"
                   onChange={changeAvatar} alt={""}/>
            <p>Выберите аватар</p>
        </div>
    );
};

export default CircleAvatarReceiver;