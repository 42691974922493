import React, {useEffect, useState} from 'react';
import {proxyImg} from "../../funcs/poster";
import {Anime} from "../../types";
import AnimeAnimatedFixedPoster from "./AnimeAnimatedFixedPoster";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import AnimePlayPosterButtonBlock from "./AnimePlayPosterButtonBlock";

const AnimeFixedBlock = ({anime, serials, serialPage}: { anime: Anime | undefined, serials: Anime[], serialPage: boolean }) => {
    let [opacity, setOpacity] = useState(1)

    function handleScroll() {
        const currentScrollPosition = window.scrollY;
        let perc = +currentScrollPosition / 300 * 100
        if (perc > 100) perc = 100;

        setOpacity((100 - perc) / 100)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className={serialPage ? "anime_fixed_poster_cont" : "anime_fixed_poster_cont season_page"} style={{
                opacity: opacity,
                // transform: `rotateZ(-15deg)`
            }}>
                <div className="anime_fixed_poster_shadow"></div>

                <div className="anime_fixed_poster_blured"
                     style={{backgroundImage: `url('${proxyImg(anime?.id, anime?.poster)}')`}}></div>

                {
                    serials?.length > 1 ?
                        serials.map((poster, i) =>
                            <AnimeAnimatedFixedPoster poster={poster} i={i} key={`post_i${i}`} total={serials.length}/>
                        ) :
                        <div className={serialPage ? "anime_fixed_poster" : "anime_fixed_poster season_page"}
                             style={{
                                 backgroundImage: `url('${proxyImg(anime?.id, anime?.poster)}')`,
                             }}></div>
                }

                {
                    !serialPage &&
                    <AnimePlayPosterButtonBlock anime={anime}/>
                }

            </div>
        </>
    );
};

export default AnimeFixedBlock;