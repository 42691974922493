import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate} from "react-router-dom";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {useCookies} from "react-cookie";
import Error from "../../Error";
import {createStream} from "../../../funcs/streams";

const StreamsCreatePage = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [err, setErr] = useState('')

    const create = () => {
        createStream({
            cookies,
            name: localStorage.getItem('new_stream_name'),
            anime: localStorage.getItem('new_stream_anime'),
            episode: localStorage.getItem('new_stream_episode'),
            translation: localStorage.getItem('new_stream_translation'),
            pass: localStorage.getItem('new_stream_pass'),
            setErr
        })

        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }
    }

    return (
        <>
            <Error message={err} setMessage={setErr}/>

            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>

            <div className="select_page_content spc">
                <div className="select_page_content_button to" onClick={() => {
                    nav('/streams/create/title')
                }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>{
                            localStorage.getItem('new_stream_name') ?
                                localStorage.getItem('new_stream_name') : 'Название не указано'
                        }</p>
                        <p className={"select_page_content_button_desc"}>Ввести название стрима</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>

                <div className="select_page_content_button to" onClick={() => {
                    nav('/streams/create/pass')
                }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>{
                            localStorage.getItem('new_stream_pass') ?
                                localStorage.getItem('new_stream_pass') : 'Пароль не установлен'
                        }</p>
                        <p className={"select_page_content_button_desc"}>Установить пароль для стрима</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>

                <div className="select_page_content_button to" onClick={() => {
                    nav('/streams/create/anime')
                }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>
                            {
                                localStorage.getItem('new_stream_anime') ?
                                    'Аниме выбрано' : 'Аниме не выбрано'
                            }</p>
                        <p className={"select_page_content_button_desc"}>Выбрать аниме</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>

                <div
                    className={localStorage.getItem('new_stream_anime') ? "select_page_content_button to" : "select_page_content_button disabled to"}
                    onClick={() => {
                        nav('/streams/create/translation')
                    }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>{
                            localStorage.getItem('new_stream_translation') ?
                                localStorage.getItem('new_stream_translation') : 'Озвучка не выбрана'
                        }</p>
                        <p className={"select_page_content_button_desc"}>Выбрать озвучку</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>
                <div
                    className={localStorage.getItem('new_stream_anime') && localStorage.getItem('new_stream_translation') ? "select_page_content_button to" : "select_page_content_button disabled to"}
                    onClick={() => {
                        nav('/streams/create/episode')
                    }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>{
                            localStorage.getItem('new_stream_episode') ?
                                localStorage.getItem('new_stream_episode') + ' эп.' : 'Эпизод не выбран'
                        }</p>
                        <p className={"select_page_content_button_desc"}>Выбрать эпизод</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>

                <p className={"select_page_content_warning"} style={{marginTop: 20}}>
                    {
                        (localStorage.getItem('new_stream_name') &&
                            localStorage.getItem('new_stream_anime') &&
                            localStorage.getItem('new_stream_translation') &&
                            localStorage.getItem('new_stream_episode')) ?
                            'Все хорошо. Указаны все данные, необходимые для начала стрима' :
                            'Еще указаны не все данные, необходимые для начала стрима'
                    }
                </p>

                <div className={(localStorage.getItem('new_stream_name') &&
                    localStorage.getItem('new_stream_anime') &&
                    localStorage.getItem('new_stream_translation') &&
                    localStorage.getItem('new_stream_episode')) ? "select_page_content_button to" : "select_page_content_button to disabled"}
                     style={{marginTop: 20}} onClick={() => {
                    create()
                }}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>Создать</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>
            </div>
        </>
    );
};

export default StreamsCreatePage;