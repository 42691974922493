import React, {useEffect, useState} from 'react';
import {checkAnimeBan, getAnimeAiredOnString} from "../../funcs/anime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {Anime, EpisodeInfo, EpisodeSave} from "../../types";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {secondsToTime} from "../../funcs/time";
import {findMinEpisode} from "../../funcs/kodik";
import {getUserPickedTranslation} from "../../funcs/users";
import {getEpisodesInfo, getWatchlogsByAnimeId} from "../../api/common";
import KinoPoisk from "../assets/KinoPoisk";
import Wink from "../assets/Wink";
import Okko from "../assets/Okko";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons";

const AnimePlayButtonBlock = ({anime}: {anime: Anime | undefined}) => {
    let [open, setOpen] = useState(false)

    let nav = useNavigate()
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [userWatchLog, setUserWatchLog] = useState<EpisodeSave>()
    let [kodik, setKodik] = useState<EpisodeInfo[]>([])

    useEffect(() => {
        (async () => {
            setKodik(await getEpisodesInfo(+(anime?.id ?? 0)))

            if (cookies.access && anime?.id) {
                let user_watch_log = await getWatchlogsByAnimeId(anime.id)
                if (user_watch_log) setUserWatchLog(user_watch_log)
            }
        })()
    }, []);

    return (
        <>
            {
                open &&
                <div className="anime_play_block_services_popup_bg">
                    <div className="anime_play_block_services_popup">
                        <p className={"anime_play_block_services_popup_title"}>Смотрите на лицензионных сервисах!</p>

                        {
                            anime?.external_links.map(el =>
                                <div className="anime_play_block_services_popup_service" onClick={() => {
                                    window.location.href = el.url
                                }}>
                                    {
                                        el.url.includes('hd.k') && <KinoPoisk size={40}/>
                                    }
                                    {
                                        el.url.includes('wink') && <Wink size={40}/>
                                    }
                                    {
                                        el.url.includes('okko') && <Okko size={40}/>
                                    }

                                    <div>
                                        {
                                            el.url.includes('hd.k') && <p>Кинопоиск HD</p>
                                        }
                                        {
                                            el.url.includes('wink') && <p>Wink</p>
                                        }
                                        {
                                            el.url.includes('okko') && <p>Okko</p>
                                        }
                                        <p>{el.url}</p>
                                    </div>
                                </div>)
                        }

                        <div className={"anime_play_block_services_popup_service_back"} onClick={() => setOpen(false)}>
                            <FontAwesomeIcon icon={faArrowLeftLong}/>
                            <p>Назад</p>
                        </div>
                    </div>
                </div>
            }

            <div className="anime_play_block">
                {
                    !checkAnimeBan(anime) && anime?.status != 'anons' && !(window?.location?.hostname?.includes('.ru')) ?
                        <>
                            <button className={"anime_play_block_button to"}
                                    onClick={() => {
                                        let l = `/anime/${anime?.id}/play/?rtx=true&translation=${getUserPickedTranslation(+(anime?.id ?? 0), kodik)}`;

                                        if (userWatchLog) {
                                            l += `&last=${userWatchLog?.time + '_' + userWatchLog?.episode?.number}&episode=${userWatchLog?.episode?.number}`
                                        } else {
                                            l += `&episode=${findMinEpisode(kodik)}`
                                        }
                                        nav(l)
                                    }}>
                                <FontAwesomeIcon icon={faPlay}
                                                 className={"anime_play_block_button_play_icon"}/>
                                <p className={"play_button_text golos"} style={{
                                    fontWeight: 600
                                }}>{
                                    !userWatchLog ? `${findMinEpisode(kodik)} эпизод` : `${userWatchLog?.episode?.number} эп. (${secondsToTime(userWatchLog?.time ?? 0)})`
                                }</p>
                            </button>
                        </> :
                        <button className={"anime_play_block_button to"} onClick={() => {
                            // window.location.href = checkAnimeBan(anime)?.original ?? '#'
                            if (anime?.external_links && anime?.external_links?.length > 0)
                                setOpen(true)
                        }} style={{background: '#272727'}}>
                            {
                                anime?.status === 'anons' &&
                                <span>{getAnimeAiredOnString(anime) ?? 'Скоро'}</span>
                            }
                            {
                                !checkAnimeBan(anime) && window?.location?.hostname?.includes('.ru') &&
                                <>
                                    <FontAwesomeIcon icon={faBan}
                                                     className={"anime_play_block_button_play_icon"}/>
                                    <span>Недоступно</span>
                                </>
                            }
                            {
                                (checkAnimeBan(anime) && anime?.external_links && anime?.external_links?.length > 0) && <>
                                    <div className="anime_play_block_button_servicesw">
                                        <div className="anime_play_block_button_services">
                                            <div className="anime_play_block_button_services_circle">
                                                <KinoPoisk size={20}/>
                                            </div>
                                            <div className="anime_play_block_button_services_circle">
                                                <Wink size={17}/>
                                            </div>
                                            <div className="anime_play_block_button_services_circle">
                                                <Okko size={17}/>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        </button>
                }
            </div>
        </>
    );
};

export default AnimePlayButtonBlock;