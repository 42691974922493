export function timeAgoTextNew(timestamps: string): string {
    if (timestamps.length < 6) {
        return "Недостаточно данных времени";
    }

    const now = new Date();
    const pastTimestamp = new Date(timestamps);

    const elapsedMilliseconds = now.getTime() - pastTimestamp.getTime();
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

    if (elapsedSeconds < 60) {
        return "несколько секунд назад";
    } else if (elapsedSeconds < 3600) {
        const minutes = Math.floor(elapsedSeconds / 60);
        return `${minutes} ${pluralize(minutes, 'минуту', 'минуты', 'минут')} назад`;
    } else if (elapsedSeconds < 86400) {
        const hours = Math.floor(elapsedSeconds / 3600);
        return `${hours} ${pluralize(hours, 'час', 'часа', 'часов')} назад`;
    } else if (elapsedSeconds < 604800) {
        const days = Math.floor(elapsedSeconds / 86400);
        return `${days} ${pluralize(days, 'день', 'дня', 'дней')} назад`;
    } else if (elapsedSeconds < 2419200) {
        const weeks = Math.floor(elapsedSeconds / 604800);
        return `${weeks} ${pluralize(weeks, 'неделю', 'недели', 'недель')} назад`;
    } else {
        const months = Math.floor(elapsedSeconds / 2419200);
        return `${months} ${pluralize(months, 'месяц', 'месяца', 'месяцев')} назад`;
    }
}

export function timeAgoText(timestamps: number[]): string {
    if (timestamps.length < 6) {
        return "Недостаточно данных времени";
    }

    const now = new Date();
    const pastTimestamp = new Date(
        timestamps[0],  // Год
        timestamps[1] - 1,  // Месяц (от 0 до 11)
        timestamps[2],  // День
        timestamps[3] + 3 || 0,  // Часы
        timestamps[4] || 0,  // Минуты
        timestamps[5] || 0   // Секунды
    );

    const elapsedMilliseconds = now.getTime() - pastTimestamp.getTime();
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

    if (elapsedSeconds < 60) {
        return "несколько секунд назад";
    } else if (elapsedSeconds < 3600) {
        const minutes = Math.floor(elapsedSeconds / 60);
        return `${minutes} ${pluralize(minutes, 'минуту', 'минуты', 'минут')} назад`;
    } else if (elapsedSeconds < 86400) {
        const hours = Math.floor(elapsedSeconds / 3600);
        return `${hours} ${pluralize(hours, 'час', 'часа', 'часов')} назад`;
    } else if (elapsedSeconds < 604800) {
        const days = Math.floor(elapsedSeconds / 86400);
        return `${days} ${pluralize(days, 'день', 'дня', 'дней')} назад`;
    } else if (elapsedSeconds < 2419200) {
        const weeks = Math.floor(elapsedSeconds / 604800);
        return `${weeks} ${pluralize(weeks, 'неделю', 'недели', 'недель')} назад`;
    } else {
        const months = Math.floor(elapsedSeconds / 2419200);
        return `${months} ${pluralize(months, 'месяц', 'месяца', 'месяцев')} назад`;
    }
}

// Функция для выбора правильного склонения слова в зависимости от числа
function pluralize(count: number, one: string, few: string, many: string): string {
    if (count === 1) {
        return one;
    } else if (count >= 2 && count <= 4) {
        return few;
    } else {
        return many;
    }
}

export const createDate = (timeArray: number[]): number => {
    if (!timeArray) return -1;

    // уменьшаем значение месяца на 1, чтобы привести его к нумерации JavaScript
    // timeArray[1] = timeArray[1] - 1;

    // создаем новый объект Date, используя значения массива
    const date = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4], timeArray[5], timeArray[6] / 1000000);

    // преобразуем объект Date в UNIX-таймстамп (секунды)
    return Math.floor(date.getTime() / 1000);
};

export function oneFToTwo(x: any): string {
    if (!x) return x;
    if ((x + '').length === 1) return "0" + x;
    return x;
}

export const dateToSort = (timeArray: number[]): number => {
    if (timeArray.length < 6) return -1;

    let endDate = "";
    endDate += timeArray[0]; // year
    endDate += oneFToTwo(timeArray[1]); // month
    endDate += oneFToTwo(timeArray[2]); // date
    endDate += oneFToTwo(timeArray[3]); // hour
    endDate += oneFToTwo(timeArray[4]); // min
    return +endDate;
}

export const nowToSort = (): number => {
    let now = "";
    let n = new Date();

    now += n.getFullYear();
    now += oneFToTwo(n.getMonth() + 1);
    now += oneFToTwo(n.getDate());
    now += oneFToTwo(n.getHours() + 1);
    now += oneFToTwo(n.getMinutes() + 1);
    return +now;
}

export function secondsToTime(seconds: number): string {
    if (isNaN(seconds) || seconds < 0) {
        return "~";
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursStr = hours > 0 ? String(hours).padStart(2, '0') + ':' : '';
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');

    return hoursStr + minutesStr + ':' + secondsStr;
}

export function getCountdownString(currentDate: Date): string {
    const targetDate = new Date(currentDate.getFullYear(), 5, 1); // 1 июня текущего года
    const diff = targetDate.getTime() - currentDate.getTime();

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    const daysString = days === 1 ? 'день' : 'дней';
    const hoursString = hours === 1 ? 'час' : 'часов';
    const minutesString = minutes === 1 ? 'минута' : 'минут';

    return `Осталось ${days} ${daysString} ${hours} ${hoursString} ${minutes} ${minutesString}`;
}