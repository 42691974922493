import React from 'react';
import '../styles/loader.css'

interface Props {
    small?: boolean,
    black?: boolean
}

const Loader = (props: Props) => {
    return (
        <span className={props.small ? (props.black ? "loader small black" : "loader small") : "loader"}>
            
        </span>
    );
};

export default Loader;