import React, {useEffect, useState} from 'react';
import {cacheableAxios} from "../funcs/cacheableAxios";
import {BACKEND} from "../consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons/faClockRotateLeft";
import {faFaceFrownOpen, faFolderClosed} from "@fortawesome/free-solid-svg-icons";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import {Anime} from "../types";
import {getSystemStatusFolder} from "../funcs/folders";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import {proxyImg} from "../funcs/poster";
import {getAnimeIds, getFoldersMy} from "../api/common";
import {useCookies} from "react-cookie";
import MaterialButtonWLoading from "./MaterialButtonWLoading";
import M3OutlinedButton from "./m3/M3OutlinedButton";

const MyPageFoldersBlock = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])


    let [items, setItems] = useState<any[]>([])
    let [animes, setAnimes] = useState<Anime[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!cookies.access) return;
            setIsLoading(true)

            let itemsS = await getFoldersMy()
            setItems(itemsS?.slice(0, 10))
            let ids = itemsS?.map((e: any) => e.items[0])?.map((e: any) => e?.releaseId)

            let animesS = await getAnimeIds(ids.join(','))
            setAnimes(animesS)
            setIsLoading(false)
        })()


    }, [cookies.access])

    return (
        <div className={"my_page_block"}>
            <p className="my_page_block_title">
                <div>
                    <FontAwesomeIcon icon={faFolderOpen}/>
                    <span className={"my_page_block_title_text"}>Папки</span>
                </div>
                {/*<p className="my_page_block_title_show_all blue_button" onClick={() => nav('/my/folders')}>Посмотреть все</p>*/}
                <M3OutlinedButton onClick={() => nav('/my/folders')} text={'Посмотреть все'}/>
            </p>
            {
                isLoading ? <div className="my_page_block_list_loading">
                        <Loader/>
                    </div> :
                    <div className="my_page_block_list">
                        {
                            animes?.map((el, index) =>
                                <div className="my_page_block_list_history_el to" onClick={e => {
                                    nav(`/my/folders/${encodeURIComponent(items?.[index]?.title)}?id=${items?.[index]?.id}`)
                                }} key={el?.id}>
                                    <div className="my_page_block_list_history_el_poster" style={{
                                        backgroundImage: `url('${proxyImg(el?.id, el?.poster)}')`
                                    }}>
                                        <div
                                            className="my_page_block_list_history_el_poster_num">
                                            <span className={"my_page_block_list_history_el_poster_num_n"}>{items?.[index]?.items?.length} эл.</span>
                                        </div>
                                    </div>
                                    <p className="my_page_block_list_history_el_title">{items?.[index]?.title}</p>
                                    <p className="my_page_block_list_history_el_desc">{getSystemStatusFolder(items?.[index]?.title) ? 'Системная' : 'Пользовательская'}</p>
                                </div>)
                        }
                        {
                            items?.length === 0 &&
                            <div className="my_page_block_list_not_found">
                                <FontAwesomeIcon icon={faFaceFrownOpen} />
                                <p>Нет элементов</p>
                            </div>
                        }
                    </div>
            }

        </div>
    );
};

export default MyPageFoldersBlock;