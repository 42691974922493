import React from 'react';
import {UserInfo} from "../types";

const UserEmoji = ({user}: {user: UserInfo | undefined}) => {
    return (
        <>
            {
                user?.sub &&
                <span className="emoji_user_span">{user?.emoji}</span>
            }
        </>
    );
};

export default UserEmoji;