import React, {useEffect, useState} from 'react';
import BackQuadButton from "../../ui/BackQuadButton";
import AnimeElementIdsBlock from "../../AnimeElementIdsBlock";
import '../../../styles/upper_page.css'
import {getWatchlogsUserPopular} from "../../../api/common";

const HomeSuggestionsPopularPage = () => {
    let [ids, setIds] = useState<number[]>([])

    useEffect(() => {
        (async () => {
            setIds(await getWatchlogsUserPopular())
        })()
    }, [])

    return (
        <div style={{padding: "50px 15px 20px 15px"}}>
            <div className="upper_page_header">
                <BackQuadButton/>
                <p className="upper_page_header_title">Популярное</p>
            </div>

            <AnimeElementIdsBlock ids={ids} limit={10} pagination={true}/>
        </div>
    );
};

export default HomeSuggestionsPopularPage;