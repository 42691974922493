import React, {useEffect, useState} from 'react';
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import {useNavigate, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {getAnimeIds, getFoldersMy} from "../../../api/common";
import axios from "../../../core/axios";
import {Anime} from "../../../types";

const AnimeFoldersPage = () => {
    let nav = useNavigate()
    let params = useParams()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [loading, setLoading] = useState(false)

    let [userFolders, setUserFolders] = useState<any[]>([])
    let [currentFolder, setCurrentFolder] = useState<any[]>([])

    useEffect(() => {
        (async () => {
            // getting user folders
            let userfoldersS = await getFoldersMy()
            setUserFolders(userfoldersS)
            setCurrentFolder(userfoldersS?.filter((v: any) => v.items.map((e: any) => e.releaseId).includes(+(params.id ?? -1))).map((g: any) => g.title))
        })()
    }, []);

    const actionToFolder = (folder: string, anime: number, action: 'add' | 'remove') => {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        axios({
            method: 'post',
            url: `app2/folders/${action}`,
            data: {
                folderTitle: folder,
                releaseId: +anime
            }
        })
    }

    return (
        <>
            <AutoFadeTopBar title={'Добавить в список'} alwaysShown={true}/>

            {
                loading ? <div className="ccp">
                    <Loader/>
                </div> : <>
                    <div className="select2_block">
                        {
                            userFolders?.map(e =>
                            <div className="select2_block_el" style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} onClick={() => {
                                if (currentFolder.includes(e.title)) {
                                    setCurrentFolder([...currentFolder.filter(v => v !== e.title)])
                                    actionToFolder(e.title, +(params?.id ?? -1), 'remove')
                                } else {
                                    setCurrentFolder([...currentFolder, e.title])
                                    actionToFolder(e.title, +(params?.id ?? -1), 'add')
                                }
                                nav(-1)
                            }}>
                                    <div>
                                        <p className="select2_block_el_title">{e.title}</p>
                                        <p className="select2_block_el_desc">{
                                            e?.items?.length
                                        } эл.</p>
                                    </div>
                                {
                                    currentFolder?.includes(e?.title) ?
                                        <FontAwesomeIcon icon={faMinus} /> :
                                        <FontAwesomeIcon icon={faPlus} />
                                }

                            </div>)
                        }
                    </div>
                </>
            }
        </>
    );
};

export default AnimeFoldersPage;