import React, {useEffect, useRef, useState} from 'react';
import BackQuadButton from "../../ui/BackQuadButton";
import SimpleDropBox from "../../SimpleDropBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {BACKEND, genres} from "../../../consts";
import {Anime} from "../../../types";
import AnimeElement from "../../AnimeElement";
import {cacheableAxios} from "../../../funcs/cacheableAxios";

type ChooseMode = 'none' | 'genres' | 'score' | 'country' | 'studios' | 'episodes' | 'year';

const HomeSuggestionsFilterPage = () => {
    let [items, setItems] = useState<Anime[]>([])
    let forLoadDiv = useRef<HTMLDivElement>(null)
    let [isLoading, setIsLoading] = useState(false)
    let [page, setPage] = useState(0)


    let [chooseMode, setChooseMode] = useState<ChooseMode>('none')

    let [cGenres, setCGenres] = useState<string[]>([])
    let [scoreGreater, setScoreGreater] = useState(-1)
    let [cEpisodes, setCEpisodes] = useState('')

    const fetchData = async (page: number) => {
        const fUrl = BACKEND + `anime/filter?size=${10}&page=${page}&score=${scoreGreater}&episodes_start=${+cEpisodes.split('-')[0]}&episodes_end=${+cEpisodes.split('-')[1]}&genres=${encodeURIComponent(cGenres.join(' '))}`

        setIsLoading(true)
        let animeList = await cacheableAxios({
            method: 'get',
            url: fUrl
        }, 60 * 60)
            .then(r => r)
            .catch(ignored => ([]))

        if (!animeList?.length) return;
        setItems([...items, ...animeList])
        setPage(page + 1)
        setIsLoading(false)
    }

    const clean = async () => {
        setPage(0)
        setItems([])

        const fUrl = BACKEND + `anime/filter?size=${10}&page=${0}&score=${scoreGreater}&episodes_start=${+cEpisodes.split('-')[0]}&episodes_end=${+cEpisodes.split('-')[1]}&genres=${encodeURIComponent(cGenres.join(' '))}`

        setIsLoading(true)
        let animeList = await cacheableAxios({
            method: 'get',
            url: fUrl
        }, 60 * 60)
            .then(r => r)
            .catch(ignored => ([]))

        if (!animeList?.length) return;
        setItems(animeList)
        setPage(page + 1)
        setIsLoading(false)
    }

    // useEffect(() => {
    //     // setItems([])
    //     // setPage(0)
    //     fetchData()
    // }, [cEpisodes, scoreGreater, cGenres])

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
            fetchData(page)
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '500px', // Область видимости - верх страницы
            threshold: 0.1,    // Порог видимости
        });

        if (forLoadDiv.current) {
            observer.observe(forLoadDiv.current);
        }

        return () => {
            if (forLoadDiv.current && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <div style={{padding: "80px 15px 20px 15px"}}>
            {
                chooseMode === 'none' ?
                    <>
                        <div className="upper_page_header">
                            <BackQuadButton/>
                            <p className="upper_page_header_title">Фильтр аниме</p>
                        </div>
                        <div className="filters_block">
                            <button
                                className={cGenres.length > 0 ? "filters_block_button active to" : "filters_block_button to"}
                                onClick={() => setChooseMode('genres')}>{
                                cGenres.length > 0 ? cGenres.join(', ') : 'Выбрать жанры'
                            }</button>
                            <button
                                className={scoreGreater > -1 ? "filters_block_button active to" : "filters_block_button to"}
                                onClick={() => setChooseMode('score')}
                            >{scoreGreater !== -1 ? scoreGreater + '+' : 'Выбрать оценку'}</button>

                            <button className="filters_block_button">Выбрать страну</button>
                            <button className="filters_block_button">Выбрать студию</button>
                            <button
                                className={cEpisodes !== '' ? "filters_block_button active to" : "filters_block_button to"}
                                onClick={() => setChooseMode('episodes')}>
                                {
                                    cEpisodes === '' ? 'Выбрать кол-во эпизодов' :  cEpisodes + ' эп.'
                                }
                            </button>
                            <button className="filters_block_button to">Выбрать год</button>
                        </div>
                    </> :
                    <div className="select_page_header filters_page_header">
                        <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                            clean()
                            setChooseMode('none')
                        }}/>
                        {chooseMode === 'genres' && <p>Выбор жанров</p>}
                        {chooseMode === 'score' && <p>Выбор оценки</p>}
                        {chooseMode === 'episodes' && <p>Выбор количества эпизодов</p>}

                        <p></p>
                    </div>
            }

            {
                chooseMode === 'genres' &&
                genres.map(e => <button
                    className={cGenres.includes(e) ? "filters_block_button active to" : "filters_block_button to"}
                    key={e + 'genre'}
                    onClick={() => {
                        if (!cGenres.includes(e)) {
                            setCGenres([...cGenres, e])
                        } else {
                            setCGenres([...cGenres.filter(v => v != e)])
                        }
                    }}
                >{e}</button>)
            }

            {
                chooseMode === 'score' &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => <button
                    className={e === scoreGreater ? "filters_block_button active" : "filters_block_button"}
                    key={e + 'score'}
                    onClick={() => {
                        setScoreGreater(+e)
                    }}
                >{e + '+'}</button>)
            }

            {
                chooseMode === 'episodes' &&
                ['0-12', '11-13', '23-25', '25-999'].map(e => <button
                    className={e === cEpisodes ? "filters_block_button active" : "filters_block_button"}
                    key={e + 'ep'}
                    onClick={() => {
                        setCEpisodes(e)
                    }}
                >{e}</button>)
            }

            <div className="animes_list_block">
                {
                    items.map(el => <AnimeElement anime={el} key={el.id}/>)
                }

                <div ref={forLoadDiv}></div>
            </div>

        </div>
    );
};

export default HomeSuggestionsFilterPage;