import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons/faCircleUser";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import {faMedal} from "@fortawesome/free-solid-svg-icons/faMedal";
import {faGear} from "@fortawesome/free-solid-svg-icons/faGear";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
import {useNavigate} from "react-router-dom";
import {faBell} from "@fortawesome/free-solid-svg-icons/faBell";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {faMountainSun} from "@fortawesome/free-solid-svg-icons";

const UserMenuSettingsPage = () => {
    let nav = useNavigate()

    return (
        <>

            <AutoFadeTopBar title={`Настройки`} alwaysShown={true}/>


            <div className="select_page_content spc">
                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/settings/notifications')}>
                    <FontAwesomeIcon icon={faBell}/>
                    <p>Уведомления</p>
                </div>
                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/settings/view')}>
                    <FontAwesomeIcon icon={faMountainSun}/>
                    <p>Вид</p>
                </div>

            </div>
        </>
    );
};

export default UserMenuSettingsPage;