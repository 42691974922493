import {createSlice, type PayloadAction} from '@reduxjs/toolkit'

interface InitialState {
    w2: {
        hubId: string,
        messages: WatchTogetherHubMessage[],
        watchData: WatchTogetherHubWatchDto,
        hubInfo: WatchTogetherHubInfo,
        player: 'anitype' | 'kodik'
    }
}

const initialState: InitialState = {
    w2: {
        hubId: '',
        messages: [],
        watchData: {
            time: 0,
            anime_id: -1,
            episode: -1,
            translation: '',
            is_paused: false
        },
        hubInfo: {
            key: '',
            owner: '',
            users: []
        },
        player: 'anitype'
    }
}

export const w2Slice = createSlice({
    name: 'w2',
    initialState,
    reducers: {
        changePlayer: (state, action: PayloadAction<string>) => {
            state.w2.player = action.payload as 'anitype' | 'kodik'
        },
        setHubId: (state, action: PayloadAction<string>) => {
            state.w2.hubId = action.payload
        },
        setWatchData: (state, action: PayloadAction<WatchTogetherHubWatchDto>) => {
            state.w2.watchData = action.payload
        },
        setWatchDataEpisode: (state, action: PayloadAction<number>) => {
            state.w2.watchData.episode = action.payload
        },
        setWatchDataTranslation: (state, action: PayloadAction<string>) => {
            state.w2.watchData.translation = action.payload
        },
        setWatchDataTime: (state, action: PayloadAction<number>) => {
            state.w2.watchData.time = action.payload
        },
        setWatchDataPaused: (state, action: PayloadAction<boolean>) => {
            state.w2.watchData.is_paused = action.payload
        },
        setWatchDataAnime: (state, action: PayloadAction<number>) => {
            state.w2.watchData.anime_id = action.payload
        },
        setHubInfo: (state, action: PayloadAction<WatchTogetherHubInfo>) => {
            state.w2.hubInfo = action.payload
        },
        addMessage: (state, action: PayloadAction<WatchTogetherHubMessage>) => {
            state.w2.messages = [action.payload, ...state.w2.messages]
        },
    },
})

export interface WatchTogetherHubMessage {
    ID: number,
    text: string,
    hub_key: string,
    user_name: string
}

export interface WatchTogetherHubWatchDto {
    time: number
    anime_id: number
    episode: number
    translation: string
    is_paused: boolean
}

export interface WatchTogetherHubInfo {
    key: string,
    owner: string,
    users: {
        updated_at: number,
        username: string,
        watch_data: WatchTogetherHubWatchDto
    }[]
}