import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {faSliders} from "@fortawesome/free-solid-svg-icons/faSliders";

interface Props {
    selectedRightTab: string,
    setSelectedRightTab: (value: string) => void,
    tabIcons: boolean
}

const W2TabsModule = (p: Props) => {
    return (
        <div className="w2_tabs_module">
            {
                ['Чат', 'Участники', 'Управление'].map(el =>
                    <>
                        <p key={`tab${el}`} className={p.selectedRightTab === el ? 'active' : ''}
                           onClick={() => p.setSelectedRightTab(el)} style={{
                            textOverflow: p.tabIcons ? 'clip' : 'ellipsis'
                        }}>
                            {
                                p.tabIcons ? <>
                                    {el === 'Чат' && <FontAwesomeIcon icon={faComments}/>}
                                    {el === 'Участники' && <FontAwesomeIcon icon={faUserGroup}/>}
                                    {el === 'Управление' && <FontAwesomeIcon icon={faSliders}/>}
                                </> : el
                            }

                        </p>
                    </>)
            }
        </div>
    );
};

export default W2TabsModule;