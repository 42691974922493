import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {Comment, UserInfo} from "../../../types";
import Loader from "../../Loader";
import '../../../styles/profile_page.css'
import UserAvatar, {getAvatar, getLevelColor, getUserBanner, LEVEL_COLORS} from "../../UserAvatar";
import {useCookies} from "react-cookie";
import Error from "../../Error";
import UserEmoji from "../../UserEmoji";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import AnimeCommentEl from "../../anime-comments-components/AnimeComment";
import {sendCommentAsync} from "../../../api/comments";
import {countCommentsByUser, getAnimeIds, getCommentsByTarget, getUserInfoByName} from "../../../api/common";
import {oneFToTwo} from "../../../funcs/time";
import axios from "../../../core/axios";
import {BACKEND} from "../../../consts";
import UseJokeMenu from "../../UseJokeMenu";
import {getCurrentWS3Info} from "../../../funcs/users";
import {formatMembersCount} from "../../../funcs/numbers";
import M3FilledTonalButton from "../../m3/M3FilledTonalButton";
import M3TextArea from "../../m3/M3TextArea";

const ProfilePage = () => {
    let params = useParams()
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [isLoading, setIsLoading] = useState(false)
    let [info, setInfo] = useState<UserInfo>()
    let [online, setOnline] = useState(false)
    let [onlineHub, setOnlineHub] = useState<any>(null)
    let [commentsCount, setCommentsCount] = useState(0)
    let [selections, setSelections] = useState<any[]>([])

    let [tab, setTab] = useState('comments')
    let [newCommentText, setNewCommentText] = useState('')
    let [err, setErr] = useState('')
    let [pComments, setPComments] = useState<Comment[]>([])

    let [openedBadge, setOpenedBadge] = useState<any>(null)

    let [jokerOpened, setJokerOpened] = useState(false)

    let [hasOtherAcs, setHasOtherAcs] = useState(true)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let i = await getUserInfoByName(params?.username ?? '')
            setInfo(i)

            axios({
                url: BACKEND + `events/checkonline?username=${params?.username}`
            })
                .then(r => setOnline(r.data.online))
                .catch(ignored => ignored)

            axios({
                url: BACKEND + `ws3/user/${params?.username}`
            })
                .then(r => {
                    let rdata = getCurrentWS3Info(r.data, params?.username ?? '')

                    if (rdata) {
                        setOnlineHub(rdata)

                        getAnimeIds(+(rdata?.anime_id ?? 0))
                            .then(res => {
                                let anime = res?.[0];
                                setOnlineHub({
                                    ...rdata,
                                    title: anime?.titles?.russian,
                                    count: rdata?.usersCount
                                })
                            })
                            .catch(ignored => ignored)
                    }

                })
                .catch(ignored => ignored)

            let cCount = await countCommentsByUser(i?.id ?? 0)
            setCommentsCount(cCount)

            // let selectionsS = await cacheableAxios({
            //     url: `app2/selections/user/${params?.username}`
            // }, 60 * 60)
            //     .then(r => r?.content)
            //     .catch(e => ([]))
            // setSelections(selectionsS)



            let comments = await getCommentsByTarget(`user${i?.id}`, 0, 10)
            setPComments(comments)

            setTimeout(() => setIsLoading(false), 500)
        })()
    }, [params?.username])

    return (
        <div style={{padding: "0px 15px 20px 15px"}}>
            <Error message={err} setMessage={setErr}/>


            <AutoFadeTopBar title={`Профиль`} alwaysShown={true}/>

            <div className={openedBadge ? "select_sub_popup" : "select_sub_popup hidden"} style={{
                alignItems: "center",
                background: `linear-gradient(180deg, ${openedBadge?.badge?.background ?? '#000'}, #171717)`
            }}>
                <h1 style={{fontSize: 44}}>{openedBadge?.badge?.emoji}</h1>
                <h1 className={'profile_page_badge_opened_title'}>{`Сезонная награда "${openedBadge?.badge?.title}"`}</h1>
                <p className={'profile_page_badge_opened_desc'}>{openedBadge?.badge?.description}</p>
                <p className={'profile_page_badge_opened_desc'} style={{
                    marginTop: 40
                }}>Получена <span> </span>
                    {oneFToTwo((new Date(openedBadge?.createdDate)).getDate())}.{oneFToTwo((new Date(openedBadge?.createdDate)).getMonth() + 1)}.{oneFToTwo((new Date(openedBadge?.createdDate)).getFullYear())}</p>
            </div>
            {openedBadge && <div className="select_sub_popup_close" onClick={() => setOpenedBadge(null)}></div>}



            {isLoading ? <div className="ccp">
                <Loader/>
            </div> : <>
                <div className={"profile_page"}>
                    <div className="user_banner" style={{
                        backgroundImage: `url('${getUserBanner(info)}')`
                    }}>

                    </div>

                    {/*<div className="profile_page_avatar" style={{backgroundImage: `url('${getAvatarLink(info?.avatar ?? '')}')`}}></div>*/}
                    <div className={"user_avatar"}>
                        {/*{*/}
                        {/*    info?.username === 'fllcker' &&*/}
                        {/*    <div className="user_avatar_append" style={{*/}
                        {/*        backgroundImage: `url('https://s3.timeweb.com/24399392-anitype-videos/test/Без имени-3.png')`,*/}
                        {/*        zIndex: 56*/}
                        {/*    }}></div>*/}
                        {/*}*/}

                        {/*{*/}
                        {/*    info?.username === 'fllcker' &&*/}
                        {/*    <div className="user_avatar_bg" style={{*/}
                        {/*        backgroundImage: `url('https://s3.timeweb.com/24399392-anitype-videos/test/leaf.gif')`,*/}
                        {/*        zIndex: 55*/}
                        {/*    }}></div>*/}
                        {/*}*/}


                        <div style={{
                            backgroundImage: `url('${getAvatar(info, 3)}')`,
                            // outline: `4px solid ${getLevelColor(info?.level ?? 0)}`,
                            border: '4px solid black',
                            width: 100,
                            height: 100,
                            minWidth: 100,
                            minHeight: 100,
                            marginTop: -45
                        }} className={"user_avatar_img"}>
                        </div>

                        {/*<div className="user_avatar_circle" style={{*/}
                        {/*    background: `linear-gradient(180deg, ${getLevelColor(info?.level ?? 0)}, ${LEVEL_COLORS[ LEVEL_COLORS.indexOf(getLevelColor(info?.level ?? 0)) + 1 ]})`*/}
                        {/*}}></div>*/}
                        <div className="avatar_badge_cont">
                            <div className="avatar_badge"
                                 style={{
                                     background: getLevelColor((info?.level ?? 0) + 5),
                                     fontSize: 11,
                                     padding: '2px 8px',
                                     borderRadius: 8,
                                     outline: '3px solid black'
                                 }}>{info?.level ?? 0}</div>
                        </div>
                    </div>

                    <p className={"profile_page_username"}>
                        <span className={info?.sub ? "sub" : ""}>{info?.username}</span>
                        <UserEmoji user={info}/>
                    </p>
                    <p className="profile_page_status">{info?.status}</p>

                    {
                        online &&
                        <p className="profile_page_status profile_page_online">онлайн
                            {
                                onlineHub &&
                                <span>{`, смотрит "${onlineHub?.title}" ${onlineHub?.episode} эпизод в совместном просмотре. ${onlineHub?.count} ${formatMembersCount(onlineHub?.count ?? 0)}`}</span>
                            }
                        </p>
                    }

                    <div className="profile_page_badges">
                        {info?.badges.map(badge =>
                            <p className="profile_page_badge" key={badge.id} onClick={() => {
                                setOpenedBadge(badge)
                            }} style={{
                                background: badge.badge.background,
                                color: badge.badge.color
                            }}>{badge?.badge?.emoji}{badge?.badge?.title}</p>)}
                    </div>

                    {
                        localStorage.getItem('username') === info?.username ?
                            <button className={"profile_page_edit_button to"}
                                    onClick={() => nav(`/profile/${info?.username}/edit`)}>Редактировать</button> :
                            <>
                                {/*<button className={"profile_page_edit_button joke to"}*/}
                                {/*        onClick={() => {*/}
                                {/*            setJokerOpened(true)*/}
                                {/*        }}>Использовать шутку*/}
                                {/*</button>*/}
                            </>
                    }
                    {
                        jokerOpened &&
                        <UseJokeMenu setOpen={setJokerOpened} username={info?.username ?? ''}/>

                    }


                    <div className="profile_page_main_stats">
                        <div className={"profile_page_main_stats_el"}>
                            <p>{commentsCount}</p>
                            <p>комментариев</p>
                        </div>
                        <div className={"profile_page_main_stats_el"}>
                            <p>{selections?.length}</p>
                            <p>подборок</p>
                        </div>
                    </div>

                    <div className="profile_tabs">
                        <div className={tab === 'comments' ? "profile_tab active" : "profile_tab"}
                             onClick={() => setTab('comments')}>
                            <p>Комментарии</p>
                            <div className="indicator"></div>
                        </div>
                        {/*<div className={tab === 'selections' ? "profile_tab active" : "profile_tab"}*/}
                        {/*     onClick={() => setTab('selections')}>*/}
                        {/*    <p>Коллекции</p>*/}
                        {/*    <div className="indicator"></div>*/}
                        {/*</div>*/}

                    </div>

                    {/*{*/}
                    {/*    tab === 'selections' &&*/}
                    {/*    <div className="profile_tab_content"*/}
                    {/*         style={{flexDirection: 'row', overflowX: 'scroll', marginLeft: -25, padding: '0 25px'}}>*/}
                    {/*        {*/}
                    {/*            selections.map(el =>*/}
                    {/*                <div className="home_block_list_selection_el" key={el.id}*/}
                    {/*                     onClick={() => nav(`/selection/${el.id}`)}>*/}

                    {/*                    <div className="home_block_list_selection_el_poster">*/}
                    {/*                        <div className="home_block_list_selection_el_poster_img" style={{*/}
                    {/*                            backgroundImage: `url('${'https://anitype.site/app2/media/download?fileName=selections/posters/' + el.poster}')`*/}
                    {/*                        }}/>*/}

                    {/*                        <div className="home_block_list_selection_el_poster_grad"></div>*/}

                    {/*                        <div className="home_block_list_selection_el_poster_info">*/}
                    {/*                            <p className="home_block_list_selection_el_title">{el.title}</p>*/}
                    {/*                            <p className="home_block_list_selection_el_desc">{el.description}</p>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    {/*                </div>)*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {
                        tab === 'comments' &&
                        <div className="profile_tab_content">
                            <M3TextArea value={newCommentText} setValue={setNewCommentText}
                                        placeholder={'Напишите комментарий'}/>

                            <div className="profile_tab_content_write_comment_buttons">
                                <M3FilledTonalButton text={'Отправить'} onClick={() => {
                                    if (!newCommentText) return;
                                    if (!cookies.access) {
                                        let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                                        window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                                        return
                                    }

                                    (async () => {
                                        let text = newCommentText
                                        setNewCommentText('')
                                        let res = await sendCommentAsync(text, `user${info?.id}`, '', '')

                                        if (typeof res === 'string') {
                                            setErr(res)
                                        } else {
                                            setPComments([res, ...pComments])
                                        }
                                    })()
                                }}/>
                            </div>


                            {
                                pComments.map(el =>
                                    <AnimeCommentEl el={el}/>)
                            }

                        </div>
                    }

                    {
                        pComments.length === 0 && tab === 'comments' &&
                        <p style={{marginTop: 50, color: '#bbb', maxWidth: 200, textAlign: 'center'}}>Комментарии
                            отсутствуют</p>
                    }

                    {
                        selections.length === 0 && tab === 'selections' &&
                        <p style={{marginTop: 50, color: '#bbb', maxWidth: 200, textAlign: 'center'}}>Коллекции
                            отсутствуют</p>
                    }
                </div>
            </>
            }
        </div>
    );
};

export default ProfilePage;