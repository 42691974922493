import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {useNavigate, useParams} from "react-router-dom";
import {Anime, Comment, UserInfo} from "../../../types";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import UserAvatar from "../../UserAvatar";
import UserEmoji from "../../UserEmoji";
import {timeAgoTextNew} from "../../../funcs/time";
import {removeHtmlAndSpecialChars} from "../../../funcs/anime";
import AnimeCommentEl from "../../anime-comments-components/AnimeComment";
import {sendCommentAsync} from "../../../api/comments";
import Error from "../../Error";
import TogglerButton from "../../TogglerButton";
import axios from "../../../core/axios";
import M3TextArea from "../../m3/M3TextArea";
import M3FilledTonalButton from "../../m3/M3FilledTonalButton";
import CommentMoreButton from "../../CommentMoreButton";

const CommentPage = () => {
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let nav = useNavigate()
    let params = useParams()

    let [loading, setLoading] = useState(true)
    let [comment, setComment] = useState<Comment>();
    let [anime, setAnime] = useState<Anime>()
    let [children, setChildren] = useState<Comment[]>([]);

    let [newCommentText, setNewCommentText] = useState('')
    let [hasSpoiler, setHasSpoiler] = useState(false)

    let [err, setErr] = useState('')

    useEffect(() => {
        fetchData()
    }, [params?.id]);

    async function fetchData() {
        setLoading(true)

        let data: Comment = await axios({
            url: `comments/id/${params?.id ?? ''}`
        }).then(r => r.data).catch(() => nav(-1))
        setComment(data)

        let child: Comment[] = await axios({
            url: `comments/id/${params?.id ?? ''}/children?page=0&size=10`
        }).then(r => r.data).catch(() => [])
        setChildren(child)

        setLoading(false)

        if (data.target.startsWith('anime') && data.target.length > ('anime'.length + 1)) {
            let animeData: Anime = await axios({
                url: `anime/ids?ids=${data.target.split('anime')[1]}`
            }).then(r => r.data[0]).catch(() => null)
            setAnime(animeData)
        }
    }

    return (
        <>
            <Error message={err} setMessage={setErr}/>

            <div style={{padding: "15px 15px 20px 15px"}}>
                <AutoFadeTopBar title={'Ответы на комментарий'} alwaysShown={true}>
                    <CommentMoreButton comment={comment}/>
                </AutoFadeTopBar>


                {
                    loading ? <div className="ccp">
                        <Loader/>
                    </div> : <>
                        <div className="anime_comments_block_comment" key={comment?.id ?? ''}>
                            <div style={{marginRight: 15}} onClick={() => nav(`/profile/${comment?.user.username}`)}>
                                <UserAvatar user={comment?.user as UserInfo | undefined}/>
                            </div>

                            <div className="anime_comments_block_comment_data">
                                <div className="anime_comments_block_comment_data_header">
                                    <p className={comment?.user.sub ? "anime_comments_block_comment_data_header_username sub" : "anime_comments_block_comment_data_header_username"}>
                                        {comment?.user.username}
                                        <UserEmoji user={comment?.user as UserInfo | undefined}/>
                                    </p>
                                    <p className="anime_comments_block_comment_data_header_date">{timeAgoTextNew(comment?.createdAt ?? '')}</p>
                                </div>

                                <div className="anime_comments_block_comment_data_body">
                                    <p>{
                                        removeHtmlAndSpecialChars(comment?.text ?? '')
                                    }</p>
                                </div>

                                {
                                    anime &&
                                    <div className="anime_comments_block_comment_data_body_anime" onClick={() => nav(`/anime/${anime?.id}`)}>
                                        к {anime?.titles.russian}
                                        <span style={{marginLeft: 5, color: '#999', fontSize: 11}}>(нажмите чтобы открыть)</span>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="anime_comments_block_comment_input">

                            <M3TextArea value={newCommentText} setValue={setNewCommentText} placeholder={'Напишите комментарий'}/>

                            <div style={{
                                width: '100%',
                                display: 'flex', justifyContent: 'space-between', marginTop: 10
                            }}>
                                <TogglerButton title={'Есть спойлер'} active={hasSpoiler} setActive={setHasSpoiler}/>

                                <M3FilledTonalButton text={'Отправить'} onClick={() => {
                                    (async () => {
                                        let text = newCommentText
                                        setNewCommentText('')
                                        let res = await sendCommentAsync(text, (params?.target ?? '') + (params?.id ?? ''),
                                            hasSpoiler ? 'spoiler' : '', comment?.id ?? '')
                                        if (typeof res === 'string') {
                                            setErr(res)
                                        } else {
                                            setChildren([res, ...children])
                                        }
                                    })()
                                }}/>
                            </div>

                        </div>
                        {
                            children?.filter(v => !v.tags.map(e => e.value).includes('no_moderated')).map(el => <>
                                <AnimeCommentEl key={el.id} el={el}/>
                            </>)
                        }
                    </>
                }
            </div>
        </>
    );
};

export default CommentPage;