export function declineViews(numViews: number): string {

    if (numViews < 0) {
        return "Отрицательное количество просмотров не поддерживается";
    }

    const formattedNumber = formatNumber(numViews);

    const lastDigit: number = formattedNumber.num % 10;
    const lastTwoDigits: number = formattedNumber.num % 100;


    if (10 < lastTwoDigits && lastTwoDigits < 20) {
        return `${formattedNumber.text} просмотров`;
    }

    if (lastDigit === 1) {
        return `${formattedNumber.text} просмотр`;
    } else if (1 < lastDigit && lastDigit < 5) {
        return `${formattedNumber.text} просмотра`;
    } else {
        return `${formattedNumber.text} просмотров`;
    }
}

function formatNumber(num: number): {text: string, num: number} {
    const abbreviations = ["", " тыс.", " млн.", " млрд.", " трлн."]; // Можно расширить для больших чисел

    let index = 0;
    let formattedNumber = num;

    while (formattedNumber >= 1000) {
        formattedNumber /= 1000;
        index++;
    }

    const formattedString = formattedNumber % 1 === 0 ? formattedNumber.toFixed(0) : formattedNumber.toFixed(1);
    return {text: `${formattedString}${abbreviations[index]}`, num: +(formattedString.split(".").join(""))}
}

export function formatMembersCount(count: number): string {
    const forms = ["участник", "участника", "участников"];

    if (count % 10 === 1 && count % 100 !== 11) {
        return forms[0];
    } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 12 || count % 100 > 14)) {
        return forms[1];
    } else {
        return forms[2];
    }
}

export function calcWithSale(i: number, sale: number) {
    return Math.round(i - (i * sale))
}