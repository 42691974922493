import React, {useEffect, useState} from 'react';
import '../../styles/anime_watch_watch_info_block.css'
import {declineViews} from "../../funcs/numbers";
import {Anime} from "../../types";
import {useCookies} from "react-cookie";
import LikeSvg from "../assets/LikeSvg";
import DisLike from "../assets/DisLike";
import ShareSvg from "../assets/ShareSvg";
import Loader from "../Loader";
import {
    countVideoReactions,
    countVideoWatches,
    getAnimeIds,
    getMyVideoReaction,
    postVideoReaction
} from "../../api/common";

export type REACTION_TYPE = 'like' | 'dislike' | 'none'

const AnimeWatchWatchInfoBlock = ({anime_id, episode, trans}: {anime_id: number, episode: number, trans: string}) => {
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [loading, setLoading] = useState(true)
    let [animeInfo, setAnime] = useState<Anime>()
    let [myReactionType, setMyReactionType] = useState<REACTION_TYPE>('none')
    let [watchCount, setWatchCount] = useState(0)
    let [likesCount, setLikesCount] = useState(0)

    async function setReaction(reaction_type: REACTION_TYPE) {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }
        setLoading(true)

        let result = await postVideoReaction(anime_id, episode, trans, reaction_type)
        setLoading(false)
        return result
    }

    useEffect(() => {
        (async () => {
            let animes = await getAnimeIds(anime_id)
            let anime: Anime | null = null;
            if (animes.length > 0) anime = animes[0];
            if (!anime) return;
            setAnime(anime)

            if (cookies.access) {

                let myReactionResp = await getMyVideoReaction(anime.id, episode);
                setMyReactionType(myReactionResp?.reaction_type ?? 'none')
            }

            let watchCountResp = await countVideoWatches(anime_id, episode)
            setWatchCount(+(watchCountResp ?? 0))

            let likesCountResp = await countVideoReactions(anime_id, episode)
            setLikesCount(+(likesCountResp ?? 0))
            setLoading(false)
        })()
    }, [anime_id, episode]);

    return (
        <div className={"anime_watch_watch_info_block"}>
            <div className="anime_watch_watch_info_block_title">{animeInfo?.titles?.russian}</div>

            <div className="player_page_info_under_title_block_info">
                <p>{declineViews(watchCount ?? 0)}</p>
                <p>{trans}, {episode} эп.</p>
            </div>

            <div style={{
                display: 'flex',
                marginTop: 10,
            }}>
                <div className="like_block">
                    <div className="like_block_el like_block_el_left" onClick={() => {
                        if (myReactionType !== 'like') {
                            setLikesCount((value) => value + 1)
                            setMyReactionType('like')
                            setReaction('like')
                                .then(r => {
                                    if (!r) {
                                        setMyReactionType('none')
                                        setLikesCount((value) => value - 1)
                                    }
                                })
                            return
                        }
                        setMyReactionType('none')
                        setReaction('none')
                        setLikesCount((value) => value - 1)
                    }}>
                        <LikeSvg isFill={myReactionType == 'like'}
                                 color={'#ddd'} size={20}/>
                        {
                            loading ? <Loader small={true}/> :
                                <span className={"like_block_count"}>{likesCount}</span>
                        }

                    </div>
                    <div className="like_block_el like_block_el_right" onClick={() => {
                        if (myReactionType === 'like') setLikesCount(value => value - 1)
                        if (myReactionType !== 'dislike') {
                            setMyReactionType('dislike')
                            setReaction('dislike')
                                .then(r => {
                                    if (!r) {
                                        setMyReactionType('none')
                                    }
                                })
                            return
                        }
                        setMyReactionType('none')
                        setReaction('none')
                    }}>
                        <DisLike isFill={myReactionType == 'dislike'}
                                 color={'#ddd'} size={20}/>
                    </div>
                </div>

                <div className="share_block" onClick={() => {
                    (async () => {
                        const shareData = {
                            title: `AniType | Смотреть ` + animeInfo?.titles.russian,
                            text: animeInfo?.description,
                            url: window.location.href ?? '',
                        };
                        try {
                            await navigator?.clipboard?.writeText(shareData.url);
                            await navigator?.share(shareData);
                        } catch (ignored) {}
                    })()
                }}>
                    <ShareSvg size={20} color={'#ddd'}/>
                    <p>Поделиться</p>
                </div>
            </div>
        </div>
    );
};

export default AnimeWatchWatchInfoBlock;