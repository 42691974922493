import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {createFolder} from "../../../api/common";

const MyPageFoldersCreate = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [title, setTitle] = useState('')

    async function create() {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        await createFolder(title)
        nav(-1)
    }

    return (
        <div style={{padding: "15px 15px 20px 15px"}}>

            <AutoFadeTopBar title={'Создание папки'} alwaysShown={true}/>


            <div style={{padding: '0 10px'}}>
                <input type="text" placeholder={"Введите название папки"} value={title} onChange={e => setTitle(e.target.value)}/>
                <div className={"select_page_content_button to"} style={{marginTop: 30, borderTop: '1px solid #333', paddingTop: 15,
                borderRadius: 0, background: 'transparent'}}
                onClick={() => create()}>
                    <div className={"select_page_content_button_g"}>
                        <p className={"select_page_content_button_title"}>Создать</p>
                    </div>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </div>
            </div>
        </div>
    );
};

export default MyPageFoldersCreate;