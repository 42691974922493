import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getKodikData} from "../../../api/kodik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";

const StreamsCreateSelectEpisodePage = () => {
    let nav = useNavigate()

    let [kodikData, setKodikData] = useState<any>([])
    useEffect(() => {
        (async () => {
            let id = +(localStorage.getItem('new_stream_anime') ?? 0)
            if (id === 0) return;

            let kodik = await getKodikData(id)
            setKodikData(kodik)
        })()
    }, [])

    return (
        <>
            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>
            <div className="select_page_content spc">
                {
                    kodikData.episodes && kodikData.episodes.length > 0 &&
                    kodikData.episodes.filter((v: any) => v.translation === localStorage.getItem('new_stream_translation')).map((e: any) => e.episode).map((el: any) =>
                        <div className={"select_page_content_button to"} key={el + 'ep'} onClick={() => {
                            localStorage.setItem('new_stream_episode', el)
                            nav(-1)
                        }}>
                            <div className={"select_page_content_button_g"}>
                                <p className={"select_page_content_button_title"}>{el} эпизод</p>
                                <p className={"select_page_content_button_desc"}>_</p>
                            </div>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </div>)
                }

            </div>
        </>
    );
};

export default StreamsCreateSelectEpisodePage;