import React from 'react';
import '../../../styles/home.css'
import HomeList from "../../home-components/HomeList";

const HomePage = () => {
    return (
        <div className={"home_page"}>
            <HomeList/>
        </div>
    );
};

export default HomePage;