import React, {useRef} from 'react';
import NavBar from "../NavBar";
import PageLayout from "./PageLayout";
import GlobalAdv from "../GlobalAdv";
import Ws from "../WS";

const PageLayoutWithBotBar = ({children, setSearchOpen, wsRef}: {
    children: any,
    setSearchOpen: any,
    wsRef: React.MutableRefObject<WebSocket | null>
}) => {

    return (
        <PageLayout>
            <Ws wsRef={wsRef}/>

            {children}


            {
                !window.location.href.includes('hub') &&
                <NavBar setSearchOpen={setSearchOpen}/>
            }


            <GlobalAdv/>
        </PageLayout>
    );
};

export default PageLayoutWithBotBar;