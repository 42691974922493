import React, {useEffect, useRef} from 'react';

const AdsInitializer = () => {

    const iterateUseEffect = useRef(0)

    useEffect(() => {
        async function start(){
            if (iterateUseEffect.current == 0) {
                    iterateUseEffect.current += 1
                    const script = document.createElement('script');
                    script.src = 'https://yandex.ru/ads/system/context.js';
                    script.async = true;
                    document.head.appendChild(script);
                }
        }
        start().catch(e => console.error(e))
    }, []);

    return (
        <></>
    );
};

export default AdsInitializer;