import React, {useEffect, useState} from 'react';
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import {
    distinct,
    engSeasonToRu,
    engStatusToRu,
    getEpisodes,
    removeSquareBrackets
} from "../../../funcs/anime";
import {getKodikData} from "../../../api/kodik";
import {useNavigate, useParams} from "react-router-dom";
import {Anime, KodikFullData} from "../../../types";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {BACKEND} from "../../../consts";
import {pluralizeEpisodes} from "../../../funcs/ru_lang";

const AnimeInfoPage = ({serialPage}: {
    serialPage: boolean
}) => {
    let nav = useNavigate()
    let params = useParams()
    let [anime, setAnime] = useState<Anime>()
    let [kodikData, setKodikData] = useState<KodikFullData>()
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setLoading(true)
            let data = await cacheableAxios({
                url: BACKEND + `anime/ids?ids=${params.id}`,
                method: 'get'
            }, 60 * 60)
                .then(r => r)
                .catch(ignored => [])

            if (data?.length && data?.length > 0) {
                setAnime(data[0])

                setLoading(false)

                let kodik = await getKodikData(data[0].id)
                setKodikData(kodik)
            }
        })()
    }, []);

    return (
        <>
            <AutoFadeTopBar title={anime?.titles?.russian ?? 'Информация'} alwaysShown={true}/>

            {
                loading ? <div className="ccp">
                    <Loader/>
                </div> : <>
                    <div className="select2_block">
                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Описание'}</p>
                            <p className="select2_block_el_desc fs14">{
                                removeSquareBrackets(anime?.description.length ? anime?.description : 'Нет описания')
                            }</p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Длительность'}</p>
                            <p className="select2_block_el_desc fs14">
                                {getEpisodes(anime)} {pluralizeEpisodes(getEpisodes(anime))} по ~{anime?.episode_duration} мин.
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Озвучки'}</p>
                            <p className="select2_block_el_desc fs14">
                                {
                                    distinct(kodikData?.episodes?.map(e => e.translation) ?? ['Нет информации']).join(', ')
                                }
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Статус'}</p>
                            <p className="select2_block_el_desc fs14">
                                {engStatusToRu(anime?.status ?? 'Нет информации')}
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Страна'}</p>
                            <p className="select2_block_el_desc fs14">
                                {anime?.countries && anime?.countries?.length > 0 ? anime?.countries?.split(',').join(', ') : 'Нет информации'}
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Студии'}</p>
                            <p className="select2_block_el_desc fs14">
                                {
                                    (anime?.studios && anime?.studios?.length > 0) ?
                                    anime?.studios.map(e => e.name).join(', ') : 'Нет информации'
                                }
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Жанры'}</p>
                            <p className="select2_block_el_desc fs14">
                                {
                                    (anime?.genres && anime?.genres?.length > 0) ?
                                        anime?.genres.map(e => e.name).join(', ') : 'Нет информации'
                                }
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Сезон'}</p>
                            <p className="select2_block_el_desc fs14">
                                {
                                    engSeasonToRu(anime?.season.split('_')[0] ?? 'Нет информации')
                                }
                            </p>
                        </div>

                        <div className="select2_block_el anime_info_el">
                            <p className="select2_block_el_title anime_info_el_title">{'Год'}</p>
                            <p className="select2_block_el_desc fs14">
                                {
                                    anime?.season.split('_') && anime?.season.split('_').length > 1 && anime?.season.split('_')[1] ?
                                        <>{anime?.season.split('_')[1]} г.</> : <>Нет информации</>
                                }
                            </p>
                        </div>



                    </div>
                </>
            }
        </>
    );
};

export default AnimeInfoPage;