import {EpisodeInfo, KodikFullData, WAElement} from "../types";
import {cacheableAxios} from "./cacheableAxios";
import {BACKEND} from "../consts";
import {distinct} from "./anime";


export async function getWAData(kodikFull?: KodikFullData): Promise<WAElement[] | undefined> {
    if (!kodikFull) return;

    let result: any;

    let waResp = await cacheableAxios({
        url: BACKEND + 'wa/gg?link=' + encodeURIComponent(kodikFull?.walink)
    }, 60 * 60)
        .then(r => r)
        .catch(ignored => ({}))

    if (waResp && waResp.length && waResp.length > 0) {
        let d = waResp.map((e: any) => {
            let l = e.split('$')
            if (l?.length && l.length === 3) {
                return {
                    ep: +l[0],
                    img: l[1],
                    name: l[2]
                }
            }
        })
        return d
    }
}

export function findMinEpisode(data: EpisodeInfo[] | undefined): number {
    let eps = distinct(data?.map(e => +(e.episode ?? -100)) ?? [])

    let min = eps[0] ?? 1;
    eps.forEach(el => {
        if (el < min) min = el;
    })
    return min;
}