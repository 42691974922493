import React, {useEffect, useState} from 'react';
import Loader from "../Loader";
import {useCookies} from "react-cookie";
import {getUserMyInfo} from "../../funcs/api/users";
import {getUserInfo} from "../../api/common";
import axios from "../../core/axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";

const QrAuthPage = () => {
    let [stage, setStage] = useState<'loading' | 'done' | 'err'>('loading')
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let params = useParams()

    useEffect(() => {
        (async () => {
            let tokens = await axios({
                method: 'post',
                url: 'users/auth/refresh',
                data: {
                    refreshToken: cookies.refresh
                }
            }).then(r => r.data).catch(e => null)

            if (!tokens) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }

            axios({
                method: 'get',
                url: 'events/auth/qr',
                data: {
                    access: tokens.accessToken,
                    refresh: tokens.refreshToken,
                    code: params?.code ?? ''
                }
            })
                .then(r => setStage('done'))
                .catch(e => setStage('err'))
        })()

    }, []);

    return (
        <>
            <div className="fwp">
                {
                    stage === 'loading' && <>
                        <Loader/>
                        <p>Подождите...</p>
                    </>
                }
                {
                    stage === 'done' && <>
                        <FontAwesomeIcon icon={faCheck}/>
                        <p>Готово!</p>
                    </>
                }
                {
                    stage === 'err' && <>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <p>Произошла ошибка :(</p>
                    </>
                }
            </div>
        </>
    );
};

export default QrAuthPage;