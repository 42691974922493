import React, {useEffect, useRef, useState} from 'react';
import '../../../styles/anime_select.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {EpisodeInfo} from "../../../types";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {check_anime_ban} from "../../../funcs/anime";
import {useCookies} from "react-cookie";
import {calcPlayerSize} from "../../../funcs/player";
import AdBlock from "../../yandex/AdBlock";
import {getStartTime} from "../../../funcs/users";
import AnimeWatchWatchInfoBlock from "../../anime-components/AnimeWatchWatchInfoBlock";
import MaterialButtonWLoading from "../../MaterialButtonWLoading";
import axios from "../../../core/axios";
import {getEpisodesInfo} from "../../../api/common";

const AnimeSelectSourcePage = ({wsRef}: {
    wsRef: React.MutableRefObject<WebSocket | null>
}) => {
    let domain = useState(window?.location?.hostname ?? 'anitype.fun')

    const [windowSize, setWindowSize] = useState({width: -1, height: -1})
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    const playerTimeRef = useRef(0)
    const durTimeRef = useRef(0)
    const lastWatchLog = useRef<any>(null)

    let nav = useNavigate()
    let params = useParams()
    let [searchParams] = useSearchParams()
    let [data, setData] = useState<EpisodeInfo[]>([])

    let [selectedPlayer, setSelectedPlayer] = useState(
        searchParams.get('player') ?? 'anitype'
    )
    let [selectedTranslation, setSelectedTranslation] = useState<string>(
        searchParams.get('translation') ?? ''
    )
    let [selectedEpisode, setSelectedEpisode] = useState<number | string>(
        searchParams.get('episode') ?? ''
    )
    let [lastSavedTime, setLastSavedTime] = useState<string>(
        (searchParams.get('last') ?? '').includes('_') ? (searchParams.get('last') ?? '') : ''
    )

    let [step, setStep] = useState((() => {
        let s = -1;
        if (localStorage.getItem('age_warning') === 'true') {
            s = 0;
        }
        return s;
    })())


    // let [fullHdTranslations, setFullHdTranslations] = useState<string[]>([])

    // TODO
    // useEffect(() => {
    //     let episode = selectedEpisode ?? searchParams.get('episode') ?? '';
    //     let player = selectedPlayer ?? searchParams.get('player') ?? 'anitype';
    //
    //     let currentLink = `/anime/${params.id}/play`
    //     nav(currentLink + `?last=${lastSavedTime}&episode=${episode}&translation=${selectedTranslation}&player=${player}`)
    //
    // }, [selectedEpisode, selectedTranslation, lastSavedTime, selectedPlayer])

    useEffect(() => {
        (async () => {
            let banned = await check_anime_ban(+(params?.id ?? 0))
            if (banned) {
                nav('/')
                return
            }

            setData(await getEpisodesInfo(+(params?.id ?? 0)))
        })()
    }, [])

    function messageListener(event: any) {
        if (event?.data?.ev) {
            if (event.data.ev === 'time') {
                if (+event.data.value > 0) {
                    playerTimeRef.current = +event.data.value
                }
            }
            if (event.data.ev === 'duration') {
                if (+event.data.value > 0) {
                    durTimeRef.current = +event.data.value
                }
            }
            if (event.data.ev === 'seek_episode') {
                setSelectedEpisode(+(event.data.value ?? 1))
            }
            if (event.data.ev === 'seek_translation') {
                setSelectedTranslation(event.data.translation)
            }
        }
    }
    useEffect(() => {
        let int = setInterval(() => {
            if (cookies.access) {
                const watchLog: any = {
                    releaseId: params.id,
                    season: -1,
                    episode: selectedEpisode.toString(),
                    translationTitle: selectedTranslation,
                    seconds: Math.round(playerTimeRef.current),
                    duration: durTimeRef.current,
                }

                if (+playerTimeRef.current > 0 && watchLog.seconds != lastWatchLog.current?.seconds) {
                    // axios({
                    //     method: 'post',
                    //     url: 'app2/watchlogs/user',
                    //     data: watchLog
                    // }).catch(ignored => {}) DEPRECATED
                    axios({
                        method: 'post',
                        url: 'episodes/saves/save',
                        data: {
                            anime_id: +(params.id ?? 0),
                            number: +(selectedEpisode ?? 0),
                            translation: selectedTranslation,
                            time: Math.round(+watchLog.seconds),
                            duration: Math.round(+watchLog.duration ?? 0)
                        }
                    }).catch(ignored => {})
                    lastWatchLog.current = watchLog
                }

                try {
                    let wsMsg = {
                        "key": "send_watch_data",
                        "value": {
                            "anime_id": +(params.id ?? 0),
                            "episode": +(selectedEpisode ?? 0),
                            "translation": selectedTranslation,
                            "time": Math.round(watchLog.seconds),
                            "agent": `m.anitype|${navigator.platform}|${navigator.userAgent}`
                        }
                    }

                    if (wsRef.current && wsRef.current?.readyState === 1 && cookies.access) {
                        wsRef.current?.send(JSON.stringify(wsMsg))
                    }
                } catch (ignored) {}

            }
        }, 10100)

        if (window.addEventListener) {
            window.addEventListener('message', messageListener);
        } else {
            // @ts-ignore
            window?.attachEvent('onmessage', messageListener);
        }

        if (window) {
            window.onresize = () => {
                setWindowSize({
                    width: +window.innerWidth,
                    height: +window.innerHeight
                })
            }
            setWindowSize({
                width: +window.innerWidth,
                height: +window.innerHeight
            })
        }

        return () => {
            clearInterval(int)

            if (window.removeEventListener) {
                window.removeEventListener('message', messageListener);
            } else {
                // @ts-ignore
                window?.detachEvent('onmessage', messageListener);
            }


        };
    }, [selectedEpisode, selectedTranslation])

    return (
        <>

            <div className="select_page_header" style={{maxHeight: 60}}>
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(`/anime/${params.id}`)
                }}/>

                <>
                    {step === -1 && <p>Подтверждение возраста</p>}
                    {step === 0 && <p>{selectedTranslation}, {selectedEpisode} эпизод</p>}
                    <p></p>
                </>

            </div>
            <div style={{height: 55}}></div>


            {
                step === -1 &&
                <div className="select_page_content spc">
                    <p className={"select_page_content_warning"}>Некоторый контент на этой странице разрешен только
                        совершеннолетним
                        пользователям. Вам есть 18 лет?</p>

                    <div className="select_page_content_button to" onClick={() => {
                        setStep(step + 1)
                        localStorage.setItem('age_warning', 'true')
                    }}>
                        <div className={"select_page_content_button_g"}>
                            <p className={"select_page_content_button_title"}>Да</p>
                            <p className={"select_page_content_button_desc"}>_</p>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </div>
                    <div className="select_page_content_button to" onClick={() => nav(-1)}>
                        <div className={"select_page_content_button_g"}>
                            <p className={"select_page_content_button_title"}>Нет</p>
                            <p className={"select_page_content_button_desc"}>_</p>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </div>
                </div>
            }

            {
                step === 0 && !(window?.location?.hostname?.includes('.ru')) &&
                <div className="select_page_content spc" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {selectedPlayer === 'kodik' &&
                        <iframe
                            style={{
                                width: calcPlayerSize(windowSize).width + 'px',
                                height: calcPlayerSize(windowSize).height + 'px'
                            }}
                            src={`https://player2.anitype.fun/?player=${'kodik'}&translation=${encodeURIComponent(selectedTranslation)}&id=${params.id}&episode=${selectedEpisode}&startFrom=${
                                getStartTime(searchParams.get('last') ?? '', +(selectedEpisode ?? 0))
                            }`}
                            allowFullScreen allow="autoplay *; fullscreen *"
                            className="select_page_content_iframe"></iframe>}

                    {selectedPlayer === 'anitype' && (selectedEpisode && selectedPlayer && selectedTranslation && data) &&
                        <iframe
                            style={{
                                width: calcPlayerSize(windowSize).width + 'px',
                                height: calcPlayerSize(windowSize).height + 'px'
                            }}
                            src={`https://player2.anitype.fun/?player=${'anitype'}&translation=${encodeURIComponent(selectedTranslation)}&id=${params.id}&episode=${selectedEpisode}&startFrom=${
                                getStartTime(searchParams.get('last') ?? '', +(selectedEpisode ?? 0))
                            }`}
                            allowFullScreen allow="autoplay *; fullscreen *"
                            className="select_page_content_iframe"></iframe>}

                    {/*under player*/}

                    <AnimeWatchWatchInfoBlock anime_id={+(params?.id ?? 0)} episode={+(selectedEpisode ?? 0)}
                                              trans={selectedTranslation}/>

                    <div className="anime_watch_watch_other_block">
                        <MaterialButtonWLoading title={'Назад'} delay={600} onClick={() => {
                            nav(-1)
                        }}/>
                        <MaterialButtonWLoading title={selectedTranslation} delay={600} onClick={() => {
                            nav(`/anime/${params?.id}/translation`)
                        }}/>

                        {
                            +(selectedEpisode ?? 0) != Math.max(...data?.filter(v => v?.translation?.title === selectedTranslation)?.map(e => (e?.episode ?? 0)) as number[]) &&
                            <MaterialButtonWLoading title={'Следующий эпизод'} delay={600} onClick={() => {
                                setSelectedEpisode(+(selectedEpisode ?? 0) + 1)
                            }}/>
                        }
                        {
                            +(selectedEpisode ?? 0) != Math.min(...data?.filter(v => v?.translation?.title === selectedTranslation)?.map(e => (e?.episode ?? 0)) as number[]) &&
                            <MaterialButtonWLoading title={'Предыдущий эпизод'} delay={600} onClick={() => {
                                setSelectedEpisode(+(selectedEpisode ?? 0) - 1)
                            }}/>
                        }

                        <MaterialButtonWLoading title={
                            selectedPlayer === 'anitype' ? 'Использовать Kodik' : 'Использовать AniType'
                        } delay={600} onClick={() => {
                            if (selectedPlayer === 'anitype') {
                                setSelectedPlayer('kodik')
                            } else setSelectedPlayer('anitype')
                        }}/>
                    </div>

                    <div style={{height: 20}}></div>

                    <AdBlock className={'select_page_free'} renderTo={"yandex_rtb_R-A-2987609-5"}
                             blockId={"R-A-2987609-5"}/>


                    <p style={{
                        marginTop: 30,
                        color: '#676767',
                        fontSize: 13,
                        fontWeight: 500
                    }}>
                        Плеер AniType находиться в раннем доступе. Если у вас возникают проблемы с просмотром, смените
                        плеер на Kodik
                    </p>

                    <p style={{
                        marginTop: 20,
                        color: '#676767',
                        fontSize: 13,
                        fontWeight: 500,
                        width: '100%'
                    }}>
                        Внимание! 4К качество доступно только в плеере AniType
                    </p>

                    <p style={{
                        marginTop: 20,
                        color: '#676767',
                        fontSize: 13,
                        fontWeight: 500,
                        width: '100%'
                    }}>
                        Сейчас используется плеер {selectedPlayer === 'anitype' ? 'AniType' : 'Kodik'}
                    </p>

                    <p style={{
                        marginTop: 20,
                        color: '#676767',
                        fontSize: 13,
                        fontWeight: 500,
                        width: '100%'
                    }}>
                        Для входа в полный экран поверните устройство.
                    </p>
                </div>
            }
        </>
    );
};

export default AnimeSelectSourcePage;