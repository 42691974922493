import React from 'react';
import '../../../styles/streams_list.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {faTv} from "@fortawesome/free-solid-svg-icons/faTv";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import {useNavigate} from "react-router-dom";

const StreamsListPage = () => {
    let nav = useNavigate()

    return (
        <div className={"streams_list_page"}>
            <div className="streams_list_page_header">
                <div>
                    <p className={"streams_list_page_title"}>
                        <FontAwesomeIcon icon={faTv} className={"streams_list_page_title_icon"} />
                        Стримы</p>
                    <p className={"streams_list_page_desc"}>Смотрите аниме в большой компании!</p>
                </div>

                <FontAwesomeIcon icon={faCircleInfo} style={{color: '#ddd'}} />
            </div>

            <div className="select_page_content_button to" onClick={() => nav('/streams/create')}>
                <div className={"select_page_content_button_g"}>
                    <p className={"select_page_content_button_title"}>Начать стрим</p>
                    <p className={"select_page_content_button_desc"}>Смотрите аниме вместе с другими пользователями!</p>
                </div>
                <FontAwesomeIcon icon={faAngleRight}/>
            </div>

        </div>
    );
};

export default StreamsListPage;