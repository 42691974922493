import React, {useEffect, useState} from 'react';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

interface Props {
    title: string,
    children?: React.ReactNode,

    alwaysShown?: boolean
}

const AutoFadeTopBar = (props: Props) => {
    let nav = useNavigate()

    let [opacity, setOpacity] = useState(props.alwaysShown ? 1 : 0)

    function handleScroll() {
        if (!props.alwaysShown) {
            const currentScrollPosition = window.scrollY;

            let perc = (+currentScrollPosition - 100) / 150 * 100
            if (perc > 100) perc = 100;
            if (perc < 0) perc = 0;

            setOpacity(perc / 100)
        } else {
            setOpacity(1)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div style={{height: 60}}></div>

            <div className="auto_fade_top_bar">
                <div className="auto_fade_top_bar_back auto_fade_top_bar_part" onClick={() => nav(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="auto_fade_top_bar_title auto_fade_top_bar_part" style={{opacity: opacity}}>
                    {props.title}
                </div>
                <div className="auto_fade_top_bar_buttons auto_fade_top_bar_part" style={{position: "relative"}}>
                    {props.children}
                </div>
            </div>
            <div className="auto_fade_top_bar_bg" style={{opacity: opacity}}></div>
        </>
    );
};

export default AutoFadeTopBar;