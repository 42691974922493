import {BACKEND} from "../consts";
import {Comment, UserInfo} from "../types";
import axios from "../core/axios";

export async function sendCommentAsync(
    text: string,
    target: string,
    tags: string,
    parentId: string,
): Promise<Comment | string> {
    return await axios({
        method: 'post',
        url: 'comments/',
        data: {
            text: text,
            target: target,
            tags: tags,
            parentId: parentId
        }
    })
        .then(newComment => {
            let cacheUrl = `comments/target/${target}?page=${0}&size=10`
            localStorage.removeItem(encodeURIComponent(`${cacheUrl}_exp`))
            localStorage.removeItem(encodeURIComponent(`${cacheUrl}_cache`))
            return newComment.data
        })
        .catch(e => {
            let er = e.response.data
            if (typeof er === 'string') return er;
            if (er?.length && er.length > 0) return er[0];
            if (er?.message && typeof er.message === 'string') return er.message;
            if (er?.message) return er.message[0];
            return JSON.stringify(er);
        })
}

export function getDeleteCommentAbility(user?: UserInfo, comment?: Comment): boolean {
    let owner = comment?.user_id === user?.id;
    let profileOwner = comment?.target?.split('user')?.pop() === user?.id;
    return owner || profileOwner;
}