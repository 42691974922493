import React, {useEffect, useRef} from 'react';
import {useCookies} from "react-cookie";
import {sendVisit, sendVisitUser} from "../api/common";

const Analytics = () => {
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    const iterateUseEffect = useRef(0)

    useEffect(() => {
        if (iterateUseEffect.current == 0) {
            iterateUseEffect.current += 1
            const script = document.createElement('script');
            script.innerHTML = "(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
                "   m[i].l=1*new Date();\n" +
                "   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +
                "   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
                "   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +
                "\n" +
                "   ym(93935435, \"init\", {\n" +
                "        clickmap:true,\n" +
                "        trackLinks:true,\n" +
                "        accurateTrackBounce:true,\n" +
                "        webvisor:true,\n" +
                "        trackHash:true\n" +
                "   });"
            script.async = true;
            document.head.appendChild(script);

            const script2 = document.createElement('script');
            script2.innerHTML = "window.dataLayer = window.dataLayer || [];\n" +
                "  function gtag(){dataLayer.push(arguments);}\n" +
                "  gtag('js', new Date());\n" +
                "\n" +
                "  gtag('config', 'G-5NE35TPDKG');"
            script2.async = true;
            document.head.appendChild(script2);
        }
    }, []);

    async function asyncc() {
        console.log(
            "%c💩💩💩💩💩💩💩💩",
            "font-size: 128px"
        )
        console.log("[ https://t.me/anitypenews ] [ https://discord.gg/anitype ]")

        // if (cookies.access) {
        //     sendVisitUser()
        // } else {
        //     sendVisit()
        // }
    }

    useEffect(() => {
        console.log(
            "%c Уважаемый гражданин, просим покинуть эту охраняемую территорию",
            "color: blue; font-size: 48px;",
            "\nче ты тут делаешь, программист что-ли?",
        );
        console.log("[ https://t.me/anitypenews ] [ https://discord.gg/anitype ]")

        let i = setInterval(() => {
            asyncc()
        }, 30000)

        return () => {
            clearInterval(i)
        }
    }, [])

    return (
        <>
        </>
    );
};

export default Analytics;