import React, {useEffect, useRef} from 'react';

declare global {
    interface Window {
        yaContextCb?: (() => void)[];
    }
}

interface AdsBlockProps{
    renderTo: string
    blockId: string
    classes?: string
    adProps?: any
}

const AdsBlock = ({ renderTo, blockId, classes = "defMargin", adProps }: AdsBlockProps) => {

    const iterateUseEffect = useRef(0)

    useEffect(() => {
        async function start(){
            if (iterateUseEffect.current == 0) {
                iterateUseEffect.current += 1
                let i = setInterval(() => {
                    window.yaContextCb = window?.yaContextCb ?? [];
                    window.yaContextCb.push(() => {
                        //@ts-ignore
                        Ya.Context.AdvManager.render({
                            blockId: blockId,
                            renderTo: renderTo,
                            ...adProps
                        });
                    });
                    clearInterval(i)

                }, 1000)
            }
        }
        start().catch(e => console.error(e))
    }, [blockId, renderTo]);
    
    return (
        <div id={renderTo} className={classes}></div>
    );
};

export default AdsBlock;