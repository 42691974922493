import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import M3OutlinedButton from "./m3/M3OutlinedButton";

interface Props {
    title: string,
    active: boolean,
    setActive: (value: boolean) => void,
    styles?: any
}

const TogglerButton = (props: Props) => {
    return (
        <>
            <M3OutlinedButton onClick={() => props.setActive(!props.active)} innerClassName={"toggler_button_m3"}>
                <p>{props.title}</p>
                {
                    props.active ?
                        <FontAwesomeIcon icon={faCheck} /> :
                        <FontAwesomeIcon icon={faXmark} />
                }
            </M3OutlinedButton>
            {/*<div className={props.active ? "toggler_button active" : "toggler_button"} onClick={() => props.setActive(!props.active)} style={{*/}
            {/*    ...props.styles*/}
            {/*}}>*/}
            {/*    */}
            {/*</div>*/}
        </>
    );
};

export default TogglerButton;