import React, {useEffect, useRef, useState} from 'react';
import AnimeElementIdsBlock from "../../AnimeElementIdsBlock";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {BACKEND} from "../../../consts";
import {useParams} from "react-router-dom";
import {Anime} from "../../../types";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import Loader from "../../Loader";
import AnimeElement from "../../AnimeElement";
import {useDispatch, useSelector} from "react-redux";
import {dataSlice, selectAnimePageableContent, selectAnimePageablePage} from "../../../lib/redux";

const HomeFullListPage = () => {
    let params =  useParams()

    let forLoadDiv = useRef<HTMLDivElement>(null)
    let [isLoading, setIsLoading] = useState(false)

    // let [animes, setAnimes] = useState<Anime[]>([])
    // let [page, setPage] = useState(0)

    let animes = useSelector(selectAnimePageableContent(params?.type ?? 'none'))
    let page = useSelector(selectAnimePageablePage(params?.type ?? 'none'))
    let dispatch = useDispatch()

    let [viewMode, setViewMode] = useState<'cards' | 'column'>(
        localStorage.getItem('list_view_mode') === 'cards' ? 'cards' : 'column'
    )


    // useEffect(() => {
    //     fetchData()
    // }, [params.type])

    const fetchData = async () => {
        // if (animes.length > 0 && params.type === 'uhd') return;
        const url = `${SYMS.find(v => v.short === params.type)?.data_url}&size=${10}&page=${page}`

        setIsLoading(true)

        let animeList = await cacheableAxios({
            method: 'get',
            url: url
        }, 60 * 60)
            .then(r => r)
            .catch(ignored => ([]))

        if (!animeList?.length) {
            setIsLoading(false)
            return
        }

        dispatch(dataSlice.actions.addAnimePageableContent({
            tag: params?.type ?? 'none',
            page: (page ?? 0) + 1,
            content: [...animes, ...animeList]
        }))
        setIsLoading(false)
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
            fetchData()
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '500px', // Область видимости - верх страницы
            threshold: 0.1,    // Порог видимости
        });

        if (forLoadDiv.current) {
            observer.observe(forLoadDiv.current);
        }

        return () => {

            if (forLoadDiv.current && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <>
            <AutoFadeTopBar title={SYMS.find(v => v.short === params.type)?.title ?? ''} alwaysShown={true}/>

            <div className="full_list_page_list" style={{padding: '0 15px'}}>
                {
                    (isLoading && animes?.length === 0) ?
                        <div className={"ccp"}>
                            <Loader/>
                        </div> :
                        <div className={viewMode === 'cards' ? "animes_list_block cards_mode" : "animes_list_block"}>

                            {
                                animes.map(el => <AnimeElement viewMode={viewMode} anime={el} key={el.id}/>)
                            }

                            <div ref={forLoadDiv}></div>
                        </div>
                }
            </div>
        </>
    );
};

export default HomeFullListPage;

export const SYMS = [
    {
        short: 'ongoings',
        title: 'Онгоинги',
        data_url: BACKEND + `anime/filter?score_start=7&status=ongoing&kind=tv`
    },
    {
        short: 'anonses',
        title: 'Анонсы',
        data_url: BACKEND + `anime/filter?status=anons&kind=tv`
    },
    {
        short: 'best',
        title: 'Лучшее',
        data_url: BACKEND + `anime/filter?score_start=9`
    },
    {
        short: 'uhd',
        title: 'Доступно в 4К',
        data_url: BACKEND + `anime/filter?uhd=true`
    },
    {
        short: 'movies',
        title: 'Фильмы',
        data_url: BACKEND + `anime/filter?kind=movie&score_start=7`
    },
    {
        short: 'ova',
        title: 'Популярные OVA',
        data_url: BACKEND + `anime/filter?kind=ova&score_start=7`
    },
]