import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate} from "react-router-dom";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";

const BackQuadButton = (params: any) => {
    let nav = useNavigate()

    return (
        <div className="anime_top_bar_back to" onClick={() => {
            if (params?.prevent) {
                params?.fun()
            } else {
                nav(-1)
            }
        }}>
            <FontAwesomeIcon icon={faChevronLeft} className={"anime_top_bar_back_icon"}/>
        </div>
    );
};

export default BackQuadButton;