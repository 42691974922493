import React from 'react';
import '../styles/error.css'

const Error = ({message, setMessage}: {message: string, setMessage: any}) => {
    return (
        <>
            {
                message.length > 0 &&
                <div className="error_cont">
                    <div className={"error"} onClick={() => setMessage('')}>
                        {message}
                    </div>
                </div>
            }
        </>
    );
};

export default Error;