import React from 'react';

const Okko = ({size}: {size: number}) => {
    return (
        <>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width={size} height={size} viewBox="0 0 64.000000 64.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                   fill="#151515" stroke="none">
                    <path d="M142 608 c-15 -15 -15 -61 0 -76 31 -31 88 -4 88 42 0 37 -61 61 -88
34z"/>
                    <path d="M288 609 c-21 -12 -24 -59 -6 -77 15 -15 61 -15 76 0 19 19 15 66 -7
78 -24 12 -41 12 -63 -1z"/>
                    <path d="M426 604 c-19 -18 -20 -38 -6 -65 12 -22 59 -26 78 -7 15 15 15 61 0
76 -17 17 -54 15 -72 -4z"/>
                    <path d="M225 456 c-60 -28 -87 -56 -114 -116 -37 -82 -23 -175 37 -242 48
-53 102 -78 172 -78 170 0 279 172 206 325 -28 60 -56 87 -116 114 -60 27
-123 26 -185 -3z m181 -120 c30 -30 34 -40 34 -84 0 -40 -6 -57 -26 -81 -49
-59 -139 -59 -188 0 -20 24 -26 41 -26 81 0 44 4 54 34 84 30 30 40 34 86 34
46 0 56 -4 86 -34z"/>
                </g>
            </svg>

        </>
    );
};

export default Okko;