import {Anime, KodikFullData} from "../types";
import {cacheableAxios} from "./cacheableAxios";
import {KODIK_API_URL, KODIK_PUBLIC_TOKEN} from "../consts";
import axios from "../core/axios";
import {oneFToTwo} from "./time";

export function getEpisodes(anime: Anime | undefined): number {
    if (!anime) return 0;
    if (anime?.episodes_aired > 0) return anime?.episodes_aired;
    return anime?.episodes;
}

export function endKindToRu(k: string) {
    if (k === 'movie') return 'Фильм'
    if (k === 'ona') return 'ONA'
    if (k === 'ova') return 'OVA'
    return 'Сериал'
}

export function endKindToRu2(k: string) {
    if (k === 'movie') return 'ФИЛЬМЕ'
    if (k === 'ona') return 'ONA'
    if (k === 'ova') return 'OVA'
    return 'СЕРИАЛЕ'
}

export function engSeasonToRu(i: string): string {
    if (i === 'winter') {
        return 'Зима'
    }
    if (i === 'summer') {
        return 'Лето'
    }
    if (i === 'autumn') {
        return 'Осень'
    }
    if (i === 'spring') {
        return 'Весна'
    }
    if (i === 'fall') {
        return 'Неизвестно'
    }
    return 'Нет информации'
}

export function engStatusToRu(i: string): string {
    if (i === 'anons') {
        return 'Анонс'
    }
    if (i === 'released') {
        return 'Вышел'
    }
    if (i === 'ongoing') {
        return 'Выходит'
    }
    return 'Неизвестно'
}

export function removeSquareBrackets(input: string | undefined): string {

    if (!input){
        return ""
    }

    // Используем регулярное выражение для поиска текста в квадратных скобках и двойных квадратных скобках
    const regex = /\[\[([^\]]*)\]\]|\[([^\]]*)\]|\[|\]/g;

    // Заменяем найденные совпадения
    return input.replace(regex, (match, doubleBrackets, singleBrackets) => {
        if (doubleBrackets !== undefined) {
            // Если найдены двойные квадратные скобки, возвращаем текст в них без скобок
            return doubleBrackets;
        } else if (singleBrackets !== undefined) {
            // Если найдены обычные квадратные скобки, возвращаем текст в них без скобок
            return '';
        }
        return ''; // В противном случае возвращаем пустую строку
    });
}

export function removeHtmlAndSpecialChars(input: string): string {
    // Удаляем HTML теги
    const withoutTags = input.replace(/<[^>]*>/g, '');

    // Удаляем символы между & и ;, включая их
    return withoutTags.replace(/&[^;]+;/g, ' ');
}

export function distinct(arr: any[]) {
    return arr.filter((item, index) => {
        return arr.indexOf(item) === index
    });
}

export async function fetchLibriaObj(id: any, title: string, ep: number) {
    let libriaObject: any = null;
    try {
        let l = `https://anitype.site/anime/anilibria/ep?ep=${ep}&kodik_id=${id}&title=${encodeURIComponent(title)}`
        let libriaResp = await fetch(l)
        let libriaObj = await libriaResp.json()
        if (+libriaObj.id > 0) {
            libriaObject = libriaObj.player.list[0]
        }
    } catch (ignored) {
        console.error('i', ignored)
    }

    return libriaObject;
}

export function checkAnimeBan(anime: Anime | undefined) {
    if (!anime || !anime.banned || anime.banned.length <= 0) return null;

    if (anime?.external_links?.length > 0) {
        return {
            reason: 'precp',
            by: anime?.external_links?.[0]?.url,
            original: anime?.external_links?.[0]?.url,
        }
    }

    let s = anime.banned.split('_')
    return {
        reason: s[0],
        by: s[1],
        original: s[2]
    }
}

export function getRateColor(rate: number): string {
    if (!rate) return '#eee';

    if (rate >= 7) {
        return '#5fbd06'
    }
    if (rate >= 4) {
        return '#9b9b9b'
    }
    return '#f52f2f'
}

export function getRateColorText(rate: number): string {
    if (!rate) return '#eee';

    if (rate >= 7) {
        return '#6dd907'
    }
    if (rate >= 4) {
        return '#9b9b9b'
    }
    return '#f52f2f'
}

export async function check_anime_ban(anime_id: number): Promise<boolean> {
    let anime: Anime | null = await axios({
        url: `anime/ids?ids=${anime_id}`
    })
        .then(r => {
            if (r.data.length > 0) return r.data[0];
            return null;
        })
        .catch(() => null)

    if (!anime) return false;
    return anime.banned.length > 0;
}

export function getAnimeAiredOnString(anime: Anime | undefined) {
    if (!anime || !anime.aired_on || !anime.aired_on.length) return null;
    let d = new Date(anime.aired_on);

    return `${oneFToTwo(d.getDate())}.${oneFToTwo(d.getMonth()+1)}.${d.getFullYear()}`
}

export function getSeasonName(anime: Anime, addSeasonWord: boolean) {
    if (anime.kind === 'tv') {
        return (addSeasonWord ? (`${anime.season_number} сезон`) : (anime.season_number))
    }
    if (anime.kind === 'movie') return 'Фильм'
    if (anime.kind === 'ova') return 'OVA'
    if (anime.kind === 'ona') return 'ONA'
    return (addSeasonWord ? (`${anime.season_number} сезон`) : (anime.season_number))
}

export function getSeasonsCountText(serials: Anime[]) {
    const seasonsCount: number = serials.length;
    let seasonText: string = "";

    if (seasonsCount === 1) {
        seasonText = "1 сезон";
    } else if (seasonsCount >= 2 && seasonsCount <= 4) {
        seasonText = `${seasonsCount} сезона`;
    } else {
        seasonText = `${seasonsCount} сезонов`;
    }

    return seasonText;
}