import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons/faCircleUser";
import {useCookies} from "react-cookie";
import {faMedal} from "@fortawesome/free-solid-svg-icons/faMedal";
import {faGear} from "@fortawesome/free-solid-svg-icons/faGear";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
import {getUserMyInfo} from "../../../funcs/api/users";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {faCalendarWeek, faHashtag} from "@fortawesome/free-solid-svg-icons";

const UserMenuPage = () => {
    let counter = useRef(0)

    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    useEffect(() => {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }
    }, [])

    let goToMyProfile = async () => {
        let info = await getUserMyInfo();

        localStorage.setItem('username', info?.username ?? '')
        nav(`/profile/${info?.username}`)
    }

    return (
        <>

            <AutoFadeTopBar title={'Аккаунт'} alwaysShown={true}/>


            <div className="select_page_content spc">
                <div className="select_page_content_item_w_icon to" onClick={() => goToMyProfile()}>
                    <FontAwesomeIcon icon={faCircleUser}/>
                    <p>Профиль</p>
                </div>

                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/level')}>
                    <FontAwesomeIcon icon={faArrowTrendUp}/>
                    <p>Уровень</p>
                </div>


                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/premium')}>
                    <FontAwesomeIcon icon={faMedal} style={{color: '#ff4a6a'}}/>
                    <p style={{color: '#ff4a6a'}}>AniType Premium</p>
                </div>

                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/activate')}>
                    <FontAwesomeIcon icon={faHashtag}/>
                    <p>Активировать код</p>
                </div>

                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/settings')}>
                    <FontAwesomeIcon icon={faGear}/>
                    <p>Настройки</p>
                </div>

                <div className="select_page_content_item_w_icon to" onClick={() => nav('/menu/beta')}>
                    <FontAwesomeIcon icon={faCalendarWeek} />
                    <p>Бета-тест</p>
                </div>

                <div className="select_page_content_item_w_icon to disabledblur" onClick={() => goToMyProfile()}>
                    <FontAwesomeIcon icon={faCircleQuestion}/>
                    <p>FAQ</p>
                </div>

                <div className="select_page_content_item_w_icon to" onClick={() => {
                    removeCookie('access', {
                        domain: '.anitype.fun'
                    })
                    removeCookie('refresh', {
                        domain: '.anitype.fun'
                    })
                    removeCookie('refreshTime', {
                        domain: '.anitype.fun'
                    })
                    removeCookie('access', {
                        domain: '.anitype.ru'
                    })
                    removeCookie('refresh', {
                        domain: '.anitype.ru'
                    })
                    removeCookie('refreshTime', {
                        domain: '.anitype.ru',
                    })

                    localStorage.clear()
                    nav('/')
                }}
                     style={{marginTop: 40}}>
                    <FontAwesomeIcon icon={faRightFromBracket} style={{color: 'crimson'}}/>

                    <p style={{color: 'crimson'}}>Выйти из аккаунта</p>
                </div>


                <div style={{
                    width: 100,
                    height: 50,
                    background: '#0A0A0A',
                    position: 'absolute',
                    bottom: 100,
                    right: 10
                }} onClick={() => {
                    counter.current += 1
                    if (counter.current == 10) {
                        nav('/hub?id=zxc&anime_id=11757&episode=2&translation=AniLibria.TV')
                    }
                }}></div>
            </div>
        </>
    );
};

export default UserMenuPage;