import React, {useEffect, useState} from 'react';
import SearchInput from "./ui/SearchInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import AniTypeSvg from "./assets/AniTypeSvg";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons/faCircleUser";
import {useNavigate} from "react-router-dom";
import {faBell} from "@fortawesome/free-solid-svg-icons/faBell";
import {UserInfo} from "../types";
import {getAvatar, getLevelColor} from "./UserAvatar";
import {getUserMyInfo} from "../funcs/api/users";
import {getNotifications} from "../api/common";
import {faArrowLeftLong, faBars} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";

const TopBarSearch = ({searchText, setSearchText, searchOpen, setSearchOpen, searchRef}: any) => {
    let nav = useNavigate()
    let [userInfo, setUserInfo] = useState<UserInfo>()
    let [notsCount, setNotsCount] = useState(0)

    useEffect(() => {
        fetchUserData()
    }, [])

    async function fetchUserData() {
        let info = await getUserMyInfo();
        if (!info) return;
        setUserInfo(info)
        localStorage.setItem('username', info.username)

        let nots = await getNotifications(0, 10)

        if (nots?.content) {
            setNotsCount(nots.content.filter((v: any) => !v.read).length)
        }
    }


    return (
        <>
            {/*<div style={{height: 60}}></div>*/}
            <div className="top_bar_search_fly" style={{
                padding: searchOpen ? '0 0' : '0 25px'
            }}>
                <div className="top_bar_search_fly_input" style={{
                    height: searchOpen ? 80 : 50,
                    background: searchOpen ? '#080808' : '#111',
                    boxShadow: searchOpen ? 'none' : '0 0 5px 5px rgba(0, 0, 0, 0.1)'
                }}>
                    {
                        searchOpen ?
                            <FontAwesomeIcon icon={faArrowLeftLong}
                                             className={"top_bar_search_fly_input_icon top_bar_search_fly_input_search_icon"}
                                             onClick={() => setSearchOpen(false)} style={{padding: 5, zIndex: 1}}/>
                            : <FontAwesomeIcon icon={faMagnifyingGlass}
                                               className={"top_bar_search_fly_input_icon top_bar_search_fly_input_search_icon"}
                                               onClick={() => setSearchOpen(true)}/>
                    }

                    <div className="top_bar_search_fly_input_right_icons">
                        <div className="top_bar_search_fly_input_icon" onClick={() => nav('/notifications')}>
                            <FontAwesomeIcon icon={faBell}
                                             className={"top_bar_search_fly_input_icon top_bar_search_fly_input_bell_icon"}/>
                            {
                                notsCount > 0 &&
                                <div className="notifications_unread_count">{notsCount}</div>
                            }
                        </div>

                        {
                            userInfo ?
                                <div className="top_bar_search_fly_input_icon top_bar_search_fly_input_avatar"
                                     onClick={() => nav('/menu')} style={{
                                    backgroundImage: `url('${getAvatar(userInfo, 0.5)}')`
                                }}></div> :
                                <div className="top_bar_search_fly_input_icon" onClick={() => {
                                    if (window.location.href.includes('.fun')) {
                                        window.location.href = `https://anitype.fun/auth`
                                    } else window.location.href = `https://anitype.ru/auth`
                                }}>
                                    <div style={{width: 20, height: 20, background: '#222', borderRadius: '50%'}}></div>
                                </div>
                        }
                    </div>

                    <input type="text" className="top_bar_search_fly_input_right_icons_input"
                           placeholder={'Начните писать...'}
                           onFocus={() => setSearchOpen(true)}
                        // onBlur={() => setSearchOpen(false)}
                           value={searchText} onChange={e => setSearchText(e.target.value)} ref={searchRef}
                    />

                </div>
            </div>
        </>
    );
};

export default TopBarSearch;