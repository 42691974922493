import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight, faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {selectHubInfo, selectPlayer, selectWatchData, w2Slice, WatchTogetherHubWatchDto} from "../../lib/redux";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../core/axios";
import {distinct} from "../../funcs/anime";

interface Props {
    watchData: React.MutableRefObject<WatchTogetherHubWatchDto>,
    username: string
}

const W2ManageModule = ({watchData, username}: Props) => {
    let watchDataState = useSelector(selectWatchData)
    let dispatch = useDispatch()

    let hubInfo = useSelector(selectHubInfo)
    let player = useSelector(selectPlayer)

    let [episodesData, setEpisodesData] = useState<any[]>([])
    let [episodes, setEpisodes] = useState<any[]>([])
    let [translations, setTranslations] = useState<string[]>([])

    let [changeTransOpen, setChangeTransOpen] = useState(false)

    useEffect(() => {
        axios({
            url: `videos/episodes/${watchDataState.anime_id}`
        }).then(r => {
            setEpisodesData(r.data)
            setEpisodes(r.data.filter((v: any) => v.translation.title === watchDataState.translation).sort((a: any, b: any) => {
                let c = a.episode;
                let d = b.episode;
                if (c > d) return 1;
                return -1;
            }))
            setTranslations(r.data.map((v: any) => v.translation.title))
        }).catch(ignored => ignored)
    }, [watchDataState.anime_id]);

    function getNextEpisode() {
        let ind = episodes.map(e => e.episode).indexOf(watchDataState.episode)
        let ep = episodes?.[ind+1];
        if (ep) {
        } else {
            ep = [...episodes].shift()
        }

        watchData.current = {
            ...watchData.current,
            episode: ep.episode
        }
        dispatch(w2Slice.actions.setWatchDataEpisode(ep.episode))
    }
    function getPrevEpisode() {
        let ind = episodes?.map(e => e.episode)?.indexOf(watchDataState?.episode)
        let ep = episodes?.[ind-1];
        if (ep) {
        } else {
            ep = [...episodes].pop()
        }

        if (ep?.episode) {
            watchData.current = {
                ...watchData.current,
                episode: ep.episode
            }
            dispatch(w2Slice.actions.setWatchDataEpisode(ep.episode))
        }
    }

    function swapPlayer() {
        dispatch(w2Slice.actions.changePlayer(player === 'anitype' ? 'kodik' : 'anitype'))
    }

    function changeTranslation(trans: string) {
        watchData.current = {
            ...watchData.current,
            translation: trans
        }
        dispatch(w2Slice.actions.setWatchDataTranslation(trans))
    }

    return (
        <div className="w2_manage_module ws_right_module">
            <div className="w2_manage_module_set">
                <p className={"w2_manage_module_set_title"}>ЭПИЗОД
                    {
                        username !== hubInfo.owner &&
                        <span>НЕДОСТУПНО УЧАСТНИКАМ КОМНАТЫ</span>
                    }
                </p>

                <div className="w2_manage_module_set_changer">
                    <input type="number" value={watchDataState.episode}/>
                    <p className="w2_manage_module_set_changer_button" onClick={() => getPrevEpisode()}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </p>
                    <p className="w2_manage_module_set_changer_button" onClick={() => getNextEpisode()}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </p>
                </div>
            </div>

            <div className="w2_manage_module_set">
                <p className={"w2_manage_module_set_title"}>ОЗВУЧКА</p>
                <div className="w2_manage_module_set_changer">
                    <input type="text" value={watchDataState.translation}/>

                    <p className="w2_manage_module_set_changer_button" onClick={() => setChangeTransOpen(v => !v)}>
                        <FontAwesomeIcon icon={faEllipsis}/>
                    </p>

                </div>

                { changeTransOpen &&
                    distinct(translations).map(trans =>
                        <p className="w2_manage_module_set_changer_var" onClick={() => {
                            changeTranslation(trans)
                            setChangeTransOpen(false)
                        }}>{trans}</p>)
                }
            </div>

            <div className="w2_manage_module_set">
                <p className={"w2_manage_module_set_title"}>ПЛЕЕР</p>
                <div className="w2_manage_module_set_changer">
                    <input type="text" value={player === 'anitype' ? 'AniType' : 'Kodik'}/>
                    <p className="w2_manage_module_set_changer_button" onClick={() => swapPlayer()}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </p>
                    <p className="w2_manage_module_set_changer_button" onClick={() => swapPlayer()}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default W2ManageModule;