import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {BACKEND} from "../../../consts";
import Error from "../../Error";
import {getKodikData} from "../../../api/kodik";
import {KodikFullData} from "../../../types";
import {useCookies} from "react-cookie";
import {createStream} from "../../../funcs/streams";
import axios from "axios";

const StreamsWatchPage = () => {
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let nav = useNavigate()
    let params = useParams()
    let [searchParams] = useSearchParams()

    let [err, setErr] = useState('')
    let [stream, setStream] = useState<any>()
    let [kodikData, setKodikData] = useState<KodikFullData>()

    let [tab, setTab] = useState('chat')

    let [translationSearch, setTranslationSearch] = useState('')

    useEffect(() => {
        let link = `streams/find/${params.tag?.split('@')[1]}`
        if (searchParams.get('pass')) {
            link += '?password=' + searchParams.get('pass')
        }

        axios({
            url: link
        })
            .then(r => {
                setStream(r.data)

            })
            .catch(e => {
                if (e?.response?.data?.ru) {
                    setErr(e?.response?.data?.ru)
                } else {
                    console.error(e)
                }
            })
    }, [])


    useEffect(() => {
        (async () => {
            if (!stream?.anime_id) return;
            setKodikData(await getKodikData(stream?.anime_id))
        })()
    }, [stream])

    async function changeTranslation(translation: string) {
        setStream(await createStream({
            cookies,
            name: stream.name,
            anime: stream.anime_id,
            episode: stream.episode,
            translation: translation,
            pass: stream.pass,
            setErr
        }))
    }
    async function changeAnime(anime: number) {
        setStream(await createStream({
            cookies,
            name: stream.name,
            anime: anime,
            episode: stream.episode,
            translation: stream.translation,
            pass: stream.pass,
            setErr
        }))
    }

    async function changeEpisode(episode: number) {
        setStream(await createStream({
            cookies,
            name: stream.name,
            anime: stream.anime_id,
            episode: episode,
            translation: stream.translation,
            pass: stream.pass,
            setErr
        }))
    }

    async function changePass(pass: string) {
        setStream(await createStream({
            cookies,
            name: stream.name,
            anime: stream.anime_id,
            episode: stream.episode,
            translation: stream.translation,
            pass: pass,
            setErr
        }))
    }

    return (
        <div className={"streams_page"} style={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            overflow: 'hidden',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <Error message={err} setMessage={setErr}/>

            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>Просмотр</p>

                <p></p>
            </div>

            <div className="select_page_content spc" style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingBottom: 80,
                overflow: 'hidden'
            }}>
                <div>
                    <iframe
                        src={`https://player.anitype.fun/iframe?source=${
                            encodeURIComponent(
                                kodikData?.episodes.filter(v => v.translation === stream?.translation && v.episode === stream?.episode && v.season === kodikData?.season)[0].kodikLink + `?translations=true&domain=*`)
                        }`}
                        allowFullScreen allow="autoplay *; fullscreen *" className="select_page_content_iframe"></iframe>

                    <div className="select_page_content_tabs">
                        <div className={tab === 'chat' ? "select_page_content_tab active to" : "select_page_content_tab to"} onClick={() => setTab('chat')}>ЧАТ</div>
                        <div className={tab === 'info' ? "select_page_content_tab active to" : "select_page_content_tab to"} onClick={() => setTab('info')}>ИНФОРМАЦИЯ</div>
                        <div className={tab === 'manage' ? "select_page_content_tab active to" : "select_page_content_tab to"} onClick={() => setTab('manage')}>УПРАВЛЕНИЕ</div>
                    </div>
                </div>

                <div className="select_page_content_tabs_content" style={{height: '100%'}}>
                    {
                        tab === 'chat' && 
                        <div className="select_page_content_tabs_content_chat">

                            <div className={"select_page_content_tabs_content_chat_list"}>
                                <p  className="select_page_content_tabs_content_chat_messages_message">
                                    <span className="select_page_content_tabs_content_chat_messages_message_time">16:51:33</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_moment">(15:44)</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_username">fllcker:</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_message">жидкая сперма</span>
                                </p>

                                {

                                    // @ts-ignore
                                    [...new Array(100).keys()].map(e =>
                                        <p key={e.toString()} className="select_page_content_tabs_content_chat_messages_message">
                                            <span className="select_page_content_tabs_content_chat_messages_message_time">16:51:33</span>
                                            <span className="select_page_content_tabs_content_chat_messages_message_moment">(15:44)</span>
                                            <span className="select_page_content_tabs_content_chat_messages_message_username">fllcker:</span>
                                            <span className="select_page_content_tabs_content_chat_messages_message_message">вот это да</span>
                                        </p>)
                                }

                                <p  className="select_page_content_tabs_content_chat_messages_message">
                                    <span className="select_page_content_tabs_content_chat_messages_message_time">16:51:33</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_moment">(15:44)</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_username">fllcker:</span>
                                    <span className="select_page_content_tabs_content_chat_messages_message_message">жидкая сперма</span>
                                </p>
                            </div>



                            {/*</div>*/}
                            {/*<input type="text" placeholder={"Введите сообщение..."}/>*/}
                        </div>
                    }

                    <div className="select_page_content_tabs_content_chat_input">
                    {/*  TODO: message input  */}
                        <input/>
                    </div>
                    
                    {tab === 'info' && <>
                        <div className="fwkv">
                            <p className="fwk">Название стрима</p>
                            <p className="fwv">{stream?.title}</p>
                        </div>
                        <div className="fwkv">
                            <p className="fwk">Пароль для доступа</p>
                            <p className="fwv">{stream?.password}</p>
                        </div>
                        <div className="fwkv">
                            <p className="fwk">Озвучка</p>
                            <p className="fwv">{stream?.translation}</p>
                        </div>
                        <div className="fwkv">
                            <p className="fwk">Эпизод</p>
                            <p className="fwv">{stream?.episode}</p>
                        </div>
                        <div className="fwkv">
                            <p className="fwk">Время</p>
                            <p className="fwv">{stream?.time}</p>
                        </div>
                    </>}

                    {
                        tab === 'manage' && <>
                        {/*translation*/}
                            <input type="text" className="fwkvinput" placeholder={"Поиск озвучки"} value={translationSearch} onChange={e => setTranslationSearch(e.target.value)}/>
                            {
                                translationSearch.length > 0 && kodikData?.episodes.filter(v => v.episode === stream.episode)
                                    .map(e => e.translation)
                                    .filter(e => e.toLowerCase().includes(translationSearch.toLowerCase()))
                                    .map(t =>
                                    <div className="kwkvel" key={t + 't'} onClick={() => {
                                        setTranslationSearch('')
                                        changeTranslation(t)
                                    }}>
                                        <p>{t}</p>
                                        <p>{kodikData?.episodes.filter(v => v.episode === stream.episode && v.translation === t).length} эп.</p>
                                    </div>)
                            }
                        {/*episodes*/}
                            <p style={{marginTop: 20, marginBottom: 5, color: '#eee', fontSize: 14}}>Выбор эпизода ({stream.translation}):</p>
                            <div className="fwkvbuttons">
                                {
                                    kodikData?.episodes.filter(v => v.translation === stream.translation).map(e => <button onClick={() => {
                                        setTranslationSearch('')
                                        changeEpisode(+e.episode)
                                    }} key={e.episode + 'epb'} className={"to"}>{e.episode}</button>)
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default StreamsWatchPage;