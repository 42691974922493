import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dataSlice, selectContinueWatch} from "../lib/redux";
import {getAnimeIds} from "../api/common";
import AnimeElement from "./AnimeElement";
import {proxyImg} from "../funcs/poster";
import {endKindToRu, engStatusToRu, getEpisodes} from "../funcs/anime";
import Dot from "./ui/Dot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {secondsToTime} from "../funcs/time";
import {formatAppAgent} from "../funcs/tech";
import {useNavigate} from "react-router-dom";

const ConinueWatchNavBar = () => {
    let [agentsBl, setAgentsBl] = useState([`m.anitype|${navigator.platform}|${navigator.userAgent}`])
    let timeoutRef = useRef<NodeJS.Timeout | null>(null)

    let nav = useNavigate()
    let dispatch = useDispatch()
    let data = useSelector(selectContinueWatch)

    useEffect(() => {
        (async () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
            if (data.data?.anime_id && data.data?.anime_id > 0 && !data.anime) {
                let animes = await getAnimeIds(data.data?.anime_id)
                if (animes.length > 0) dispatch(dataSlice.actions.setContinueWatchAnime(animes?.[0]))
            }
            timeoutRef.current = setTimeout(() => {
                dispatch(dataSlice.actions.setContinueWatchData())
            }, 15 * 1000)
        })()
    }, [data]);

    return (
        <div className={(data?.data && !agentsBl.includes(data?.data?.agent) && data?.data?.agent?.length) ? "nav_bar_second" : "nav_bar_second hidden"}>
            <p className="nav_bar_second_title">Продолжить просмотр с этого устройства?</p>
            {data?.anime && <>
                <div className={"anime_el to"}>

                    <div className={"anime_el_poster_cont"}>
                        <div className="anime_el_poster_cont_blured"
                             style={{backgroundImage: `url('${proxyImg(data?.anime?.id, data?.anime?.poster)}')`}}></div>
                        {
                            data?.anime?.score > 7 &&
                            <div
                                className={"anime_el_poster_rate"}>{data?.anime?.score.toFixed(1)}</div>
                        }
                        <div className={"anime_el_poster"}
                             style={{backgroundImage: `url('${proxyImg(data?.anime?.id, data?.anime?.poster)}')`}}></div>
                    </div>

                    <div className={"anime_el_info"}>
                        <p className={"anime_el_info_title"}>{data?.anime?.titles.russian}</p>
                        <p className="anime_el_info_info">
                            <p>Вы остановились на {secondsToTime(data?.data?.time ?? 0)}</p>
                            <p>{formatAppAgent(data?.data?.agent?.toLowerCase() ?? '')}</p>
                        </p>
                    </div>
                </div>
            </>}

            <div className="nav_bar_second_buttons">
                <button onClick={() => setAgentsBl([...agentsBl, data?.data?.agent ?? ''])}>Скрыть</button>
                <button onClick={() => {
                    nav(`/anime/${data?.data?.anime_id}/play?episode=${data?.data?.episode}&last=${data?.data?.time}_${data?.data?.episode}&translation=${data?.data?.translation}`)
                    setAgentsBl([...agentsBl, data?.data?.agent ?? ''])
                }}>Смотреть</button>
            </div>

        </div>
    );
};

export default ConinueWatchNavBar;