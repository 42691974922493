import React, {useState} from 'react';
import Loader from "./Loader";
import M3FilledButton from "./m3/M3FilledButton";
import M3FilledTonalButton from "./m3/M3FilledTonalButton";

interface Props {
    title: string,
    delay: number,
    onClick?: () => void
}

const MaterialButtonWLoading = (props: Props) => {
    let [loading, setLoading] = useState(false)

    return (
        <>
            <M3FilledTonalButton onClick={() => {
                setLoading(true)
                setTimeout(() => {
                    if (props.onClick) props.onClick()
                    setLoading(false)
                }, props.delay)
            }} innerClassName={"anime_fast_button_m3"}>
                <span>{props.title}</span>

                {
                    loading && <div className="anime_fast_button_loading">
                        <Loader small={true} black={true}/>
                    </div>
                }
            </M3FilledTonalButton>

            {/*<button className={loading ? "anime_fast_button to loading" : "anime_fast_button to"} onClick={() => {*/}
            {/*    setLoading(true)*/}
            {/*    setTimeout(() => {*/}
            {/*        if (props.onClick) props.onClick()*/}
            {/*        setLoading(false)*/}
            {/*    }, props.delay)*/}
            {/*}}>*/}
            {/*    <span>{props.title}</span>*/}

            {/*    {*/}
            {/*        loading && <div className="anime_fast_button_loading">*/}
            {/*            <Loader small={true} black={true}/>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</button>*/}
        </>
    );
};

export default MaterialButtonWLoading;