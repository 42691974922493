export function isUsingIphoneAndPWA() {
    const isIphone = /iPhone/i.test(navigator.userAgent);
    // @ts-ignore
    const isInStandaloneMode = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone === true);

    return isIphone && isInStandaloneMode;
}

export function isUsingIphone() {
    return /iPhone/i.test(navigator.userAgent);
}

export function resizeImage(file: File, width: number, height: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Failed to create blob.'));
                    }
                }, "null");
            }
        };
        img.onerror = (error) => {
            reject(error);
        };
    });
}

export function formatAppAgent(agent: string) {
    let tags: string[] = []
    if (agent.includes('anitype') && !agent.includes('m.') || agent.includes('windows')) tags = [...tags, 'Настольное устройство']
    if (agent.includes('m.anitype') || agent.includes('mobile')) tags = [...tags, 'Мобильное устройство']
    if (agent.includes('iphone')) tags = [...tags, 'IPhone']
    if (agent.includes('android')) tags = [...tags, 'Android']
    return tags.join(', ')
}