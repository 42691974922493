import React, {useEffect, useState} from 'react';
import {proxyImg} from "../../funcs/poster";
import {Anime} from "../../types";

const AnimeAnimatedFixedPoster = ({poster, i, total}: {
    poster: Anime,
    i: number,
    total: number
}) => {
    let [deg, setDeg] = useState(
        i * 2 - (total * 2 / 2)
    )

    let [opacity, setOpacity] = useState(false)

    useEffect(() => {
        let o = setTimeout(() => {
            setOpacity(true)
        }, i * 500)

        let t = setTimeout(() => {
            setDeg(i * 12 - (total * 14 / 2))

            t = setTimeout(() => {
                setDeg(i * 2 - (total * 2 / 2))
            }, 600)
        }, 500 * total)

        return () => {
            clearTimeout(t)
            clearTimeout(o)
        }
    }, []);

    return (
        <div className="anime_fixed_poster"
             style={{
                 backgroundImage: `url('${proxyImg(poster?.id, poster?.poster)}')`,
                 transform: `rotateZ(${deg}deg)`,
                 opacity: opacity ? 1 : 0.05
             }}></div>
    );
};

export default AnimeAnimatedFixedPoster;