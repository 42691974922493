import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate} from "react-router-dom";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";

const StreamsCreateSelectNamePage = () => {
    let nav = useNavigate()

    let [pass, setPass] = useState(localStorage.getItem('new_stream_pass') ?? '')
    useEffect(() => {
        localStorage.setItem('new_stream_pass', pass)
    }, [pass])

    return (
        <>
            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>

            <div className="select_page_content spc">
                <input type="text" placeholder={"Введите пароль для стрима"} value={pass}
                onChange={e => setPass(e.target.value)}/>

                <p className={"select_page_content_warning"} style={{marginTop: 20}}>В случае если вы хотите сделать стрим только для друзей, установите пароль!</p>
            </div>
        </>
    );
};

export default StreamsCreateSelectNamePage;