import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {getKodikData} from "../../../api/kodik";
import {distinct} from "../../../funcs/anime";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";

const StreamsCreateSelectTranslationPage = () => {
    let nav = useNavigate()

    let [kodikData, setKodikData] = useState<any>([])
    useEffect(() => {
        (async () => {
            let id = +(localStorage.getItem('new_stream_anime') ?? 0)
            if (id === 0) return;

            setKodikData(await getKodikData(id))
        })()
    }, [])

    return (
        <>
            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>
            <div className="select_page_content spc">
                {
                    kodikData.episodes && kodikData.episodes.length > 0 &&
                    distinct(kodikData.episodes?.map((e: any) => e.translation))?.map(el =>
                        <div className={"select_page_content_button to"} key={el + 't'} onClick={() => {
                            localStorage.setItem('new_stream_translation', el)
                            nav(-1)
                        }}>
                            <div className={"select_page_content_button_g"}>
                                <p className={"select_page_content_button_title"}>{el}</p>
                                <p className={"select_page_content_button_desc"}>{kodikData?.episodes?.filter((v: any) => v.translation === el).length} эп.</p>
                            </div>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </div>)
                }

            </div>
        </>
    );
};

export default StreamsCreateSelectTranslationPage;