import React from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import '../../styles/payment_page.css'
import {SUB_COSTS} from "./usermenu/UserMenuPremiumPage";

const PaymentPage = () => {
    let nav = useNavigate()

    const {itemId, username} = useParams()

    return (
        <>
            <div className="pay_page_content">
                <h1 className="pay_page_title">Оформление заказа</h1>

                <p style={{color: '#ccc'}}>
                    В настоящий момент мы испытываем трудности с платежными системами и автоматическая
                    оплата невозможна.
                </p>
                <div style={{fontWeight: 500}}>
                    <p>Отправьте </p>
                    <input type="text" value={SUB_COSTS[+(itemId ?? 1)-1].price} onClick={() => {
                        navigator?.clipboard?.writeText(SUB_COSTS[+(itemId ?? 1)-1].price + '')
                            .then(r => r)
                            .catch(e => e);
                    }}/>
                    <p>руб. на карту </p>
                    <span style={{color: '#ff4a6a'}}></span>
                    <input type="text" value={'2200 7010 7391 8036'} onClick={() => {
                        navigator?.clipboard?.writeText('2200701073918036')
                            .then(r => r)
                            .catch(e => e);
                    }}/>
                </div>
                <p>
                    Если есть возможность - укажите в комментарии к платежу имя пользователя.
                </p>
                <p>Ваше имя пользователя - <span style={{color: '#ff4a6a'}}>{username}</span></p>
                <p>
                    Если возможности указать имя пользователя нет - отправьте чек менеджеру
                </p>
                <p>Связаться с менеджером можно тут -
                    <a style={{color: '#ff4a6a'}} href="https://t.me/inspiredscout">https://t.me/inspiredscout</a>
                </p>
                <p></p>
            </div>
        </>
    );
};

export default PaymentPage;