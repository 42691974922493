import React, {useEffect, useState} from 'react';
import {Anime, EpisodeInfo, EpisodeInfoV2, EpisodeSave, KodikFullData, WAElement} from "../../types";
import {getKodikData} from "../../api/kodik";
import {getWAData} from "../../funcs/kodik";
import {faEarthAmericas} from "@fortawesome/free-solid-svg-icons/faEarthAmericas";
import {faSortDown} from "@fortawesome/free-solid-svg-icons/faSortDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import {getUserPickedTranslation} from "../../funcs/users";
import {useNavigate, useParams} from "react-router-dom";
import {getEpisodesInfo, getEpisodesInfoV2, getSeasonsInfo} from "../../api/common";
import Loader from "../Loader";
import {getSeasonName} from "../../funcs/anime";
import axios from "../../core/axios";
import {cacheableAxios} from "../../funcs/cacheableAxios";
import {useCookies} from "react-cookie";
import {secondsToTime} from "../../funcs/time";

const AnimeEpisodesBlock = ({anime, reverse, serialPage}: {
    anime: Anime | undefined, reverse: boolean, serialPage: boolean
}) => {
    let [loading, setLoading] = useState(true)

    let nav = useNavigate()
    let params = useParams()
    let [id, setId] = useState(+(anime?.id ?? 0) ?? 0)

    let [translation, setTranslation] = useState('')

    let [items, setItems] = useState<EpisodeInfo[]>([])
    let [items2, setItems2] = useState<EpisodeInfoV2[]>([])
    let [seasons, setSeasons] = useState<Anime[]>([])
    let [saves, setSaves] = useState<EpisodeSave[]>([])

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    useEffect(() => {
        (async () => {
            if (!id) return;
            setLoading(true)
            let data = await getEpisodesInfo(id);
            setItems(data);

            if (!params.translation) {
                setTranslation(getUserPickedTranslation(id, data))
            } else {
                setTranslation(params.translation)
            }

            cacheableAxios({
                url: `anime/ids?ids=${anime?.kp_id}&type=kp`
            }, 60 * 60).then(r => setSeasons(r)).catch(ignored => ignored)
            setLoading(false)

            if (cookies.access) {
                axios({
                    url: `episodes/saves/serial/${anime?.kp_id}`
                }).then(r => setSaves(r.data)).catch(ignored => ignored)
            }

            let data2 = await getEpisodesInfoV2(id, anime?.kp_id);
            setItems2(data2)
        })()
    }, [translation, nav, params?.translation, anime, id]);

    return (
        <>
            {
                loading ? <>
                    <div className="ccp" style={{minHeight: 200, height: 200}}>
                        <Loader/>
                    </div>
                </> : <>
                    <div style={{
                        maxHeight: 43
                    }} className={serialPage ? "anime_info_episodes_choose_translation" : "anime_info_episodes_choose_translation season_page"}
                         onClick={() => nav(`/anime/${id}/translation`)}>
                        {
                            (translation && items?.length > 0) ? <>
                                <FontAwesomeIcon icon={faEarthAmericas}/>
                                <p>{translation}</p>
                            </> : <Loader/>
                        }

                        {/*<FontAwesomeIcon icon={faAngleDown} />*/}
                    </div>

                    {
                        (seasons && seasons.length > 1) &&
                        <div className="anime_info_episodes_seasons_block">
                            {
                                [...seasons.sort((a, b) => {
                                    if (a.season_number > b.season_number) return 1;
                                    return -1;
                                })].map((season, index) =>
                                    <div key={`animeseason${anime?.id}${season?.id}`} onClick={() => {
                                        setId(season.id)
                                    }}
                                         className={(+(id ?? 0) === +(season?.id ?? 0)) ? `${serialPage ? 'anime_info_episodes_seasons_block_el' : 'anime_info_episodes_seasons_block_el season_page'} active` : `${serialPage ? 'anime_info_episodes_seasons_block_el' : 'anime_info_episodes_seasons_block_el season_page'}`}>{getSeasonName(season, true)}</div>)
                            }
                        </div>
                    }


                    {
                        items?.sort((a, b) => {
                            if (!a.episode || !b.episode) return 0;

                            if (+a.episode === +b.episode) return 0;

                            let result = 0;
                            if (+a.episode > +b.episode) result = 1;
                            if (+a.episode < +b.episode) result = -1;

                            if (reverse) {
                                if (result === -1) {
                                    result = 1
                                } else {
                                    result = -1
                                }
                            }

                            return result;
                        })
                            .filter(v => v.translation?.title === translation)
                            .map(v =>
                                <div className="anime_info_episodes_episode" key={v.links?.kodik + 'ep'}
                                     onClick={() => {
                                         // play
                                         nav(`/anime/${id}/play/?translation=${v.translation?.title}&episode=${v.episode}`)
                                     }}>
                                    {
                                        (v.title && v.title.length) ?
                                            <div className="anime_info_episodes_episode_image" style={{
                                                backgroundImage: `url('${v.picture}')`
                                            }}>
                                                {
                                                    v.has_uhd &&
                                                    <div
                                                        className="home2_anime_el_rate anime_info_episodes_episode_image_tag"
                                                        style={{
                                                            background: 'crimson'
                                                        }}>4K</div>
                                                }

                                                {
                                                    secondsToTime(items2?.find(v2 => v2.number == v.episode && v2.season == v.season)?.duration ?? 0) &&
                                                        <div className="anime_info_episodes_episode_image_time_tag">{
                                                            secondsToTime(items2?.find(v2 => v2.number == v.episode && v2.season == v.season)?.duration ?? 0)
                                                        }</div>
                                                }


                                                {
                                                    saves?.find(s =>
                                                        s?.episode?.number === v?.episode &&
                                                        s?.episode?.season === v?.season &&
                                                        s?.time > 0
                                                    ) &&
                                                    <div className="anime_info_episodes_episode_image_progress">
                                                        <div
                                                            className="anime_info_episodes_episode_image_progress_progress" style={{
                                                                width: `${
                                                                    ((
                                                                        saves?.find(s =>
                                                                            s?.episode?.number === v?.episode &&
                                                                            s?.episode?.season === v?.season &&
                                                                            s?.time > 0
                                                                        )?.time ?? 0
                                                                    ) / (
                                                                        saves?.find(s =>
                                                                            s?.episode?.number === v?.episode &&
                                                                            s?.episode?.season === v?.season &&
                                                                            s?.time > 0
                                                                        )?.duration ?? 1440
                                                                    )) * 100
                                                                }%`
                                                        }}></div>
                                                    </div>
                                                }

                                            </div> :
                                            <div
                                                className={serialPage ? "anime_info_episodes_episode_image" : "anime_info_episodes_episode_image season_page"} style={{
                                                backgroundImage: `url('${'https://s3.timeweb.com/24399392-anitype-videos/useruploads/default_ep_pic.jpg'}')`
                                            }}>
                                                {
                                                    v.has_uhd &&
                                                    <div
                                                        className="home2_anime_el_rate anime_info_episodes_episode_image_tag"
                                                        style={{
                                                            background: 'crimson'
                                                        }}>4K</div>
                                                }

                                                {
                                                    saves?.find(s =>
                                                        s?.episode?.number === v?.episode &&
                                                        s?.episode?.season === v?.season &&
                                                        s?.time > 0
                                                    ) &&
                                                    <div className="anime_info_episodes_episode_image_progress">
                                                        <div
                                                            className="anime_info_episodes_episode_image_progress_progress" style={{
                                                            width: `${
                                                                ((
                                                                    saves?.find(s =>
                                                                        s?.episode?.number === v?.episode &&
                                                                        s?.episode?.season === v?.season &&
                                                                        s?.time > 0
                                                                    )?.time ?? 0
                                                                ) / (
                                                                    saves?.find(s =>
                                                                        s?.episode?.number === v?.episode &&
                                                                        s?.episode?.season === v?.season &&
                                                                        s?.time > 0
                                                                    )?.duration ?? 1440
                                                                )) * 100
                                                            }%`
                                                        }}></div>
                                                    </div>
                                                }
                                            </div>
                                    }


                                    <div className="anime_info_episodes_episode_info">
                                        <div className="anime_info_episodes_episode_info_title golos">
                                            {
                                                !saves?.find(s =>
                                                    s?.episode?.number === v?.episode &&
                                                    s?.episode?.season === v?.season &&
                                                    s?.time > 0
                                                ) && <div className="no_save_dot"></div>
                                            }

                                            <p className="golos">{
                                                (v.title && v.title.length) ?
                                                    `${v.episode}. ${v.title.split('/').pop()}` :
                                                    `${v.episode} эпизод`
                                            }</p>
                                        </div>


                                        <p className="anime_info_episodes_episode_info_desc golos">
                                            {
                                                items2?.find(v2 => v2.number == v.episode && v2.season == v.season)?.description
                                            }
                                        </p>
                                    </div>
                                </div>)
                    }
                </>
            }
        </>
    );
};

export default AnimeEpisodesBlock;