import React, {useEffect, useState} from 'react';
import AutoFadeTopBar from "../AutoFadeTopBar";
import '../../styles/new_selection_page.css'
import {Anime, Selection} from "../../types";
import AnimeElementIdsBlock from "../AnimeElementIdsBlock";
import AnimeElement from "../AnimeElement";
import axios from "../../core/axios";
import Error from "../Error";
import PremiumBadge from "../PremiumBadge";
import {changeAvatarOrBanner} from "../../api/common";
import {useNavigate} from "react-router-dom";

const NewSelectionPage = () => {
    let nav = useNavigate()

    let [step, setStep] = useState<'title' | 'text' | 'anime_pick' | 'banner'>('title')

    let [selection, setSelection] = useState<Selection>()

    let [title, setTitle] = useState('')
    let [text, setText] = useState('')
    let [search, setSearch] = useState('')
    let [searchEls, setSearchEls] = useState<Anime[]>([])
    let [searchPicked, setSearchPicked] = useState<Anime[]>([])

    let [err, setErr] = useState('')

    async function create() {
        axios({
            url: `selections/`,
            method: 'post',
            data: {
                title: title,
                text: text,
                items: searchPicked.map(e => e.id).join(',')
            }
        })
            .then(r => setSearchEls(r.data))
            .catch(e => {})
    }

    useEffect(() => {
        if (search.length > 0) {
            axios({
                url: `anime/search?keyword=${search}`
            })
                .then(r => setSearchEls(r.data))
                .catch(e => [])
        } else {
            setSearchEls([])
        }

    }, [search]);

    return (
        <>
            <Error message={err} setMessage={setErr}/>

            <AutoFadeTopBar title={'Создание подборки'} alwaysShown={true}/>

            <div className="new_selection_page">
                { step === 'title' &&
                    <>
                        <input type="text" placeholder={"Название"}
                               value={title} onChange={e => setTitle(e.target.value)}/>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <button className="anime_fast_button to" onClick={() => {
                                if (title.length < 6 || title.length > 64) {
                                    setErr('Длина названия должна быть от 6 до 64 символов!')
                                    return
                                }
                                setStep('anime_pick')
                            }}>Продолжить</button>
                        </div>

                        <div className="selection_hint">
                            <p className={"rule"}>1. Старайтесь создавать оригинальные подборки, не повторяя то, что уже было сделано.</p>
                            <p className={"rule"}>2. Не создавайте подборки по типу: {"\"лучшие аниме\""}, {"\"аниме в озвучке ...\""}</p>
                            <p className={"rule"}>3. Не создавайте подборки посвященные одной франшизе аниме</p>
                            {/*<p>3. Обратите внимание, что Вы можете добавить до 50 элементов в подборку. Выбирайте лучшее!</p>*/}
                        </div>
                    </>
                }

                { step === 'anime_pick' &&
                    <>


                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <button className="anime_fast_button to" onClick={() => {
                                setSearchPicked([])
                            }}>Отчистить</button>

                            <button className="anime_fast_button to" onClick={() => {
                                if (searchPicked.length < 3 || searchPicked.length > 50) {
                                    setErr('В подборке должно быть от 3 до 50 элементов!')
                                    return
                                }
                                setStep('text')
                            }}>Продолжить (выбрано {searchPicked.length} эл.)</button>
                        </div>

                        <div className="selection_hint">
                            <p className={"rule"}>1. Обратите внимание, что Вы можете добавить до 50 элементов в подборку. Выбирайте лучшее!</p>
                            <p className={"rule"}>2. На следующем шаге можно будет написать текст подборки и описать добавленные аниме.</p>
                        </div>

                        <input style={{
                            marginTop: 30,
                            marginBottom: 10
                        }} type="text" placeholder={"Поиск"}
                               value={search} onChange={e => setSearch(e.target.value)}/>
                        {/*<p style={{marginTop: 20}}>Результаты поиска:</p>*/}
                        {
                            searchEls.map(el => <div className="new_selection_anime_el" key={el.id}>
                                <AnimeElement viewMode={'column'} anime={el}/>
                                <button className="anime_fast_button to new_selection_anime_el_button" onClick={() => {
                                    if (!searchPicked.includes(el)) {
                                        setSearchPicked((p) => [el, ...p])
                                    } else {
                                        setSearchPicked((p) => p.filter(v => v.id != el.id))
                                    }
                                }}>{
                                    searchPicked.includes(el) ? 'Удалить' : 'Добавить'
                                }</button>
                            </div>)
                        }
                    </>
                }

                { step === 'text' &&
                    <>
                        <textarea className={"anime_comments_block_comment_input_textarea"} placeholder={"Текст подборки"} value={text} onChange={e => setText(e.target.value)}></textarea>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <p style={{
                                color: '#bbb',
                                fontSize: 12
                            }}>
                                <span style={{
                                    color: text.length > 2048 ? 'crimson' : '#bbb'
                                }}>{text.length}</span>
                                /2048</p>
                            <button className="anime_fast_button to" onClick={() => {
                                if (text.length < 16) {
                                    setErr('Текст подборки слишком короткий!')
                                    return
                                }
                                if (text.length < 2048) {
                                    setErr('Текст подборки должен быть до 2048 символов!')
                                    return
                                }
                                create().then(() => setStep('banner'))

                            }}>Продолжить</button>
                        </div>

                        {/*<div className="selection_hint">*/}
                        {/*    /!*<p className={"rule"}>1. Старайтесь создавать оригинальные подборки, не повторяя то, что уже было сделано.</p>*!/*/}
                        {/*    /!*<p className={"rule"}>2. Не создавайте подборки по типу: {"\"лучшие аниме\""}, {"\"аниме в озвучке ...\""}</p>*!/*/}
                        {/*    /!*<p className={"rule"}>3. Не создавайте подборки посвященные одной франшизе аниме</p>*!/*/}
                        {/*</div>*/}
                    </>
                }

                { step === 'banner' &&
                    <>
                        <div className={"banner_receiver user_banner"} style={{width: '100%', marginBottom: 10}}>
                            <input className={"avatar_receiver_input"} type="file" style={{marginBottom: 20}} accept="image/png, image/jpeg, image/gif"
                                   onChange={(ev) => changeAvatarOrBanner(ev, `selection?selectionId=${selection?.id}`, (v: any) => {}, setErr)} alt={""}/>
                            <p>Выберите баннер</p>
                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <button className="anime_fast_button to" onClick={() => {
                                nav('/')
                            }}>Продолжить</button>
                        </div>

                        <div className="selection_hint">
                            <p className={"rule"}>Подборка создана. Осталось выбрать баннер! Рекомендуется использовать
                            изображение форматом 16:9, до 3 МБ, png/jpg/webp</p>
                            <p className={"rule"}>Если подборка не появилась через несколько минут, значит она попала на модерацию. Не нужно создавать ее еще раз!</p>
                            {/*<p className={"rule"}>2. Не создавайте подборки по типу: {"\"лучшие аниме\""}, {"\"аниме в озвучке ...\""}</p>*/}
                            {/*<p className={"rule"}>3. Не создавайте подборки посвященные одной франшизе аниме</p>*/}
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default NewSelectionPage;