import React from 'react';

const AniTypeCoin = ({size}: {
    size: number
}) => {
    return (
        <>
            <svg width={size} height={size} viewBox="0 0 390 390" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="210.5" cy="281.5" rx="172.5" ry="84.5" fill="#E4AF17"/>
                <path d="M41 233L38 282L383 286L385.5 233H41Z" fill="#E4AF17"/>
                <ellipse cx="213.5" cy="236.5" rx="172.5" ry="84.5" fill="#FAC629"/>
                <ellipse cx="216" cy="233" rx="145" ry="60" fill="#FDD340"/>
                <path
                    d="M157 260L188.59 228.41L164 214H203L221 196L227.047 214H270L232.214 229.382L242.5 260L207.452 239.462L157 260Z"
                    fill="#EDB81E"/>
                <path d="M94 344V298L105 303V348.5L94 344Z" fill="#CE9911"/>
                <path d="M125 355L113.5 351.5V306L125 309V355Z" fill="#CE9911"/>
                <path d="M260 363L244 364.5V320L260 318.5V363Z" fill="#CE9911"/>
                <path d="M290.5 356.5L275 360V315.5L290.5 312.5V356.5Z" fill="#CE9911"/>
                <path d="M316 348.5L302 353V309L316 304.5V348.5Z" fill="#CE9911"/>
                <ellipse cx="172.5" cy="213.5" rx="172.5" ry="84.5" fill="#E4AF17"/>
                <path d="M3 165L0 214L345 218L347.5 165H3Z" fill="#E4AF17"/>
                <ellipse cx="175.5" cy="168.5" rx="172.5" ry="84.5" fill="#FAC629"/>
                <ellipse cx="178" cy="165" rx="145" ry="60" fill="#FDD340"/>
                <path
                    d="M119 192L150.59 160.41L126 146H165L183 128L189.047 146H232L194.214 161.382L204.5 192L169.452 171.462L119 192Z"
                    fill="#EDB81E"/>
                <path d="M56 276V230L67 235V280.5L56 276Z" fill="#CE9911"/>
                <path d="M87 287L75.5 283.5V238L87 241V287Z" fill="#CE9911"/>
                <path d="M222 295L206 296.5V252L222 250.5V295Z" fill="#CE9911"/>
                <path d="M252.5 288.5L237 292V247.5L252.5 244.5V288.5Z" fill="#CE9911"/>
                <path d="M278 280.5L264 285V241L278 236.5V280.5Z" fill="#CE9911"/>
                <ellipse cx="214.5" cy="152.5" rx="172.5" ry="84.5" fill="#E4AF17"/>
                <path d="M45 104L42 153L387 157L389.5 104H45Z" fill="#E4AF17"/>
                <ellipse cx="217.5" cy="107.5" rx="172.5" ry="84.5" fill="#FAC629"/>
                <ellipse cx="220" cy="104" rx="145" ry="60" fill="#FDD340"/>
                <path
                    d="M161 131L192.59 99.4096L168 85H207L225 67L231.047 85H274L236.214 100.382L246.5 131L211.452 110.462L161 131Z"
                    fill="#EDB81E"/>
                <path d="M98 215V169L109 174V219.5L98 215Z" fill="#CE9911"/>
                <path d="M129 226L117.5 222.5V177L129 180V226Z" fill="#CE9911"/>
                <path d="M264 234L248 235.5V191L264 189.5V234Z" fill="#CE9911"/>
                <path d="M294.5 227.5L279 231V186.5L294.5 183.5V227.5Z" fill="#CE9911"/>
                <path d="M320 219.5L306 224V180L320 175.5V219.5Z" fill="#CE9911"/>
            </svg>

        </>
    );
};

export default AniTypeCoin;