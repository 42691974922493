import React from 'react';
import PremiumSvg from "./assets/PremiumSvg";
import PremiumBadge from "./PremiumBadge";

const UserBannerReceiver = ({change}: any) => {
    return (
        <div className={"banner_receiver user_banner"}>
            <input className={"avatar_receiver_input"} type="file" style={{marginBottom: 20}} accept="image/png, image/jpeg, image/gif"
                   onChange={change} alt={""}/>
            <p>Выберите баннер</p>

            <PremiumBadge position={'top_left'}/>
        </div>
    );
};

export default UserBannerReceiver;