import {cacheableAxios} from "../funcs/cacheableAxios";
import {BACKEND} from "../consts";
import axios from "../core/axios";
import {ChangeEvent} from "react";
import {resizeImage} from "../funcs/tech";
import {Anime, EpisodeInfo, EpisodeInfoV2, EpisodeSave} from "../types";

export async function getSelections(page: number, size: number, order: 'createdAt' | 'saved') {
    return await cacheableAxios({
        url: `selections/all?page=${page}&size=${size}&orderby=${order}`,
    }, 60 * 60)
}

export async function getUsersLeaderboard(page: number, size: number) {
    return await cacheableAxios({
        url: `app2/users/leaderboard/level?page=${page}&size=${size}`,
    }, 60 * 60)
}

export async function getAnimeIds(ids: string | number, type?: string) {
    return await cacheableAxios({
        url: `anime/ids?ids=${ids}&type=${type === 'kp' ? 'kp' : 's'}`
    }, 60 * 60).then(r => r).catch(ignored => ([]))
}

export async function getEpisodesInfo(id: number): Promise<EpisodeInfo[]> {
    return await cacheableAxios({
        url: `videos/episodes/${id}`
    }, 60 * 15).then(r => r).catch(ignored => ([]))
}

export async function getEpisodesInfoV2(id: number, serial_id?: number): Promise<EpisodeInfoV2[]> {
    return await cacheableAxios({
        url: `episodes/${serial_id ? 'serial/' + serial_id : 'anime/' + id}`
    }, 60 * 15).then(r => r).catch(ignored => ([]))
}

export async function getSeasonsInfo(id: number): Promise<Anime[]> {
    return await cacheableAxios({
        url: `anime/seasons/${id}`
    }, 60 * 15).then(r => r).catch(ignored => ([]))
}

export async function getWatchlogsUserPopular() {
    return await cacheableAxios({
        url: 'episodes/saves/popular?days=7'
    }, 60 * 60).then(r => r).catch(ignored => [])
}

export async function getEpisodesHistory(page: number, size: number): Promise<EpisodeSave[]> {
    return await cacheableAxios({
        url: `episodes/saves/history?page=${page}&size=${size}`
    }, 60 * 60).then(r => r).catch(ignored => [])
}

export async function getWatchlogsByAnimeId(id: number | string): Promise<EpisodeSave | null> {
    return await axios({
        url: `episodes/saves/anime/${id}?size=1`
    }).then(r => {
        return r?.data?.find((v: EpisodeSave) => v)
    }).catch(e => null)
}

export async function getNotifications(page: number, size: number) {
    return await cacheableAxios({
        url: `app2/notifications?size=${size}&page=${page}`,
    }, 60 * 3).then(r => r).catch(e => ({}))
}

export async function readNotificationsIds(ids: string) {
    return axios({
        method: 'put',
        url: `app2/notifications/read?ids=${ids}`,
    }).then(r => r.data).catch(e => null)
}
export async function readNotificationsAll() {
    return axios({
        method: 'put',
        url: `app2/notifications/read/all`,
    }).then(r => r.data).catch(e => null)
}

export async function sendVisitUser() {
    return await axios({
        method: 'post',
        url: 'app2/visit/do/user',
        data: {
            url: 'm.'
        }
    }).catch(ignored => {})
}

export async function sendVisit() {
    return await axios({
        method: 'post',
        url: 'app2/visit/do',
        data: {
            url: 'm.'
        }
    }).catch(ignored => {})
}

export async function searchAnimes(q: string, page: number, size: number) {
    return await cacheableAxios({
        url: `anime/search?keyword=${q}&limit=${size}&page=${page}`,
    }, 60 * 60)
}

export async function searchUsers(q: string) {
    return await cacheableAxios({
        url: `app2/users/search/${q}`,
    }, 60 * 60)
}

export async function renameUserFolder(id: string, newName: string) {
    return axios({
        url: `app2/folders/${id}/${newName}`,
        method: 'put',
    });
}

export async function truncateUserFolder(title: string) {
    return axios({
        url: `app2/folders/trun/${title}/anime`,
        method: 'delete'
    })
}

export async function deleteUserFolder(id: string) {
    return axios({
        url: `app2/folders/${id}`,
        method: 'delete'
    })
}

export async function createFolder(title: string) {
    return axios({
        method: 'post',
        url: 'app2/folders',
        data: {
            base: 'anime',
            title: title
        }
    })
        .then(r => true)
        .catch(r => true)
}

export async function getFoldersMy() {
    return axios({
        url: 'app2/folders/my'
    }).then(r => r.data).catch(e => [])
}

export async function getSavedSelections(page: number, size: number) {
    return axios({
        url: `selections/favourites/me?page=${page}&size=${size}`
    }).then(r => r.data).catch(e => [])
}

export async function getFolderItems(title: string) {
    return axios({
        url: BACKEND + `app2/folders/items/${title}`,
    }).then(r => r.data).catch(e => ([]))
}

export async function getUserInfo() {
    return axios({
        url: `users/me`
    }).then(r => r.data).catch(e => null)
}

export async function getUserInfoByName(name: string) {
    return axios({
        url: `users/info/${name}`
    }).then(r => r.data).catch(e => [])
}

export async function uploadUserAvatarOrBanner(act: string, formData: any) {
    return await axios.post(`media/a/upload/${act}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export async function changeAvatarOrBanner(event: ChangeEvent<HTMLInputElement>, act: string, setAvatar: (value: string) => void, setErr: (value: string) => void) {
    let file = event.target.files ? event.target.files[0] : null;
    if (file != null) {

        const formData = new FormData();

        let resizedFile: Blob | null;

        if (file.type === 'image/gif' || act === 'banner' || act.startsWith('selection')) {
            resizedFile = file
        } else {
            resizedFile = await resizeImage(file, 250, 250);
        }

        formData.append('upload', resizedFile);

        try {
            await uploadUserAvatarOrBanner(act, formData)

            const reader = new FileReader();
            reader.onload = () => {
                const preview = reader.result as string;
                setAvatar(preview);
            };
            reader.readAsDataURL(resizedFile);

            setErr(`${act === 'avatar' ? 'Аватар' : 'Баннер'} будет обновлен в скором времени`)
        } catch (e: any) {
            setErr('Произошла ошибка')
        }

    }
}

export async function createWatchTogetherHub() {
    return axios({
        url: "app2/hubs",
        method: 'post'
    }).then(r => r.data).catch(e => [])
}

export async function countCommentsByUser(id: string) {
    return await cacheableAxios({
        url: `comments/count/user/${id}`
    }, 60 * 60) .then(r => r) .catch(e => 0)
}

export async function getCommentsByTarget(target: string, page: number, size: number) {
    return await cacheableAxios({
        url: `comments/target/${target}?page=${page}&size=${size}`
    }, 60 * 10).then(r => r).catch(e => ([]))
}

export async function postVideoReaction(anime_id?: number, episode?: number, trans?: string, reaction_type?: string) {
    return await axios({
        method: 'post',
        url: 'kodik/reactions',
        data: {
            anime_id: +(anime_id ?? -1),
            episode: +(episode ?? -1),
            translation: trans,
            reaction_type: reaction_type
        }
    })
        .then(() => true)
        .catch(() => false);
}

export async function getMyVideoReaction(anime_id: string | number, episode: number) {
    return await axios({
        url: `kodik/reactions/my?anime_id=${anime_id}&episode=${episode}`
    }) .then(r => r.data) .catch(e => undefined)
}

export async function countVideoReactions(anime_id: string | number, episode: number) {
    return await cacheableAxios({
        url: `kodik/reactions/count?anime_id=${anime_id}&episode=${episode}`,
    }, 60 * 15)
}

export async function countVideoWatches(anime_id: string | number, episode: number) {
    return await cacheableAxios({
        url: `kodik/watch/count?anime_id=${anime_id}&episode=${episode}`,
    }, 60 * 15)
}

