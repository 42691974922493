import React from 'react';
import UserAvatar from "../UserAvatar";
import {Comment, UserInfo} from "../../types";
import UserEmoji from "../UserEmoji";
import {timeAgoTextNew} from "../../funcs/time";
import {removeHtmlAndSpecialChars} from "../../funcs/anime";
import {pluralizeResponses} from "../../funcs/ru_lang";
import {useNavigate} from "react-router-dom";

const AnimeCommentEl = ({el}: {
    el: Comment | undefined
}) => {
    let nav = useNavigate()

    let openComment = () => nav(`/comments/${el?.id}`)

    return (
        <>
            <div className="anime_comments_block_comment">

                <div style={{marginRight: 15}} onClick={() => nav(`/profile/${el?.user.username}`)}>
                    <UserAvatar user={el?.user as UserInfo | undefined}/>
                </div>

                <div className="anime_comments_block_comment_data">
                    <div className="anime_comments_block_comment_data_header">
                        <p className={el?.user?.sub ? "anime_comments_block_comment_data_header_username sub" : "anime_comments_block_comment_data_header_username"}>
                            {el?.user.username}
                            <UserEmoji user={el?.user as UserInfo | undefined}/>
                        </p>
                        <p className="anime_comments_block_comment_data_header_date">{timeAgoTextNew(el?.createdAt ?? '')}</p>
                    </div>

                    <div className={el?.tags.map(e => e.value).includes('spoiler') ? "anime_comments_block_comment_data_body blured" : "anime_comments_block_comment_data_body"} onClick={openComment}>
                        <p>{
                            removeHtmlAndSpecialChars(el?.text ?? '')
                        }</p>
                    </div>

                    <div className="anime_comments_block_comment_data_actions">
                        {
                            (el?.children_count ?? 0) > 0 &&
                            <p className={"to"} style={{width: 'fit-content'}}
                               onClick={openComment}>{pluralizeResponses(el?.children_count ?? 0)}</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnimeCommentEl;