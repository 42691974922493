import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Anime} from "../../../types";
import '../../../styles/anime_page.css'
import {checkAnimeBan,} from "../../../funcs/anime";
import Loader from "../../Loader";
import AnimeFixedBlock from "../../anime-components/AnimeFixedBlock";
import AnimeTitlesBlock from "../../anime-components/AnimeTitlesBlock";
import AnimePlayButtonBlock from "../../anime-components/AnimePlayButtonBlock";
import AnimeMultiBlock from "../../anime-components/AnimeMultiBlock";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import AnimeMoreButton from "../../anime-components/AnimeMoreButton";
import {getUserMyInfo} from "../../../funcs/api/users";
import {getAnimeIds} from "../../../api/common";
import AnimeActionsBlock from "../../anime-components/AnimeActionsBlock";
import {useDispatch, useSelector} from "react-redux";
import {dataSlice, selectAnime, selectAnimePagePlayLink} from "../../../lib/redux";
import AnimeFlyPlayBlock from "../../anime-components/AnimeFlyPlayBlock";

const AnimePage = () => {
    let dispatch = useDispatch()
    let params = useParams()
    let anime = useSelector(selectAnime(+(params?.id ?? 0)))
    let [isLoading, setIsLoading] = useState(false)

    let [sub, setSub] = useState(false)

    let nav = useNavigate()
    let playLink = useSelector(selectAnimePagePlayLink)

    useEffect(() => {
        (async () => {
            window.scrollTo(0, 0)

            if (anime && anime.id === (params.id ?? 0)) return;

            setIsLoading(true)
            let data = await getAnimeIds(params.id ?? '')
            if (data?.length && data?.length > 0) {
                dispatch(dataSlice.actions.addAnime(data[0]))
                setIsLoading(false)
            }
        })()
    }, [params.id])

    useEffect(() => {
        (async () => {
            let userInfo = await getUserMyInfo();
            setSub(userInfo?.sub ?? false)
        })()
    }, []);

    return (
        <>
            <AutoFadeTopBar title={anime?.titles.russian ?? ''} children={
                <AnimeMoreButton anime={anime} sub={sub}/>
            }/>

            {
                isLoading || !anime ? <div className="ccp">
                    <Loader/>
                </div> : <>
                    <AnimeFixedBlock anime={anime} serials={[anime]} serialPage={false}/>

                    <div className="anime_info_cont">
                        <div className="anime_info_cont_top season_page" onClick={() => nav(playLink)}></div>
                        <div className="anime_info_cont_cont season_page">
                            <AnimeTitlesBlock anime={anime} serials={[anime]} serialPage={false}/>

                            <AnimeFlyPlayBlock anime={anime}/>
                            {/*<AnimePlayButtonBlock anime={anime}/>*/}

                            <AnimeActionsBlock anime={anime} serialPage={false}/>

                            {/*<AnimeAddToFolderBlock/>*/}

                            {
                                checkAnimeBan(anime) ?
                                    <>
                                        <p className={"anime_bm"}>Доступ ограничен в связи с обращением правообладателя.</p>

                                        {
                                            checkAnimeBan(anime)?.by === 'kp' &&
                                            <p className={"anime_bm"}>Просмотр в Российской Федерации и в некоторых странах СНГ доступен на стриминговом сервисе Кинопоиск.</p>
                                        }
                                        {
                                            checkAnimeBan(anime)?.by === 'imdb' &&
                                            <p className={"anime_bm"}>Просмотр доступен на стриминговом сервисе IMDB.</p>
                                        }
                                    </> : <>
                                        {/*<AnimeDescriptionBlock anime={anime}/>*/}

                                        {anime?.status !== 'anons' &&
                                            <AnimeMultiBlock anime={anime} serialPage={false}/>
                                        }

                                    </>
                            }
                        </div>

                        <div style={{height: 100}}></div>
                    </div>
                </>
            }


        </>
    );
};

export default AnimePage;