import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {createDate, dateToSort, nowToSort, oneFToTwo} from "../../../funcs/time";
import {getUserMyInfo} from "../../../funcs/api/users";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import {UserInfo} from "../../../types";
import MaterialButtonWLoading from "../../MaterialButtonWLoading";
import {isUsingIphoneAndPWA} from "../../../funcs/tech";
import axios from "axios";
import M3FilledTonalButton from "../../m3/M3FilledTonalButton";

const UserMenuPremiumPage = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let [sub, setSub] = useState<any>()
    let [loading, setLoading] = useState(false)

    let [userInfo, setUserInfo] = useState<UserInfo>()
    let [selectedItemId, setSelectedItemId] = useState<string>('14666635-4cd3-4878-8dfd-8aadb5c4c2d5')
    let [popupOpened, setPopupOpened] = useState(false)

    let [email, setEmail] = useState('')

    useEffect(() => {
        let x = document.querySelector('body');
        if (x && popupOpened) {
            x.style.overflowY = 'hidden'
        } else if (x) {
            x.style.overflowY = 'scroll'
        }

        return () => {
            let x = document.querySelector('body');
            if (x) {
                x.style.overflowY = 'scroll'
            }
        }
    }, [popupOpened]);

    useEffect(() => {
        (async () => {
            setLoading(true)
            let info = await getUserMyInfo();

            if (!info) return;

            setUserInfo(info)

            if (info?.email) setEmail(info?.email)

            setSub(info?.subscriptions.find((el: any) => {
                let endDate = (new Date(el.endDate)).getTime();
                let now = Date.now();

                return endDate > now;
            }))
            setLoading(false)
        })()
    }, [])

    return (
        <>

            <AutoFadeTopBar title={`AniType Premium`} alwaysShown={true}/>

            {popupOpened && <div className="select_sub_popup_close" onClick={() => setPopupOpened(false)}></div>}

            <div className={popupOpened ? "select_sub_popup" : "select_sub_popup hidden"}>
                <div className={popupOpened ? "select_sub_popup_bottom" : "select_sub_popup_bottom hidden"}>
                    {/*<input type="email" placeholder={"Email"} style={{*/}
                    {/*    padding: '25px 15px',*/}
                    {/*    background: 'transparent'*/}
                    {/*}} value={email} onChange={e => setEmail(e.target.value)}/>*/}

                    <a href={`https://anitype.ru/order?id=${selectedItemId}&username=${userInfo?.username ?? ''}&email=${userInfo?.email ?? ''}`}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                        <M3FilledTonalButton onClick={() => {}} innerClassName={"anime_fast_button_m3"} rootClassName={"select_sub_popup_go_button"}>
                            <span>Продолжить</span>
                        </M3FilledTonalButton>
                    </a>

                    {/*<MaterialButtonWLoading title={'Продолжить'} delay={1000} onClick={() => {*/}

                    {/*    window.location.href = `https://anitype.ru/order?id=${selectedItemId}&username=${userInfo?.username ?? ''}&email=${userInfo?.email ?? ''}`*/}
                    {/*}}/>*/}

                    {isUsingIphoneAndPWA() && <div style={{height: 20}}></div>}
                </div>
                {
                    SUB_COSTS.map(i =>
                        <div className="select_sub_popup_var" key={i + 'subvar'} onClick={() => {
                            setSelectedItemId(i.id)
                        }}>
                            <div className="select_sub_popup_var_picker_cont">
                                <div className="select_sub_popup_var_picker">
                                    {
                                        selectedItemId === i.id &&
                                        <div className="select_sub_popup_var_picker_inner"></div>
                                    }
                                </div>
                            </div>
                            <div className="select_sub_popup_var_desc">
                                <p>{i.days} дней,<span style={{
                                    marginLeft: 5,
                                    color: '#ccc'
                                }}>{i.price}₽</span></p>
                                <p style={{
                                    color: '#ccc',
                                    fontSize: 12
                                }}>{i.desc}</p>
                            </div>
                        </div>)
                }
                {isUsingIphoneAndPWA() && <div style={{height: 36}}></div>}

            </div>


            <div className="select_page_content spc premium_page">
                <div className="premium_icon">🍁</div>

                <h1 className="premium_title golos">AniType Premium</h1>

                {
                    loading ? <Loader/> : <>
                        {
                            sub ? <>
                                <button className="anime_fast_button" style={{
                                    marginRight: 0,
                                    fontWeight: 500
                                }} onClick={() => {
                                    // window.location.href = `https://anitype.ru/shop?cat=1&username=${username}`
                                    setPopupOpened(true)
                                }}>
                                    {`до ${oneFToTwo((new Date(sub.endDate)).getDate())}.${oneFToTwo((new Date(sub.endDate)).getMonth() + 1)}.${oneFToTwo((new Date(sub.endDate)).getFullYear())}`}
                                </button>
                            </> : <>
                                <div className="premium_connect_buttons">
                                    <button className="anime_fast_button" style={{
                                        marginRight: 0,
                                        fontWeight: 500
                                    }} onClick={() => {
                                        // window.location.href = `https://anitype.ru/shop?cat=1&username=${username}`
                                        setPopupOpened(true)
                                    }}>
                                        от 108,25 ₽ в месяц
                                    </button>
                                    <div className="premium_connect_buttons_separator">
                                        <div></div>
                                        <p>или</p>
                                        <div></div>
                                    </div>
                                    <button className="anime_fast_button anime_fast_button2" style={{
                                        marginRight: 0,
                                        fontWeight: 500
                                    }} onClick={() => {
                                        window.location.href = `https://t.me/anitypepremiumbot`
                                    }}>
                                        Попробовать бесплатно
                                    </button>
                                </div>
                            </>
                        }
                    </>
                }


                <div className="premium_sep"></div>

                <div className="premium_bonuses">

                    {
                        subBonuses.map((el, index) =>
                            <div className="premium_bonus" key={index + 'bonus'}>
                                <div className={"premium_bonus_emoji"}>{el.emoji}</div>
                                <div className={"premium_bonus_info"}>
                                    <p className="premium_bonus_title">{el.title}</p>
                                    <p className="premium_bonus_desc">{el.desc}</p>
                                </div>
                            </div>)
                    }


                </div>


            </div>
        </>
    );
};

export default UserMenuPremiumPage;

export const subBonuses = [
    {
        emoji: '📺',
        title: 'Отключение рекламы',
        desc: 'Отключение рекламы на сайте и в AniType плеере'
    },
    {
        emoji: '🍀',
        title: '4K библиотека',
        desc: 'Доступ к нашей библиотеке аниме в 4К качестве'
    },
    {
        emoji: '📕',
        title: 'Удвоенные лимиты',
        desc: 'Вы сможете оставлять вдвое больше комментариев в день, комментариев к профилям в день, видео в профиль и т.д.'
    },
    {
        emoji: '🔔',
        title: 'Уведомления о новых эпизодах',
        desc: 'Вы будете получать уведомления о выходе новых эпизодов выбранных вами аниме и озвучек.'
    },
    {
        emoji: '✨',
        title: 'Уровень',
        desc: 'Вы сможете получить уровень выше 5-го'
    },
    {
        emoji: '🎬',
        title: 'Видеоаватары',
        desc: 'Возможность установить gif аватарку'
    },
    {
        emoji: '😃',
        title: 'Эмодзи-статусы',
        desc: 'Возможность установить эмодзи рядом с именем пользователя'
    },
    {
        emoji: '🌄',
        title: 'Баннер профиля',
        desc: 'Возможность установить баннер, который будет отображаться в вашем профиле'
    },
]

export const SUB_COSTS = [
    {
        days: 7,
        price: 99,
        desc: 'Для того, чтобы попробовать',
        id: 'dd996318-35d0-434f-9200-b9da10890a8c',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 7 дней'
    },
    {
        days: 14,
        price: 149,
        desc: 'Просто так',
        id: 'ecb038ec-e95a-4c9d-a2b5-7f339c5bc881',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 14 дней'
    },
    {
        days: 30,
        price: 199,
        desc: 'Это база',
        id: '14666635-4cd3-4878-8dfd-8aadb5c4c2d5',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 30 дней'
    },
    {
        days: 90,
        price: 490,
        desc: 'Ради выгоды',
        id: '0e93f317-66e0-4d48-9087-088ea23ecc63',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 90 дней'
    },
    {
        days: 180,
        price: 990,
        desc: 'Ради большой выгоды',
        id: '932fcd24-e94a-4d6f-a0f5-04da011ec9c8',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 180 дней'
    },
    {
        days: 360,
        price: 1990,
        desc: 'Ради ооочень большой выгоды',
        id: 'd4d1cfc4-22e4-4c63-be09-0e782be5d580',
        payDesc: 'Смена статуса аккаунта на \"Premium\" сроком на 360 дней'
    },
]