import React from 'react';
import {MaterialButtonProps} from "./M3FilledTonalButton";

const M3OutlinedButton = (p: MaterialButtonProps) => {
    return (
        <>
            {/* @ts-ignore */}
            <md-outlined-button onClick={p.onClick} class={p?.disabled ? 'blured' : ''}>
                {
                    p?.text?.length ?
                        <p style={{padding: '0 15px'}} className={p?.innerClassName ?? ''}>
                            {p.text}
                        </p> :
                        <div style={{padding: '0 15px'}} className={p?.innerClassName ?? ''}>
                            {p.children}
                        </div>
                }
                {/* @ts-ignore */}
            </md-outlined-button>
        </>
    );
};

export default M3OutlinedButton;