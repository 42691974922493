import React, {useEffect, useRef, useState} from 'react';
import AnimeElement from "./AnimeElement";
import {Anime} from "../types";
import {cacheableAxios} from "../funcs/cacheableAxios";
import {BACKEND} from "../consts";
import Loader from "./Loader";
import ChangeViewDropBox from "./ChangeViewDropBox";
import {getAnimeIds} from "../api/common";

interface Props {
    ids: number[],

    limit: number,

    pagination: boolean,

    paramsElements?: any,

    hideTab?: boolean,
    blockStyles?: any
}

const AnimeElementIdsBlock = (props: Props) => {
    let forLoadDiv = useRef<HTMLDivElement>(null)
    let [isLoading, setIsLoading] = useState(false)

    let [animes, setAnimes] = useState<Anime[]>([])

    let [page, setPage] = useState(0)

    let [viewMode, setViewMode] = useState<'cards' | 'column'>(
        localStorage.getItem('list_view_mode') === 'cards' ? 'cards' : 'column'
    )

    useEffect(() => {
        if (!props.pagination) {
            (async () => {
                let ids = props.ids

                let animeList = await getAnimeIds(ids.join(','));

                if (!animeList?.length) return;
                setAnimes(animeList)
            })()
        } else {
            fetchData()
        }
    }, [props.ids])

    const fetchData = async () => {
        setIsLoading(true)
        let ids = props.ids.slice(page * props.limit, page * props.limit + props.limit)
        if (ids.length === 0) {
            setIsLoading(false)
            return
        }

        let animeList = await getAnimeIds(ids.join(','))

        if (!animeList?.length) {
            setIsLoading(false)
            return
        }

        setAnimes([...animes, ...animeList])
        setPage(page + 1)
        setIsLoading(false)
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
            fetchData()
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        if (props.pagination) {
            observer = new IntersectionObserver(handleIntersection, {
                rootMargin: '500px', // Область видимости - верх страницы
                threshold: 0.1,    // Порог видимости
            });

            if (forLoadDiv.current) {
                observer.observe(forLoadDiv.current);
            }
        }

        return () => {

            if (forLoadDiv.current && props.pagination && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <>
            {
                !props.hideTab &&
                <div className="animes_list_block_params">
                    <ChangeViewDropBox selected={viewMode} setSelected={setViewMode}/>
                    {
                        props?.paramsElements
                    }
                </div>
            }


            {
                (isLoading && animes?.length === 0) ?
                    <div className={"ccp"}>
                        <Loader/>
                    </div> :
                    <div className={viewMode === 'cards' ? "animes_list_block cards_mode" : "animes_list_block"} style={{
                        ...props.blockStyles
                    }}>

                        {
                            animes.map(el => <AnimeElement viewMode={viewMode} anime={el} key={el.id}/>)
                        }

                        <div ref={forLoadDiv}></div>
                    </div>
            }
        </>
    );
};

export default AnimeElementIdsBlock;