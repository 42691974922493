import React from 'react';

export interface MaterialButtonProps {
    text?: string,
    onClick: () => void,
    children?: React.ReactNode,
    innerClassName?: string,
    rootClassName?: string,
    disabled?: boolean
}

const M3FilledTonalButton = (p: MaterialButtonProps) => {
    return (
        <>
            {/* @ts-ignore */}
            <md-filled-tonal-button onClick={p.onClick} class={p?.rootClassName ?? ''}>
                {
                    p?.text?.length ?
                        <p style={{padding: '0 15px'}} className={p?.innerClassName ?? ''}>
                            {p.text}
                        </p> :
                        <div style={{padding: '0 15px'}} className={p?.innerClassName ?? ''}>
                            {p.children}
                        </div>
                }

                {/* @ts-ignore */}
            </md-filled-tonal-button>
        </>
    );
};

export default M3FilledTonalButton;