import React, {Ref, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectHubId, selectHubMessages} from "../../lib/redux";
import {distinct} from "../../funcs/anime";

interface Props {
    ws: React.MutableRefObject<WebSocket | undefined>
}

const W2ChatModule = (p: Props) => {
    let hubId = useSelector(selectHubId)
    let hubMessages = useSelector(selectHubMessages)

    let [value, setValue] = useState('')

    async function sendChatMessage(text: string) {
        if (!p?.ws?.current || !text.trim().length) return;

        if (p.ws.current?.readyState !== 1) console.error('errws', p.ws.current?.readyState)

        p.ws.current.send(JSON.stringify({
            "key": "send_chat_message",
            "value": {
                hub_key: hubId,
                text: text.trim()
            }
        }))
        setValue('')
    }

    return (
        <div className="w2_chat_module ws_right_module">
            <div className="input">
                <input type="text" value={value} onChange={e => setValue(e.target.value)}/>

                <div className="w2_input_buttons">
                    <div className="w2_input_button" onClick={() => sendChatMessage(value)}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </div>
                </div>

            </div>

            {
                distinct(hubMessages.map(v => v.ID)).map(msg =>
                    <div className="w2_chat_module_message">
                        <p>{hubMessages.find(v => v.ID === msg)?.user_name}</p>
                        <p>{hubMessages.find(v => v.ID === msg)?.text}</p>
                    </div>)
            }
        </div>
    );
};

export default W2ChatModule;