import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import AnimeElementIdsBlock from "../../AnimeElementIdsBlock";
import {createDate} from "../../../funcs/time";
import {Anime} from "../../../types";
import ManageFolderDropBox from "../../ManageFolderDropBox";
import {proxyImg} from "../../../funcs/poster";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {getAnimeIds, getFolderItems} from "../../../api/common";

const MyFullFolder = () => {
    let nav = useNavigate()
    let params = useParams()
    let [searchParams] = useSearchParams()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [items, setItems] = useState<number[]>([])
    let [animes, setAnimes] = useState<Anime[]>([])

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }

            let itemsS = await getFolderItems(encodeURIComponent(params?.title ?? ''))
            itemsS = itemsS.sort((a: any, b: any) => {
                return createDate(b?.createdDate) - createDate(a?.createdDate)
            }).map((e: any) => e.releaseId)
            setItems(itemsS)


            if (itemsS.length === 0) return;

            let animesS = await getAnimeIds(itemsS.slice(0, 4).join(','))
            setAnimes(animesS)
        })()
    }, [])

    return (
        <>
            <AutoFadeTopBar title={params?.title ?? ''}/>
            {/*<div className="select_page_header" style={{borderBottom: 'none'}}>*/}
            {/*    <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {*/}
            {/*        nav(-1)*/}
            {/*    }}/>*/}
            {/*    /!*<p>{params.title}</p>*!/*/}

            {/*    <p></p>*/}
            {/*</div>*/}

            <div className="my_full_folder_folder" >
                <div className="my_full_folder_folder_image_blured" style={{backgroundImage: `url('${proxyImg(animes[0]?.id, animes[0]?.poster)}')`}}></div>
                <div className="my_full_folder_folder_image_block">
                    <div className="my_full_folder_folder_image_block_img" style={{backgroundImage: `url('${proxyImg(animes[0]?.id, animes[0]?.poster)}')`}}></div>
                    <div className="my_full_folder_folder_image_block_img" style={{backgroundImage: `url('${proxyImg(animes[1]?.id, animes[1]?.poster)}')`}}></div>
                    <div className="my_full_folder_folder_image_block_img" style={{backgroundImage: `url('${proxyImg(animes[2]?.id, animes[2]?.poster)}')`}}></div>
                    <div className="my_full_folder_folder_image_block_img" style={{backgroundImage: `url('${proxyImg(animes[3]?.id, animes[3]?.poster)}')`}}></div>
                </div>

                <div className="my_full_folder_folder_info_block">
                    <div className="my_full_folder_folder_info_block_title">{params.title}</div>
                    <p className="my_full_folder_folder_info_block_desc">{items.length} элементов</p>
                </div>
            </div>

            <div className="select_page_content" style={{

                flexDirection: 'column'
            }}>
                <AnimeElementIdsBlock ids={items} limit={10} pagination={true} paramsElements={<>
                    <ManageFolderDropBox id={searchParams.get('id') ?? -1} title={params?.title ?? ''}/>
                </>}/>
            </div>
        </>
    );
};

export default MyFullFolder;