import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import axios from "../../../core/axios";
import Error from "../../Error";
import {calcWithSale} from "../../../funcs/numbers";
import AniTypeCoin from "../../assets/AniTypeCoin";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../../../lib/redux";
import PurchaseByCoins from "../../PurchaseByCoins";

const UserMenuBetaPage = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let [loading, setLoading] = useState(false)

    let userInfo = useSelector(selectUserInfo)

    let [buyDialogOpen, setBuyDialogOpen] = useState(false)

    let [info, setInfo] = useState<any>()

    let [email, setEmail] = useState('')

    let [err, setErr] = useState('')

    useEffect(() => {
        (async () => {
            setLoading(true)
            let info = await axios({
                url: '/reports/bt'
            }).then(r => r.data).catch(e => null)
            setInfo(info)

            setLoading(false)
        })()
    }, [])

    const reg = () => {
        axios({
            url: '/reports/bt',
            method: 'post',
            data: {
                email
            }
        })
            .then(r => {
                setInfo(r.data)
            })
            .catch(e => {
                if (typeof e.response.data === 'string') {
                    setErr(JSON.stringify(e.response.data))
                } else {
                    setErr(JSON.stringify(e.response.data?.[0]))
                }
            })
    }

    async function buyPass() {
        axios({
            url: 'reports/bt/buy',
        })
            .then(r => {
                setErr('Успех!')
            })
            .catch(e => {
                let d = e?.response?.data;
                if (typeof d?.message === 'string') {
                    setErr(d?.message)
                } else if (typeof d?.message?.[0] === 'string') {
                    setErr(d?.message?.[0])
                }
            })
    }

    return (
        <>
            <AutoFadeTopBar title={`Регистрация на бета-тест`} alwaysShown={true}/>

            <Error message={err} setMessage={setErr}/>

            <div className="select_page_content spc">
                <div className="beta_test_img" style={{
                    backgroundImage: `url('https://s3.timeweb.com/24399392-anitype-videos/images/betatest1.png')`
                }}></div>

                <PurchaseByCoins title={'BETA-TEST PASS'}
                                 desc={`Вы получите доступ к бета-тесту приложения, не дожидаясь новой волны добавления участников, ${userInfo?.sub ? calcWithSale(199, 0.33) : 199} AC`}
                                 price={userInfo?.sub ? calcWithSale(199, 0.33) : 199} userInfo={userInfo}
                                 show={buyDialogOpen} setShow={setBuyDialogOpen} buyActionCb={buyPass}/>

                <>
                    {
                        !loading && <>

                            {info ?
                                <p style={{
                                    marginTop: 20,
                                    color: '#6c9d38',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    width: '100%'
                                }}>
                                    Вы зарегистрированы на бета-тест.
                                    Ожидайте, пока ваша заявка будет принята.
                                </p> : <p style={{
                                    marginTop: 20,
                                    color: '#c95252',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    width: '100%'
                                }}>
                                    Вы не зарегистрированы на бета-тест.
                                </p>}

                            {
                                info && !info?.accepted && <>
                                    <p style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                        color: '#b9a3a3',
                                        fontSize: 13,
                                        fontWeight: 500,
                                        width: '100%',
                                    }}>
                                        Заявки на бета-тест принимаются с каждым новым обновлением.
                                        Новое обновление бета-версии приложения происходит примерно каждую 1-2 неделю.
                                        Принимаются не все заявки, а определенное кол-во случайно выбранных.
                                        Вы можете пропустить этап принятия заявки, и получить доступ сейчас, с помощью BETA-TEST PASS.
                                    </p>

                                    <button className={'w_button sb to'} onClick={() => setBuyDialogOpen(true)}>
                                <span style={{color: '#111', fontWeight: 600}}>
                                    {
                                        userInfo?.sub ?
                                            <>
                                                <span
                                                    className={"old_price"}>{199}</span>
                                                <span
                                                    className={"new_price"}>{calcWithSale(199, 0.33)} (-33% premium)</span>
                                            </> :
                                            (199)
                                    }
                                </span>
                                        <div className={'w_button_coin'}>
                                            <AniTypeCoin size={15}/>
                                        </div>
                                    </button>
                                </>
                            }

                            {
                                info?.accepted &&
                                <p style={{
                                    marginTop: 20,
                                    color: '#6c9d38',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    width: '100%'
                                }}>
                                    <span>
                                        Вашу заявку на бета-тест приняли!
                                    Ожидайте, когда Вас добавят в бета-тестеров Google Play. (это займет не больше суток)
                                    После, скачайте приложение, и авторизуйтесь в ЭТОМ аккаунте.
                                    </span>

                                    <button className={"beta_test_button"} onClick={() => {
                                        window.location.href = 'https://t.me/anitypenews'
                                    }}>Следите за новостями в телеграм канале (нажмите)
                                    </button>
                                    <button className={"beta_test_button"} onClick={() => {
                                        window.location.href = 'https://t.me/anitypeapk'
                                    }}>
                                        Рекомендуем скачивать именно из Google Play, но
                                        если вы не хотите ждать, то скачайте APK из этого канала (нажмите)
                                    </button>
                                </p>
                            }

                            {
                                !info && <>
                                    <p style={{marginTop: 20, marginBottom: 10, fontSize: 12}}>Введите email, на которое вам
                                        придет приглашение. Указывайте email вашего Google аккаунта, для
                                        доступа к бета-тесту в Google Play.</p>
                                    <input type="email" className={"beta_test_input"}
                                           placeholder={"Email"} value={email} onChange={e => setEmail(e.target.value)}/>

                                    <button className="anime_fast_button" style={{
                                        width: 'fit-content'
                                    }} onClick={reg}>
                                        Зарегистрироваться
                                    </button>
                                </>
                            }
                        </>
                    }
                </>
            </div>
        </>
    );
};

export default UserMenuBetaPage;