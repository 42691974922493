import React, {useEffect, useState} from 'react';
import {Anime} from "../../../types";
import {BACKEND} from "../../../consts";
import {proxyImg} from "../../../funcs/poster";
import {getEpisodes, getRateColorText} from "../../../funcs/anime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import Home2Blocks from "../../home-components/Home2Blocks";
import {useNavigate} from "react-router-dom";
import Loader from "../../Loader";
import axios from "../../../core/axios";
import {useDispatch, useSelector} from "react-redux";
import {dataSlice, selectHomeAnime} from "../../../lib/redux";
import Home2Footer from "../../home-components/Home2Footer";

const Home2Page = () => {
    let [playType, setPlayType] = useState<'video' | 'poster'>('video')

    let [loading, setLoading] = useState(false)

    let nav = useNavigate()

    let anime = useSelector(selectHomeAnime)
    let dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            if (loading || anime) return;

            setLoading(true)

            let response = await axios({
                url: 'anime/filter?size=10&page=0&uhd=true&in_rotate=true',
                timeout: 10000
            }).then(r => {
                let rand = Math.floor(Math.random() * r.data.length);
                return r.data[rand]
            }).catch(e => null)

            dispatch(dataSlice.actions.setHomeAnime(response))

            setLoading(false)
        })()
    }, []);

    return (
        <div className={"home_page"}>
            {
                loading ? <div className="home_page_trailer_block home_page_trailer_block_loading">
                    <Loader/>
                </div> : <div className="home_page_trailer_block">
                    <div className="home_page_trailer_info_block">
                        <div className="home_page_trailer_info">
                            <p className="title golos">{anime?.titles.russian}</p>
                            <p className="info golos">
                            <span style={{
                                color: getRateColorText(anime?.score ?? 0)
                            }}>{
                                (+(anime?.score ?? 0)).toFixed(2)
                            }</span>
                                <span className="separator">|</span>
                                <span>{getEpisodes(anime ?? undefined)} эпизодов</span>
                                <span className="separator">|</span>
                                <span>{anime?.genres.map(e => e.name).join(', ')}</span>
                            </p>
                            <div className="buttons">
                                <button className="trailer_anime_watch_button" onClick={() => nav(`/anime/${anime?.id}`)}>
                                    <FontAwesomeIcon icon={faPlay} />
                                    <p style={{fontWeight: 500}} className={"golos"}>Доступно в 4К!</p>
                                </button>
                                {/*<button className="trailer_anime_watch_fav circle">*/}
                                {/*    <FontAwesomeIcon icon={faBookmark} />*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>

                    <div className="home_page_trailer_shadow"></div>

                    <div className="home_page_trailer_block_poster" style={{
                        backgroundImage: `url('${proxyImg(anime?.id, anime?.poster)}')`
                    }}></div>

                    {/*{*/}
                    {/*    false &&*/}
                    {/*    <div className={playType === 'video' ? "home_page_trailer_video" : "home_page_trailer_video hidden"}>*/}
                    {/*        <video className="home_page_trailer_video_video" autoPlay={true} muted={true} onEnded={() => {*/}
                    {/*            setPlayType('poster')*/}
                    {/*        }} src={`https://s3.timeweb.com/24399392-anitype-videos/op_rotate/${anime?.id}/${anime?.id}.webm`}>*/}
                    {/*        </video>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            }

            <Home2Blocks/>

            <Home2Footer/>
        </div>
    );
};

export default Home2Page;