import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {Anime} from "../../../types";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {BACKEND} from "../../../consts";
import Loader from "../../Loader";
import AnimeElement from "../../AnimeElement";

const StreamsCreateSelectAnimePage = () => {
    let nav = useNavigate()

    let [keyword, setKeyword] = useState('')

    let [animes, setAnimes] = useState<Anime[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let response = await cacheableAxios({
                url: BACKEND + `anime/search?keyword=${encodeURIComponent(keyword)}&limit=10&page=0`,
                method: 'get'
            }, 60 * 60)
            setAnimes(response)
            setIsLoading(false)
        })()
    }, [keyword])

    return (
        <>
            <div className="select_page_header">
                <FontAwesomeIcon icon={faArrowLeft} className={"to"} onClick={() => {
                    nav(-1)
                }}/>
                <p>{"Создание стрима"}</p>

                <p></p>
            </div>

            <div className="select_page_content spc">
                <input type="text" placeholder={"Начните писать..."} value={keyword}
                       onChange={e => setKeyword(e.target.value)}/>

                <p className={"select_page_content_warning"} style={{marginTop: 20}}>Найдите нужное аниме по названию</p>

                {
                    isLoading ? <div className="ccp">
                            <Loader/>
                        </div> :
                        <>
                            {
                                animes && animes?.length > 0 &&
                                animes.map(el => <AnimeElement anime={el} key={el.id} prevent={true} fun={(id: number) => {
                                    localStorage.setItem('new_stream_anime', id + '')
                                    nav(-1)
                                }}/>)
                            }
                        </>
                }
            </div>
        </>
    );
};

export default StreamsCreateSelectAnimePage;