import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons/faNewspaper";
import Toggler from "../../Toggler";
import '../../../styles/settings.css'
import {useCookies} from "react-cookie";
import {BACKEND} from "../../../consts";
import {getSet} from "../../../funcs/users";
import {distinct} from "../../../funcs/anime";
import {faFaceSmileWink} from "@fortawesome/free-solid-svg-icons/faFaceSmileWink";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import axios from "../../../core/axios";
import {getKodikTranslations} from "../../../api/kodik";
import UserSettingsToggler from "../../UserSettingsToggler";
import {faComment, faCommentDots, faComments} from "@fortawesome/free-solid-svg-icons";

const UserMenuSettingsNotificationsPage = () => {
    let nav = useNavigate()
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [settings, setSettings] = useState([])

    let [selectedTranslations, setSelectedTranslations] = useState<string[]>([])
    let [allTranslations, setAllTranslations] = useState<string[]>([])

    let [tg, setTg] = useState('')

    useEffect(() => {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        getKodikTranslations()
            .then(r => {
                setAllTranslations(r.map((e: any) => e.title))
            })
            .catch(ignored => {})

        axios({
            url: 'app2/users/settings'
        })
            .then(r => {
                setSettings(r.data)

                setSelectedTranslations(getSet(r.data, 'eps_nots_translations', '').split(';').filter(v => v.length > 0))
                setTg(getSet(r.data, 'tg', ''))
            })
    }, []);

    return (
        <>

            <AutoFadeTopBar title={`Настройки уведомлений`} alwaysShown={true}/>

            <div className="select_page_content spc">

                <UserSettingsToggler settings={settings} title={'О повышении уровня'} keyT={'level_nots'}>
                    <FontAwesomeIcon icon={faArrowTrendUp}/>
                </UserSettingsToggler>

                <UserSettingsToggler settings={settings} title={'Об ответах на комментарии'} keyT={'comment_answer_nots'}>
                    <FontAwesomeIcon icon={faComments} />
                </UserSettingsToggler>

                <UserSettingsToggler settings={settings} title={'О комментариях в профиле'} keyT={'comment_profile_nots'}>
                    <FontAwesomeIcon icon={faComment} />
                </UserSettingsToggler>

                <UserSettingsToggler settings={settings} title={'О комментариях в подборках'} keyT={'comment_selection_nots'}>
                    <FontAwesomeIcon icon={faCommentDots} />
                </UserSettingsToggler>

                <UserSettingsToggler settings={settings} title={'О новых эпизодах'} keyT={'eps_nots'}>
                    <FontAwesomeIcon icon={faNewspaper}/>
                </UserSettingsToggler>

                <div className="hint">
                    <div className="hint_svg">
                        <FontAwesomeIcon icon={faFaceSmileWink}/>
                    </div>
                    <p>Вы будете получать уведомления о новых эпизодах только если у вас есть AniType Premium.</p>
                </div>

                <div className="select_items_block">
                    <p className="select_items_block_title">Дублирование уведомлений в Telegram</p>
                    <p className="select_items_block_desc">Если включено, Вы будете получать уведомления в Telegram</p>

                    {tg ?
                        <button className="select_items_button to" onClick={() => {
                            axios({
                                method: 'put',
                                url: `app2/users/settings?key=${'tg'}&value=`,
                            })
                                .then(r => {
                                    setTg('')
                                })
                                .catch(ignored => {
                                })

                        }}>Выключить ({tg})</button> :
                        <button className="select_items_button to" onClick={() => {
                            window.location.href = `https://t.me/anitypebot?start=`
                        }}>Включить</button>
                    }

                </div>

                <div className="select_items_block">
                    <p className="select_items_block_title">Выбор озвучек</p>
                    <p className="select_items_block_desc">Если указано, Вы будете получать уведомления о новых эпизодах
                        только этих озвучек</p>
                    <div className="select_items_block_input">
                        {
                            selectedTranslations.map((el: string) =>
                                <p onClick={() => {
                                    let l = [...selectedTranslations.filter(v => v !== el)]
                                    setSelectedTranslations(l)

                                    axios({
                                        method: 'put',
                                        url: `app2/users/settings?key=${'eps_nots_translations'}&value=${l.join(';')}`,
                                    })
                                }} key={el + 'st'} className="select_items_block_input_el">{el}
                                    <span className="minus">-</span>
                                </p>)
                        }
                    </div>

                    <div className="select_items_block_vars">
                        {
                            [...allTranslations.filter(v => !selectedTranslations.includes(v))].map((el: any) =>
                                <p onClick={() => {
                                    let l = distinct([...selectedTranslations, el])
                                    setSelectedTranslations(l)

                                    axios({
                                        method: 'put',
                                        url: `app2/users/settings?key=${'eps_nots_translations'}&value=${l.join(';')}`,
                                    })
                                }} key={el + 'translationall'} style={{marginBottom: 10}}
                                   className="select_items_block_input_el">{el}
                                    <span className="plus">+</span></p>)
                        }
                    </div>
                </div>

                {/*<div className="select_items_block">*/}
                {/*    <p className="select_items_block_title">Выбор папок</p>*/}
                {/*    <p className="select_items_block_desc">Если указано, Вы будете получать уведомления только о тех аниме, которые находятся в этих папок</p>*/}
                {/*    <div className="select_items_block_input">*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="minus">-</span>*/}
                {/*        </p>*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="minus">-</span></p>*/}
                {/*    </div>*/}

                {/*    <div className="select_items_block_vars">*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="plus">+</span></p>*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="plus">+</span></p>*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="plus">+</span></p>*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="plus">+</span></p>*/}
                {/*        <p className="select_items_block_input_el">AniLibria.TV*/}
                {/*            <span className="plus">+</span></p>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </>
    );
};

export default UserMenuSettingsNotificationsPage;