import React from 'react';
import '../styles/anime_element.css'
import {Anime} from "../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import Dot from "./ui/Dot";
import {useNavigate} from "react-router-dom";
import {endKindToRu, engStatusToRu, getEpisodes, removeSquareBrackets} from "../funcs/anime";
import {proxyImg} from "../funcs/poster";

interface Props {
    anime: Anime,
    viewMode?: 'column' | 'cards'

    prevent?: boolean,
    fun?: any
}

const AnimeElement = ({anime, viewMode, prevent, fun}: Props) => {
    let nav = useNavigate()
    if (!viewMode) {
        viewMode = localStorage.getItem('list_view_mode') === 'cards' ? 'cards' : 'column';
    }

    return (
        <div className={viewMode === 'cards' ? "anime_el cards_mode to" : "anime_el to"} onClick={() => {
            if (!prevent) {
                nav(`/anime/${anime.id}`)
            } else {
                fun(anime.id)
            }
        }}>
            {
                viewMode === 'cards' && <>
                    <div className="anime_el_poster_extend">
                        <div className="anime_el_poster_extend_img" style={{backgroundImage: `url('${proxyImg(anime.id, anime.poster)}')`}}></div>
                    </div>
                </>
            }


            <div className={viewMode === 'cards' ? "anime_el_poster_cont cards_mode" : "anime_el_poster_cont"}>
                <div className="anime_el_poster_cont_blured" style={{backgroundImage: `url('${proxyImg(anime.id, anime.poster)}')`}}></div>
                {
                    anime.score > 7 &&
                    <div className={viewMode === 'cards' ? "anime_el_poster_rate cards_mode" : "anime_el_poster_rate"}>{anime?.score?.toFixed(1) ?? ""}</div>
                }
                <div className={viewMode === 'cards' ? "anime_el_poster cards_mode" : "anime_el_poster"} style={{backgroundImage: `url('${proxyImg(anime.id, anime.poster)}')`}}></div>
            </div>

            <div className={viewMode === 'cards' ? "anime_el_info cards_mode" : "anime_el_info"}>
                <p className={viewMode === 'cards' ? "anime_el_info_title cards_mode" : "anime_el_info_title"}>{anime.titles.russian}</p>
                {
                    viewMode === 'column' &&
                    <p className="anime_el_info_info">
                        {/*<p>*/}
                        {/*    {*/}
                        {/*        anime && anime.genres && anime.genres?.length && anime.genres?.length > 0 &&*/}
                        {/*        anime?.genres?.map(e => e.name).join(', ')*/}
                        {/*    }*/}
                        {/*</p>*/}
                        <span className="no_wrap">{getEpisodes(anime)} эп. <Dot/></span>
                        <span className="no_wrap">{anime.score}<FontAwesomeIcon icon={faStar} className={"anime_el_info_info_star"} /></span>

                        <div className={"anime_el_info_info_tags"}>
                            <div className="anime_el_info_info_tag">
                                {endKindToRu(anime.kind)}
                            </div>
                            {
                                engStatusToRu(anime.status) != "Неизвестно" &&
                                <div className="anime_el_info_info_tag">{engStatusToRu(anime.status)}</div>
                            }
                            {
                                anime.uhd &&
                                <div className="anime_el_info_info_tag">Доступно в 4К</div>
                            }
                        </div>
                    </p>
                }

            </div>


        </div>
    );
};

export default AnimeElement;