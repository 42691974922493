import axios from "axios";
import {BACKEND} from "../consts";

axios.defaults.baseURL = BACKEND

axios.interceptors.request.use(async (config) => {
    try {
        if (document?.cookie) {
            let cookies = document.cookie.split('; ');

            let accessCookie = cookies.find(v => v.startsWith('access'));
            let accessToken = accessCookie ? accessCookie.split('=').pop() : undefined;

            let refreshCookie = cookies.find(v => v.startsWith('refresh'));
            let refreshToken = refreshCookie ? refreshCookie.split('=').pop() : undefined;

            let refreshTimeCookie = cookies.find(v => v.startsWith('refreshTime'));
            let refreshTimeToken = refreshTimeCookie ? refreshTimeCookie.split('=').pop() : undefined;

            if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

            if (refreshToken && !refreshTimeToken) { // getting refresh token
                let tokensResponse = await fetch(BACKEND + 'users/auth/refresh', {
                    method: 'post',
                    body: JSON.stringify({refreshToken: refreshToken}),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                if (tokensResponse.status === 200 ||
                    tokensResponse.status === 201) {
                    let tokens = await tokensResponse.json();

                    if (tokens && tokens?.accessToken && tokens?.refreshToken) {
                        document.cookie = `access=${tokens.accessToken}; domain=.anitype.fun; max-age=${86400 / 2}`
                        document.cookie = `refresh=${tokens.refreshToken}; domain=.anitype.fun; max-age=${86400 * 7}`
                        document.cookie = `refreshTime=${Date.now()}; domain=.anitype.fun; max-age=${86400 * 6}`

                        document.cookie = `access=${tokens.accessToken}; domain=.anitype.ru; max-age=${86400 / 2}`
                        document.cookie = `refresh=${tokens.refreshToken}; domain=.anitype.ru; max-age=${86400 * 7}`
                        document.cookie = `refreshTime=${Date.now()}; domain=.anitype.ru; max-age=${86400 * 6}`
                        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
                    }
                }
            }

            if (!accessToken && refreshToken) { // getting access token
                let tokensResponse = await fetch(BACKEND + 'users/auth/access', {
                    method: 'post',
                    body: JSON.stringify({refreshToken: refreshToken}),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                if (tokensResponse.status === 200 ||
                    tokensResponse.status === 201) {
                    let tokens = await tokensResponse.json();

                    if (tokens && tokens?.accessToken) {
                        document.cookie = `access=${tokens.accessToken}; domain=.anitype.fun; max-age=${86400 / 2}`

                        document.cookie = `access=${tokens.accessToken}; domain=.anitype.ru; max-age=${86400 / 2}`
                        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
                    }
                }
            }
        }
    } catch (ignored) {
        console.error(ignored)
    }

    return config;
});

export default axios;