import React, {useState} from 'react';
import {Anime} from "../../types";
import AnimeEpisodesBlock from "./AnimeEpisodesBlock";
import {faArrowDownShortWide} from "@fortawesome/free-solid-svg-icons/faArrowDownShortWide";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp19} from "@fortawesome/free-solid-svg-icons/faArrowUp19";
import {faArrowDownAZ} from "@fortawesome/free-solid-svg-icons/faArrowDownAZ";
import {faArrowDownWideShort} from "@fortawesome/free-solid-svg-icons/faArrowDownWideShort";
import {useNavigate, useParams} from "react-router-dom";
import AnimeElementIdsBlock from "../AnimeElementIdsBlock";
import {distinct} from "../../funcs/anime";

type SelectedType = 'episodes' | 'comments' | 'similar' | 'related';

const AnimeMultiBlock = ({anime, serialPage}: {anime: Anime | undefined, serialPage: boolean}) => {

    let [selected, setSelected] = useState<SelectedType>(
        !(window?.location && window?.location.hostname.includes('.ru')) ? 'episodes' : 'similar')

    let [order, setOrder] = useState<'asc' | 'desc'>('asc')

    // time fix
    let nav = useNavigate()
    let params = useParams()

    return (
        <>
            <div className="anime_multi_blocks">
                <div className="anime_multi_blocks_changer">
                    <div className={"anime_multi_blocks_changer_buttons"}>
                        {
                            !(window?.location?.hostname?.includes('.ru')) &&
                            <button className={selected === 'episodes' ? "active golos" : "golos"}
                                    onClick={() => setSelected('episodes')}>
                                <span>Эпизоды</span>
                                {
                                    selected === 'episodes' &&
                                    <div className="active_line"></div>
                                }
                            </button>
                        }

                        <button className={selected === 'similar' ? "active golos" : "golos"} onClick={() => {
                            setSelected('similar')

                        }}>
                            <span>Похожее</span>
                            {
                                selected === 'similar' &&
                                <div className="active_line"></div>
                            }
                        </button>

                        <button className={selected === 'related' ? "active golos" : "golos"} onClick={() => {
                            setSelected('related')

                        }}>
                            <span>Связанное</span>
                            {
                                selected === 'related' &&
                                <div className="active_line"></div>
                            }
                        </button>
                    </div>

                    <button onClick={() => {
                        if (order === 'asc') {
                            setOrder('desc')
                        } else setOrder('asc')
                    }}>
                        <FontAwesomeIcon icon={order === 'asc' ? faArrowDownShortWide : faArrowDownWideShort}/>
                    </button>
                </div>

                <div className="anime_multi_blocks_body" style={{
                    paddingTop: 10,
                    paddingBottom: 150
                }}>
                    {
                        selected === 'episodes' && <AnimeEpisodesBlock anime={anime} reverse={order === 'desc'} serialPage={serialPage}/>
                    }
                    {
                        selected === 'similar' &&
                        <AnimeElementIdsBlock ids={distinct(anime?.similar.map(e => e.to_anime_id) ?? [])}
                                              limit={10} pagination={false} hideTab={true} blockStyles={{
                                                  padding: '0px 30px 0 0'
                        }}/>
                    }
                    {
                        selected === 'related' &&
                        <AnimeElementIdsBlock ids={distinct(anime?.relations.map(e => e.to_anime_id) ?? [])}
                                              limit={10} pagination={false} hideTab={true} blockStyles={{
                            padding: '0px 30px 0 0'
                        }}/>
                    }
                </div>
            </div>
        </>
    );
};

export default AnimeMultiBlock;