import React, {useEffect, useState} from 'react';
import AdsBlock from "./AdsBlock";
import {useCookies} from "react-cookie";
import axios from "../../core/axios";

export interface AdProps {
    className?: string,
    renderTo: string,
    blockId: string,
    adProps?: any
}

const AdBlock = ({className, renderTo, blockId, adProps}: AdProps) => {
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let [sub, setSub] = useState(false)

    useEffect(() => {
        axios({
            url: 'users/me',
        })
            .then(r => {
                setSub(r.data.sub)
            })
            .catch(ignored => {
            })
    }, [cookies.access])

    return (
        <>
            {
                !sub && <>
                    <div className={className}>
                        <AdsBlock renderTo={renderTo} blockId={blockId} adProps={adProps}/>
                    </div>
                </>
            }
        </>

    );
};

export default AdBlock;