import {dailyCleanCache} from "./dailyCleanCache";
import axios from "../core/axios";

export async function cacheableAxios(axiosOptions: any, expTime: number): Promise<any> {
    dailyCleanCache()

    let cacheName = encodeURIComponent(axiosOptions.url)
    let unix = Date.now() / 1000

    let cacheExpTime = +(localStorage.getItem(cacheName + '_exp') ?? 0)
    if (Math.abs(cacheExpTime - unix) > expTime) {
        let newResult = await axios(axiosOptions)
            .then(r => r.data)
            .catch(ignored => null)


        if (newResult) {
            localStorage.setItem(cacheName + '_exp', (unix + expTime) + '')
            localStorage.setItem(cacheName + '_cache', JSON.stringify(newResult))
        }

        return newResult
    }

    let result = null;
    try {
        result = JSON.parse(localStorage.getItem(cacheName + '_cache') ?? '')
        return result
    } catch (ignored) {}

    return result
}