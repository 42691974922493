import React, {useEffect, useState} from 'react';
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import AnimeFixedBlock from "../../anime-components/AnimeFixedBlock";
import {useNavigate, useParams} from "react-router-dom";
import {EpisodeInfo, KodikFullData} from "../../../types";
import {distinct} from "../../../funcs/anime";
import {getKodikData} from "../../../api/kodik";
import {setUserPickedTranslation} from "../../../funcs/users";
import {getEpisodesInfo} from "../../../api/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

const AnimeSelectTranslationPage = () => {
    let nav = useNavigate()
    let params = useParams()

    let [loading, setLoading] = useState(false)

    let [data, setData] = useState<EpisodeInfo[]>()

    useEffect(() => {
        (async () => {
            setLoading(true)
            setData(await getEpisodesInfo(+(params?.id ?? 0)))
            setLoading(false)
        })()
    }, []);

    return (
        <>
            <AutoFadeTopBar title={'Выбор озвучки'} alwaysShown={true}/>

            {
                loading ? <div className="ccp">
                    <Loader/>
                </div> : <>
                    <div className="select2_block">
                        {
                            distinct((data ?? []).sort((a, b) => {
                                let aCount = -999;
                                let bCount = -999;
                                if (a.translation?.this_anime_count && +a.translation?.this_anime_count > 0)
                                    aCount = +a.translation?.this_anime_count;

                                if (b.translation?.this_anime_count && +b.translation?.this_anime_count > 0)
                                    bCount = +b.translation?.this_anime_count;

                                if (aCount === bCount) return 0;
                                if (aCount > bCount) return -1;
                                return 1;
                            }).map(e => e.translation?.title))
                                .map(el =>
                            <div className="select2_block_el select2_block_el_lr" onClick={() => {
                                setUserPickedTranslation(+(params?.id ?? 0), el)
                                nav(`/anime/${params?.id}?translation=${el}`)
                            }}>
                                <div>
                                    <div className="select2_block_el_title">
                                        <span>{el}</span>

                                        {(data ?? []).find(v => v.translation?.title === el)?.translation?.has_uhd &&
                                            <div className="home2_anime_el_rate anime_info_translations_title_tag"
                                                 style={{
                                                     background: 'crimson'
                                                 }}>4K
                                            </div>}

                                    </div>
                                    <p className="select2_block_el_desc">{
                                        (data ?? []).filter(v => v.translation?.title === el)?.length
                                    } эп.</p>
                                </div>
                                <div className={"select2_block_el_watch_count_el"}>
                                    <p>{(data ?? []).find(v => v.translation?.title === el)?.translation?.this_anime_count}</p>
                                    <FontAwesomeIcon icon={faEye} />
                                </div>
                            </div>)
                        }
                    </div>
                </>
            }
        </>
    );
};

export default AnimeSelectTranslationPage;