import React, {useEffect, useRef, useState} from 'react';
import {useCookies} from "react-cookie";
import {useNavigate, useParams} from "react-router-dom";
import {cacheableAxios} from "../../../funcs/cacheableAxios";
import {BACKEND} from "../../../consts";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import Loader from "../../Loader";
import {Comment} from "../../../types";
import AnimeCommentEl from "../../anime-comments-components/AnimeComment";
import {sendCommentAsync} from "../../../api/comments";
import Error from "../../Error";
import TogglerButton from "../../TogglerButton";
import M3FilledTonalButton from "../../m3/M3FilledTonalButton";
import M3TextArea from "../../m3/M3TextArea";

const CommentTargetPage = () => {
    let forLoadDiv = useRef<HTMLDivElement>(null)

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let nav = useNavigate()
    let params = useParams()

    let [isLoading, setIsLoading] = useState(false)
    let [items, setItems] = useState<Comment[]>([])
    let [stop, setStop] = useState(false)
    let [page, setPage] = useState(0)

    let [newCommentText, setNewCommentText] = useState('')
    let [hasSpoiler, setHasSpoiler] = useState(false)

    let [err, setErr] = useState('')

    const fetchData = async () => {
        if (stop) {
            setIsLoading(false)
            return
        }

        let itemsS = await cacheableAxios({
            method: 'get',
            url: `comments/target/${params.target}${params.id}?page=${page}&size=10`
        }, 60 * 5)
            .then(r => r)
            .catch(ignored => [])
        setItems([...items, ...itemsS])
        if (itemsS.length === 0) {
            setStop(true)
        }
        setPage(page + 1)
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
            fetchData()
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '500px', // Область видимости - верх страницы
            threshold: 0.1,    // Порог видимости
        });

        if (forLoadDiv.current) {
            observer.observe(forLoadDiv.current);
        }

        return () => {
            if (forLoadDiv.current && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <>
            <Error message={err} setMessage={setErr}/>
            <div style={{padding: "15px 15px 20px 15px"}}>
                <AutoFadeTopBar title={'Комментарии'} alwaysShown={true}/>


                {
                    isLoading ? <div className="ccp">
                        <Loader/>
                    </div> : <>
                        <div className="anime_comments_block_comment_input">
                            <M3TextArea value={newCommentText} setValue={setNewCommentText} placeholder={'Напишите комментарий'}/>

                            <div style={{
                                width: '100%',
                                display: 'flex', justifyContent: 'space-between', marginTop: 5
                            }}>
                                <TogglerButton title={'Есть спойлер'} active={hasSpoiler} setActive={setHasSpoiler}/>

                                <M3FilledTonalButton text={'Отправить'} onClick={() => {
                                    (async () => {
                                        let text = newCommentText
                                        setNewCommentText('')
                                        let res = await sendCommentAsync(text, (params?.target ?? '') + (params?.id ?? ''),
                                            hasSpoiler ? 'spoiler' : '', '')
                                        if (typeof res === 'string') {
                                            setErr(res)
                                        } else {
                                            setItems([res, ...items])
                                        }
                                    })()
                                }}/>
                            </div>

                        </div>
                        {
                            items.filter(v => !v.tags.map(e => e.value).includes('no_moderated')).map(el => <>
                                <AnimeCommentEl key={el.id} el={el}/>
                            </>)
                        }
                        <div ref={forLoadDiv}></div>

                    </>
                }
            </div>
        </>
    );
};

export default CommentTargetPage;