import React, {useState} from 'react';

interface MaterialTextAreaProps {
    value: string
    setValue: (value: string) => void
    placeholder: string
}

const M3TextArea = (p: MaterialTextAreaProps) => {
    let [focused, setFocused] = useState(false)

    return (
        <>
            <div className={focused ? "anime_comments_block_comment_input_textarea focused" : "anime_comments_block_comment_input_textarea"}>
                <p className={(focused || p.value.length) ? "anime_comments_block_comment_input_textarea_ph focused" : "anime_comments_block_comment_input_textarea_ph"}>{p.placeholder}</p>
                <textarea className={"anime_comments_block_comment_input_textarea_ta"}
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                          placeholder={""}
                          rows={3}
                          value={p.value}
                          onChange={e => p.setValue(e.target.value)}></textarea>
            </div>
        </>
    );
};

export default M3TextArea;