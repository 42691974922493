
export async function dailyCleanCache() {
    for (const key in localStorage) {
        if (key.endsWith('_exp')) {
            let value = localStorage.getItem(key)
            let expTime = +(value ?? 0);

            let now = Date.now() / 1000;
            if (Math.abs(now - expTime) > 60 * 60 * 24) {
                localStorage.removeItem(key);

                let cacheItemName = key.split('_')[0] + '_cache';
                localStorage.removeItem(cacheItemName)
            }
        }
    }
}