import React, {useEffect, useRef, useState} from 'react';
import {Anime, Comment, Selection, UserInfo} from "../../types";
import Loader from "../Loader";
import {proxyImg} from "../../funcs/poster";
import {removeSquareBrackets} from "../../funcs/anime";
import {useNavigate} from "react-router-dom";
import UserAvatar from "../UserAvatar";
import UserEmoji from "../UserEmoji";
import {timeAgoText} from "../../funcs/time";
import AdBlock from "../yandex/AdBlock";
import {getAnimeIds, getSelections, getWatchlogsUserPopular} from "../../api/common";
import {faBookmark} from "@fortawesome/free-solid-svg-icons/faBookmark";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import {faFire} from "@fortawesome/free-solid-svg-icons/faFire";

const HomeList = () => {
    let nav = useNavigate()

    let forLoadDiv = useRef<HTMLDivElement>(null)
    let [loading, setLoading] = useState(false)

    let [list, setList] = useState<HomeListEl[]>([])

    let [animePage, setAnimePage] = useState(0)
    let [selectionsPage, setSelectionsPage] = useState(0)
    let [commentsPage, setCommentsPage] = useState(0)
    let [usersPage, setUsersPage] = useState(0)

    let [selectionsSort, setSelectionsSort] = useState<'createdAt' | 'saved'>('createdAt')

    useEffect(() => {
        setSelectionsPage(0)
        setList([...list.filter(v => v.type != 'Selection')])
    }, [selectionsSort]);

    async function loadMore() {
        if (loading) return;

        setLoading(true)

        let newData = []

        // let newAnime: HomeListEl[] = (await fetchPopularAnime(15)).map((el: Anime) => ({
        //     type: 'Anime',
        //     el
        // }))

        let newSelections: HomeListEl[] = (await fetchSelections(10, selectionsSort)).map((el: Selection) => ({
            type: 'Selection',
            el
        }))

        // let newComments: HomeListEl[] = (await fetchComments(4)).map((el: Comment) => ({
        //     type: 'AnimeComment',
        //     el
        // }))
        //
        // let newUsers: HomeListEl[] = (await fetchUsers(4)).map((el: UserInfo) => ({
        //     type: 'User',
        //     el
        // }))

        // newData = shuffle([...newAnime, ...newSelections, ...newComments, ...newUsers])
        newData = newSelections

        setList([...list, ...newData])

        setLoading(false)
    }

    async function fetchPopularAnime(limit: number) {
        let ids: number[] = await getWatchlogsUserPopular()

        ids = ids.slice(animePage * limit, animePage * limit + limit)

        let animeList = await getAnimeIds(ids.join(','))

        setAnimePage(animePage + 1)
        return animeList ?? []
    }

    async function fetchSelections(limit: number, order: 'createdAt' | 'saved') {
        let selectionsS = await getSelections(selectionsPage, limit, order)

        setSelectionsPage(selectionsPage + 1)
        return selectionsS ?? []
    }

    useEffect(() => {
        if (list.length === 0) {
            loadMore()
        }
    }, [])


    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !loading) {
            loadMore()
        }
    };
    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '500px', // Область видимости - верх страницы
            threshold: 0.1,    // Порог видимости
        });

        if (forLoadDiv.current) {
            observer.observe(forLoadDiv.current);
        }

        return () => {

            if (forLoadDiv.current && observer) {
                observer.unobserve(forLoadDiv.current);
            }
        };
    }, [handleIntersection]);

    return (
        <>
            <div style={{height: 70}}></div>

            {/*<div style={{height: 10}}></div>*/}

            <div className="home_list_el_buttons">
                <button className="anime_fast_button home_list_el_button" onClick={() => nav('/selection/new')}>
                    <FontAwesomeIcon icon={faSquarePlus}/>
                    <span>Создать подборку</span>
                </button>
                <button className="anime_fast_button home_list_el_button" onClick={() => {
                    if (selectionsSort === 'createdAt') {
                        setSelectionsSort('saved')
                    } else setSelectionsSort('createdAt')
                }}>
                    {
                        selectionsSort === 'createdAt' ? <>
                            <FontAwesomeIcon icon={faCalendarDays}/>
                            <span>Сначала новые</span>
                        </> : <>
                            <FontAwesomeIcon icon={faFire}/>
                            <span>Сначала популярные</span>
                        </>
                    }
                </button>
            </div>

            {
                list.map((el, index) => <>
                    {
                        (index % 5 == 0 && index > 1) &&
                        <AdBlock className={"home_list_el_cont_ab"} renderTo={`yandex_rtb_R-A-2987609-7-${index}`}
                                 blockId={"R-A-2987609-7"} adProps={{
                            pageNumber: index
                        }}/>
                    }

                    <div className={(function () {
                        if (el.type === 'Anime') return 'home_list_el_cont yellow'
                        if (el.type === 'Selection') return 'home_list_el_cont green'

                        return 'home_list_el_cont'
                    })()} key={el.type + el.el.id + el.type}>
                        <div className="home_list_el" style={{
                            overflow: el.type === 'Anime' ? 'hidden' : 'unset'
                        }}>
                            {
                                // if anime
                                el.type === "Anime" &&
                                <div className={"home_list_el_el home_list_el_anime"}
                                     onClick={() => nav(`/anime/${el?.el?.id}`)}>
                                    <div className="home_list_el_tag yellow">Популярное</div>

                                    <div className="home_list_el_anime_bg"
                                         style={{backgroundImage: `url('${proxyImg(+el?.el?.id, (el?.el as Anime | undefined)?.poster)}')`}}></div>
                                    <div className="home_list_el_anime_bg_grad"></div>

                                    <div className="home_list_el_anime_info">
                                        <p className="home_list_el_anime_info_title">{(el?.el as Anime | undefined)?.titles.russian}</p>
                                        <p className="home_list_el_anime_info_desc">{removeSquareBrackets((el?.el as Anime | undefined)?.description)}</p>
                                    </div>
                                </div>
                            }

                            {
                                // if selection
                                el.type === "Selection" &&
                                <div className={"home_list_el_el home_list_el_anime"}
                                     onClick={() => nav(`/selection/${el?.el?.id}`)}>
                                    <div className="home_list_el_tag selection_count">
                                        <p className="selection_count_text">
                                            {(el?.el as Selection | undefined)?.fav_count}
                                        </p>
                                        <FontAwesomeIcon icon={faBookmark}/>
                                    </div>

                                    <div className="home_list_el_anime_bg home_list_el_anime_bg1"
                                         style={{backgroundImage: `url('https://s2.anitype.site/media/selection/${(el?.el as Selection | undefined)?.id}')`}}></div>
                                    <div className="home_list_el_anime_bg home_list_el_anime_bg2"></div>
                                    <div className="home_list_el_anime_bg home_list_el_anime_bg3"></div>
                                    <div className="home_list_el_anime_bg_grad"></div>

                                    <div className="home_list_el_anime_info">
                                        <p className="home_list_el_anime_info_title">{(el?.el as Selection | undefined)?.title}</p>
                                        <p className="home_list_el_anime_info_desc">{removeSquareBrackets((el?.el as Selection | undefined)?.text)}</p>
                                    </div>
                                </div>
                            }

                            {/*{*/}
                            {/*    // if comment*/}
                            {/*    el.type === "AnimeComment" &&*/}
                            {/*    <div className={"home_list_el_el home_list_el_comment"}>*/}
                            {/*        <div className="home_list_el_tag blue" style={{top: -15}}>Комментарий</div>*/}

                            {/*        <div className="home_list_el_comment_comment">*/}
                            {/*            <div style={{marginRight: 15}}*/}
                            {/*                 onClick={() => nav(`/profile/${(el?.el as Comment | undefined)?.user.username}`)}>*/}
                            {/*                <UserAvatar*/}
                            {/*                    user={(el?.el as Comment | undefined)?.user as UserInfo | undefined}/>*/}
                            {/*            </div>*/}

                            {/*            <div className="anime_comments_block_comment_data">*/}
                            {/*                <div className="anime_comments_block_comment_data_header">*/}
                            {/*                    <p className={getSubStatus((el?.el as Comment | undefined)?.user.sub) ? "anime_comments_block_comment_data_header_username sub" : "anime_comments_block_comment_data_header_username"}>*/}
                            {/*                        {(el?.el as Comment | undefined)?.user.username}*/}
                            {/*                        <UserEmoji*/}
                            {/*                            user={(el?.el as Comment | undefined)?.user as UserInfo}/>*/}
                            {/*                    </p>*/}
                            {/*                    <p className="anime_comments_block_comment_data_header_date">{timeAgoTextNew((el?.el as Comment)?.createdAt ?? '')}</p>*/}
                            {/*                </div>*/}

                            {/*                <div className="anime_comments_block_comment_data_body"*/}
                            {/*                     onClick={() => nav(`/comments/${(el?.el as Comment | undefined)?.id}`)}>*/}
                            {/*                    <p className={"home_page_comment_text_value"}>{*/}
                            {/*                        removeHtmlAndSpecialChars((el?.el as Comment)?.text)*/}
                            {/*                    }</p>*/}
                            {/*                    /!*<p className="home_page_comment_text_value_anime">к {animes.find(v => v.id === el.releaseId)?.titles.russian}</p>*!/*/}

                            {/*                </div>*/}

                            {/*                <div className="anime_comments_block_comment_data_actions">*/}
                            {/*                    {*/}
                            {/*                        (el?.el as Comment)?.children_count > 0 &&*/}
                            {/*                        <p className={"to"} style={{width: 'fit-content'}}*/}
                            {/*                           onClick={() => nav(`/comments/${(el?.el as Comment | undefined)?.id}`)}>{pluralizeResponses((el?.el as Comment)?.children_count)}</p>*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*}*/}

                            {
                                // if user
                                el.type === "User" &&
                                <div className={"home_list_el_el home_list_el_comment"}>
                                    <div className="home_list_el_tag purple" style={{top: -15}}>Пользователь</div>

                                    <div className="home_list_el_comment_comment">
                                        <div className="home_page_top_user">

                                            <div style={{marginRight: 15}}
                                                 onClick={() => nav(`/profile/${(el?.el as UserInfo | undefined)?.username}`)}>
                                                <UserAvatar user={(el?.el as UserInfo | undefined)}/>
                                            </div>
                                            <div>
                                                <p className="home_page_top_user_username">{
                                                    (el?.el as UserInfo | undefined)?.username
                                                }
                                                    <UserEmoji user={(el?.el as UserInfo | undefined)}/>
                                                </p>
                                                <p className="home_page_top_user_level">{
                                                    (el?.el as UserInfo | undefined)?.level
                                                } уровень</p>
                                                <p className="home_page_top_reg_time">зарегистрировался {timeAgoText((el?.el as UserInfo | undefined)?.createdDate ?? [])}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </>)
            }


            {/* Other elements */}
            {
                loading &&
                <div className={"ccp"}>
                    <Loader/>
                </div>
            }
            <div ref={forLoadDiv}></div>
        </>
    );
};

export default HomeList;

export type HomeListElType = Anime | UserInfo | Selection | Comment;

export interface HomeListEl {
    el: HomeListElType,
    type: 'Anime' | 'User' | 'Selection' | 'AnimeComment';
}