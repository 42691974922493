import {BACKEND} from "../consts";
import axios from "../core/axios";

export const createStream = async ({cookies, name, anime, episode, translation, pass, setErr}: any) => {
    if (!cookies.access) {
        let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
        window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
        return
    }

    return axios({
        method: 'post',
        url: BACKEND + 'streams/create',
        data: {
            title: name ?? 'Новый стрим',
            anime_id: +(anime ?? -1),
            season: -1,
            episode: +(episode ?? -1),
            translation: translation ?? '',

            password: pass ?? '',
        },
        headers: {
            Authorization: 'Bearer ' + cookies.access
        }
    })
        .then(r => {
            return r.data
        })
        .catch(e => {
            if (e?.response?.data?.ru) {
                setErr(e?.response?.data?.ru)
            } else {
                console.error(e)
            }
            return null;
        })
}

