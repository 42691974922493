import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {getSystemStatusFolder} from "../../../funcs/folders";
import {faFolderPlus} from "@fortawesome/free-solid-svg-icons/faFolderPlus";
import Loader from "../../Loader";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {getFoldersMy} from "../../../api/common";

const MyPageFolders = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let [items, setItems] = useState<any[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }

            setIsLoading(true)

            let itemsS = await getFoldersMy()
            setItems(itemsS)
            setIsLoading(false)
        })()
    }, [])

    return (
        <div style={{padding: "15px 15px 20px 15px"}}>

            <AutoFadeTopBar title={'Все папки'} alwaysShown={true}/>


            <div style={{padding: '0 10px'}}>
                {
                    !isLoading ?
                    items.map(el =>
                        <div key={el.id} className="select_page_content_button to" onClick={() => {
                            nav(`/my/folders/${encodeURIComponent(el.title)}?id=${el.id}`)
                        }}>
                            <div className={"select_page_content_button_g"}>
                                <p className={"select_page_content_button_title"}>{el.title}</p>
                                <p className={"select_page_content_button_desc"}>{getSystemStatusFolder(el.title) ? 'Системная' : 'Пользовательская'}</p>
                            </div>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </div>) : <div className={"ccp"}>
                            <Loader/>
                        </div>
                }

                <div className="my_page_separator" style={{marginBottom: 10, marginTop: 35}}></div>
                <div>
                    <div className="select_page_content_button2 to" onClick={() => {
                        nav('/my/folders/create')
                    }}>
                        <div className="svg">
                            <FontAwesomeIcon icon={faFolderPlus} />
                        </div>
                        <p className={"select_page_content_button_title"}>Создать папку</p>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default MyPageFolders;