import React, {useEffect, useState} from 'react';
import {Anime, EpisodeInfo, EpisodeSave} from "../../types";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {getEpisodesInfo, getWatchlogsByAnimeId} from "../../api/common";
import KinoPoisk from "../assets/KinoPoisk";
import Wink from "../assets/Wink";
import Okko from "../assets/Okko";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons";
import {checkAnimeBan, getAnimeAiredOnString} from "../../funcs/anime";
import {getUserPickedTranslation} from "../../funcs/users";
import {findMinEpisode} from "../../funcs/kodik";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {secondsToTime} from "../../funcs/time";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {useDispatch} from "react-redux";
import {dataSlice} from "../../lib/redux";

const AnimePlayPosterButtonBlock = ({anime}: { anime: Anime | undefined }) => {
    let [open, setOpen] = useState(false)

    let nav = useNavigate()
    let [cookies, setCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [userWatchLog, setUserWatchLog] = useState<EpisodeSave>()
    let [kodik, setKodik] = useState<EpisodeInfo[]>([])

    let dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            setKodik(await getEpisodesInfo(+(anime?.id ?? 0)))

            if (cookies.access && anime?.id) {
                let user_watch_log = await getWatchlogsByAnimeId(anime.id)
                if (user_watch_log) setUserWatchLog(user_watch_log)
            }
        })()
    }, []);

    useEffect(() => {
        let l = `/anime/${anime?.id}/play/?rtx=true&translation=${getUserPickedTranslation(+(anime?.id ?? 0), kodik)}`;

        if (userWatchLog) {
            l += `&last=${userWatchLog?.time + '_' + userWatchLog?.episode?.number}&episode=${userWatchLog?.episode?.number}`
        } else {
            l += `&episode=${findMinEpisode(kodik)}`
        }
        dispatch(dataSlice.actions.setAnimePagePlayLink(l))
    }, [anime, kodik, userWatchLog]);

    return (
        <>
            {
                open &&
                <div className="anime_play_block_services_popup_bg">
                    <div className="anime_play_block_services_popup">
                        <p className={"anime_play_block_services_popup_title"}>Смотрите на лицензионных сервисах!</p>

                        {
                            anime?.external_links.map(el =>
                                <div className="anime_play_block_services_popup_service" onClick={() => {
                                    window.location.href = el.url
                                }}>
                                    {
                                        el.url.includes('hd.k') && <KinoPoisk size={40}/>
                                    }
                                    {
                                        el.url.includes('wink') && <Wink size={40}/>
                                    }
                                    {
                                        el.url.includes('okko') && <Okko size={40}/>
                                    }

                                    <div>
                                        {
                                            el.url.includes('hd.k') && <p>Кинопоиск HD</p>
                                        }
                                        {
                                            el.url.includes('wink') && <p>Wink</p>
                                        }
                                        {
                                            el.url.includes('okko') && <p>Okko</p>
                                        }
                                        <p>{el.url}</p>
                                    </div>
                                </div>)
                        }

                        <div className={"anime_play_block_services_popup_service_back"} onClick={() => setOpen(false)}>
                            <FontAwesomeIcon icon={faArrowLeftLong}/>
                            <p>Назад</p>
                        </div>
                    </div>
                </div>
            }


            {
                !checkAnimeBan(anime) && anime?.status != 'anons' && !(window?.location?.hostname?.includes('.ru')) ?
                    <>
                        <button className="anime_fixed_poster_play" onClick={() => {
                            let l = `/anime/${anime?.id}/play/?rtx=true&translation=${getUserPickedTranslation(+(anime?.id ?? 0), kodik)}`;

                            if (userWatchLog) {
                                l += `&last=${userWatchLog?.time + '_' + userWatchLog?.episode?.number}&episode=${userWatchLog?.episode?.number}`
                            } else {
                                l += `&episode=${findMinEpisode(kodik)}`
                            }
                            nav(l)
                        }}>
                            <FontAwesomeIcon icon={faPlay}/>
                            <p className={"golos"}>{
                                !userWatchLog ? `${findMinEpisode(kodik)} эпизод` : `${userWatchLog?.episode?.number} эп. (${secondsToTime(userWatchLog?.time ?? 0)})`
                            }</p>
                        </button>
                    </> :
                    <>
                        <FontAwesomeIcon icon={faBan}/>
                        <p className={"golos"}>
                            {
                                anime?.status === 'anons' ?
                                    <span>{getAnimeAiredOnString(anime) ?? 'Скоро'}</span> :
                                    <span>Недоступно</span>
                            }
                        </p>
                    </>
            }
        </>
    );
};

export default AnimePlayPosterButtonBlock;