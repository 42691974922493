import {ReduxState} from "../../store";

export const selectAnime = (id: number) => {
    return (state: ReduxState) => state.data.data.animes.find(v => v.id === id);
}

export const selectHomeContent = (url: string) => {
    return (state: ReduxState) => state.data.data.pages.home.idsContents.find(v => v.url === url)?.content ?? [];
}

export const selectAnimePageableContent = (tag: string) => {
    return (state: ReduxState) => state.data.data.animePageableContent.find(v => v.tag === tag)?.content ?? [];
}

export const selectAnimePageablePage = (tag: string) => {
    return (state: ReduxState) => state.data.data.animePageableContent.find(v => v.tag === tag)?.page ?? 0;
}

export const selectHomeAnime = (state: ReduxState) => state.data.data.pages.home.anime;

export const selectUserInfo = (state: ReduxState) => state.data.data.userInfo;
export const selectContinueWatch = (state: ReduxState) => state.data.data.continueWatch;

export const selectAnimePagePlayLink = (state: ReduxState) => state.data.data.pages.anime.play_page_link;