import React from 'react';
import PremiumSvg from "./assets/PremiumSvg";

const PremiumBadge = ({position}: {position: string}) => {
    return (
        <div className={`premium_badge premium_badge_${position}`}>
            <PremiumSvg size={15}/>
            <p>Premium</p>
        </div>
    );
};

export default PremiumBadge;