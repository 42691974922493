import React, {useEffect, useRef, useState} from 'react';
import '../../../styles/watchtogether.css'
import W2TabsModule from "../../w2/W2TabsModule";
import W2ChatModule from "../../w2/W2ChatModule";
import W2ManageModule from "../../w2/W2ManageModule";
import W2MembersModule from "../../w2/W2MembersModule";
import {useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {
    selectHubId,
    selectHubInfo,
    selectHubMessages,
    selectPlayer,
    selectWatchData,
    w2Slice, WatchTogetherHubInfo,
    WatchTogetherHubMessage,
    WatchTogetherHubWatchDto
} from "../../../lib/redux";
import {UserInfo} from "../../../types";
import axios from "../../../core/axios";
import {faExpand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AnimeWatchTogether = () => {
    // ws
    const ws = useRef<WebSocket>()

    // separators
    let [selectedRightTab, setSelectedRightTab] = useState('Чат')
    let [tabIcons, setTabIcons] = useState(false)

    let [firstPartPixels, setFirstPartPixels] = useState(
        (window.screen.width / 100 * 70) - 10
    )
    let [secondPartPixels, setSecondPartPixels] = useState(
        (window.screen.width / 100 * 30) - 10
    )

    let [searchParams] = useSearchParams()
    let [userInfo, setUserInfo] = useState<UserInfo>()

    let player = useSelector(selectPlayer)
    let hubId = useSelector(selectHubId)
    let hubInfo = useSelector(selectHubInfo)
    let hubMessages = useSelector(selectHubMessages)
    let watchData = useRef<WatchTogetherHubWatchDto>({
        time: 0,
        anime_id: +(searchParams.get('anime_id') ?? 35849),
        episode: +(searchParams.get('episode') ?? 1),
        translation: searchParams.get('translation') ?? 'AniLibria.TV',
        is_paused: false
    })
    let watchDataState = useSelector(selectWatchData)
    let dispatch = useDispatch()

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])
    let iframeRef = useRef<HTMLIFrameElement>(null)
    let pageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (watchDataState.anime_id === -1) {
            dispatch(w2Slice.actions.setWatchData({
                time: 0,
                anime_id: +(searchParams.get('anime_id') ?? 35849),
                episode: +(searchParams.get('episode') ?? 1),
                translation: searchParams.get('translation') ?? 'AniLibria.TV',
                is_paused: false
            }))
        }
    }, []);

    const onChangeOrientation = (event: any) => {
        setFirstPartPixels((window.screen.width / 100 * 70) - 15)
        setSecondPartPixels((window.screen.width / 100 * 30) - 15)
    }

    function volumelineMouseMoveMobile(event: any) {
        var touch = event.touches[0];
        var currentX = touch.clientX;
        let screenWidth = window.screen.width;
        if (window.screen.height > window.screen.width && window.screen.orientation.type.startsWith('landscape')) {
            screenWidth = window.screen.height;
        }

        setFirstPartPixels(currentX - 15)
        setSecondPartPixels(screenWidth - (currentX - 15) - 15)

        if ((screenWidth - (currentX - 15) - 15) < 250) {
            setTabIcons(true)
        } else setTabIcons(false)
    }

    function playerMessageListener(event: any) {
        if (event?.data?.ev) {
            if (event.data.ev === 'time') {
                if (+event.data.value > 0) {
                    watchData.current = {
                        ...watchData.current,
                        time: Math.round(+event.data.value)
                    }
                    dispatch(w2Slice.actions.setWatchDataTime(Math.round(+event.data.value)))
                }
                return
            }
            // if (event.data.ev === 'duration') {
            //     if (+event.data.value > 0) {
            //
            //         console.log('pml dur', +event.data.value)
            //     }
            //     return
            // }
            if (event.data.ev === 'pause') {
                watchData.current = {
                    ...watchData.current,
                    is_paused: true
                }
                dispatch(w2Slice.actions.setWatchDataPaused(true))
            }
            if (event.data.ev === 'play') {
                watchData.current = {
                    ...watchData.current,
                    is_paused: false
                }
                dispatch(w2Slice.actions.setWatchDataPaused(false))
            }
            if (event.data.ev === 'seek_episode') {
                watchData.current = {
                    ...watchData.current,
                    episode: +event.data.value
                }
                dispatch(w2Slice.actions.setWatchDataEpisode(+event.data.value))
                return
            }
            if (event.data.ev === 'seek_translation') {
                watchData.current = {
                    ...watchData.current,
                    translation: event.data.value
                }
                dispatch(w2Slice.actions.setWatchDataTranslation(event.data.value))
                return
            }
            if (event.data.ev === 'change_player') {
                dispatch(w2Slice.actions.changePlayer(event.data.value))
            }
        }
    }

    function onMessage(hId: string) {
        return (ev: MessageEvent<any>) => {
            try {
                const json = JSON.parse(ev.data)

                if (json?.key && json.key == 'hub_info' && json.value?.key == hId) {
                    dispatch(w2Slice.actions.setHubInfo(json.value))

                    let hInfo: WatchTogetherHubInfo = json.value;
                    if (hInfo.owner !== userInfo?.username) {
                        let owner = hInfo.users.find(v => v.username === hInfo.owner)

                        if (owner?.watch_data.episode && owner?.watch_data.episode > 0 &&
                            watchData.current?.episode !== owner.watch_data.episode) {
                            dispatch(w2Slice.actions.setWatchDataEpisode(owner.watch_data.episode))
                            watchData.current = {
                                ...watchData.current,
                                episode: owner.watch_data.episode
                            }
                        }

                        if (owner?.watch_data.anime_id && owner?.watch_data.anime_id > 0 &&
                            watchData.current?.anime_id !== owner.watch_data.anime_id) {
                            dispatch(w2Slice.actions.setWatchDataAnime(owner.watch_data.anime_id))
                            watchData.current = {
                                ...watchData.current,
                                anime_id: owner.watch_data.anime_id
                            }
                        }

                        if (Math.abs(Math.round(watchData.current.time) - Math.round(+(owner?.watch_data.time ?? 0))) > 2) {
                            postToIFramePlayer('seek', Math.round(+(owner?.watch_data.time ?? 0)))
                        }
                    }
                }

                if (json?.key && json.key == "hub_message") {
                    const chatMessage: WatchTogetherHubMessage = json.value;
                    if (hubMessages.filter(v => v.ID === chatMessage.ID).length == 0) {
                        dispatch(w2Slice.actions.addMessage(chatMessage))
                    }
                    postToIFramePlayer("message", `${chatMessage.ID};;${chatMessage.text};;${chatMessage.user_name}`)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    function connect() {
        if (!cookies.access) {
            let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
            window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
            return
        }

        axios({
            url: `users/me`
        }).then(r => setUserInfo(r.data)).catch(ignored => ignored)

        let hId = searchParams.get('id')
        if (!hId) return;
        dispatch(w2Slice.actions.setHubId(hId))

        ws.current = new WebSocket(`wss://anitype.site/ws3/connect?subs=hub_${hId}&authorization=Bearer ${cookies.access}`)

        ws.current.onerror = (er) => {
            console.error(er)
            ws.current = new WebSocket(`wss://anitype.site/ws3/connect?subs=hub_${hId}&authorization=Bearer ${cookies.access}`)
            ws.current.onmessage = onMessage(hId ?? '')

        }
        ws.current.onclose = (er) => {
            console.error(er)
            ws.current = new WebSocket(`wss://anitype.site/ws3/connect?subs=hub_${hId}&authorization=Bearer ${cookies.access}`)
            ws.current.onmessage = onMessage(hId ?? '')

        }
        ws.current.onmessage = onMessage(hId ?? '')
    }

    // ws
    useEffect(() => {

        if (window?.addEventListener) {
            window.addEventListener('message', playerMessageListener);
            window.addEventListener('orientationchange', onChangeOrientation);
        } else {
            // @ts-ignore
            window?.attachEvent('onmessage', playerMessageListener);
            // @ts-ignore
            window?.attachEvent('orientationchange', onChangeOrientation);
        }

        let i = setInterval(() => {
            if (ws.current?.readyState !== 1) {
                connect()
                return;
            }
            let json = {
                key: "send_my_data",
                value: {
                    key: hubId,
                    watch_data: {
                        ...watchData.current,
                    }
                }
            }
            ws.current?.send(JSON.stringify(json))
        }, 1000)

        return () => {
            ws.current?.close()

            if (window.removeEventListener) {
                window.removeEventListener('message', playerMessageListener);
            } else {
                // @ts-ignore
                window?.detachEvent('onmessage', playerMessageListener);
            }

            clearInterval(i)
        }
    }, [cookies.access, hubId]);



    function postToIFramePlayer(method: "seek" | "play" | "pause" | "message" | "send_skip_time", value?: any) {
        const iframeWindow = iframeRef.current?.contentWindow;
        if (!iframeWindow) return;

        if (method == "play") {
            iframeWindow.postMessage({
                ev: 'play',
            }, 'https://player2.anitype.fun');
            return;
        }

        if (method == "pause") {
            iframeWindow.postMessage({
                ev: 'pause',
            }, 'https://player2.anitype.fun');
            return;
        }

        if (method == "seek") {
            iframeWindow.postMessage({
                ev: 'seek',
                value: value
            }, 'https://player2.anitype.fun');
            return;
        }

        if (method == "message"){
            iframeWindow.postMessage({
                ev: 'new_chat_message',
                value: value
            }, 'https://player2.anitype.fun');
            return;
        }

        if (method == "send_skip_time"){
            iframeWindow.postMessage({
                ev: 'skip_time',
                value: value
            }, 'https://player2.anitype.fun');
            return;
        }
    }

    function fullscreen() {
        if (pageRef.current)
            pageRef.current.requestFullscreen().catch(ignored => ignored)
    }

    return (
        <div className={"separated_page_bg"} ref={pageRef}>
            <div className="fullscreen_badge" onClick={() => fullscreen()}>
                <FontAwesomeIcon icon={faExpand} />
                <span style={{marginLeft: 10}}>Полноэкранный режим</span>
                </div>

            <div className="separated_page_part" style={{
                width: `${firstPartPixels}px`
            }}>
                <iframe ref={iframeRef} allowFullScreen={true} allow="autoplay *; fullscreen *" src={`https://player2.anitype.fun/?player=${player}&translation=${watchDataState.translation}&id=${watchDataState.anime_id}&episode=${watchDataState.episode}&season=1`}></iframe>
            </div>

            <div className="separated_page_separator"
                 onTouchMove={volumelineMouseMoveMobile}
            >
                <div className="separated_page_separator_icon">
                    <div className="separated_page_separator_icon_i"></div>
                    <div className="separated_page_separator_icon_i"></div>
                    <div className="separated_page_separator_icon_i"></div>
                </div>
            </div>

            <div className="separated_page_part" style={{
                width: `${secondPartPixels}px`
            }}>
                <W2TabsModule selectedRightTab={selectedRightTab} setSelectedRightTab={setSelectedRightTab} tabIcons={tabIcons}/>

                {
                    selectedRightTab === 'Чат' &&
                    <W2ChatModule ws={ws}/>
                }
                {
                    selectedRightTab === 'Участники' &&
                    <W2MembersModule/>
                }
                {
                    selectedRightTab === 'Управление' &&
                    <W2ManageModule watchData={watchData} username={userInfo?.username ?? ''}/>
                }
            </div>
        </div>
    );
};

export default AnimeWatchTogether;