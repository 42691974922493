import React, {useEffect, useState} from 'react';
import Home2Block from "./Home2Block";
import {BACKEND} from "../../consts";
import {cacheableAxios} from "../../funcs/cacheableAxios";
import {getWatchlogsUserPopular} from "../../api/common";

const Home2Blocks = () => {
    let [popularIds, setPopularIds] = useState<number[]>([])

    useEffect(() => {
        (async () => {
            let ids: number[] = await getWatchlogsUserPopular()

            ids = ids?.slice(0, 15) ?? []
            setPopularIds(ids)
        })()
    }, []);

    return (
        <>
            {
                popularIds.length > 0 &&
                <Home2Block title={'Смотрят сейчас'} desc={'Самые популярные аниме за 2 недели!'} url={
                    `anime/ids?ids=${popularIds.join(',')}`
                } moreUrl={'#'}/>
            }

            <Home2Block title={'Онгоинги'} desc={'Эти аниме выходят сейчас'} url={
                `anime/filter?size=15&page=0&score_start=7&status=ongoing&kind=tv`
            } moreUrl={'/list/ongoings'}/>

            <Home2Block title={'Анонсы'} desc={'Время подобрать что-то новое?'} url={
                `anime/filter?size=15&page=0&status=anons&kind=tv`
            } moreUrl={'/list/anonses'}/>

            <Home2Block title={'Лучшее'} desc={'Аниме с высшей оценкой'} url={
                `anime/filter?size=15&page=0&score_start=9`
            } moreUrl={'/list/best'}/>

            <Home2Block title={'Доступно в 4К'} desc={'Аниме в ультра качестве!'} url={
                `anime/filter?size=15&page=0&uhd=true`
            } moreUrl={'/list/uhd'}/>

            <Home2Block title={'Фильмы'} desc={'Приятно провести вечер'} url={
                `anime/filter?size=15&page=0&kind=movie&score_start=7`
            } moreUrl={'/list/movies'}/>

            <Home2Block title={'Популярные OVA'} desc={'Продолжения любимых историй'} url={
                `anime/filter?size=15&page=0&kind=ova&score_start=7`
            } moreUrl={'/list/ova'}/>


        </>
    );
};

export default Home2Blocks;