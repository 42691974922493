import {EpisodeInfo} from "../types";

export function targetValue(level: number): number { // level to exp
    let firstValue = 300.0;
    let incrementPercentage = 0.15; // 15%
    let expNeeded = firstValue;

    for (let i = 2; i <= level; i++) {
        expNeeded += firstValue + expNeeded * incrementPercentage;
    }

    return expNeeded;
}

export function calcProcLevel(level: number | undefined, exp: number | undefined): number {
    if (!level || !exp) return -1;

    return ((targetValue(level) - targetValue(level - 1) - (targetValue(level) - exp)) / (targetValue(level) - targetValue(level - 1))) * 100
}


export function getSet(settings: any[], key: string, def: string): string {
    let k = settings.filter((v: any) => v.key === key)
    if (k.length === 0) return def;
    return k[0].value;
}

export function getUserPickedTranslation(anime: number, data: EpisodeInfo[]): string {
    let localSet = localStorage.getItem(`picked_translation_${anime}`)
    if (localSet && data.find(v => v.translation?.title === localSet)) {
        return localSet;
    }

    if (!data?.length || data?.length <= 0) return '';

    let libria = data?.filter(v => v.translation?.title === 'AniLibria.TV') ?? [];

    if (libria?.length > 0) {
        setUserPickedTranslation(anime, 'AniLibria.TV')
        return 'AniLibria.TV';
    }

    return data?.[0]?.translation?.title ?? '';
}

export function setUserPickedTranslation(anime: number, translation: string) {
    localStorage.setItem(`picked_translation_${anime}`, translation)
}

export function getStartTime(searchParam: string, currentEp: number) {
    if (!searchParam || !searchParam.includes('_') || searchParam.split('_').length != 2) return 0;

    let time = +(searchParam.split('_')[0] ?? 0)
    let ep = +(searchParam.split('_')[1] ?? 0)

    if (ep == currentEp) return time;
    return 0;
}

export function getCurrentWS3Info(hubs: any[], username: string) {
    let hubss = hubs.sort((a: any, b: any) => {
        let a1 = a?.users?.find((v: any) => v.username === username)?.updated_at;
        let b1 = b?.users?.find((v: any) => v.username === username)?.updated_at;

        if (a1 > b1) return -1;
        return 1;
    })
    if (hubss.length === 0) return undefined;

    return {
        ...hubss?.[0]?.users?.find((v: any) => v.username === username)?.watch_data,
        usersCount: hubss?.[0]?.users?.length
    };
}