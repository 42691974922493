import React, {useEffect, useRef, useState} from 'react';
import './styles/topbarsearch.css'
import './styles/navbar.css'
import {Route, Routes} from "react-router-dom";
import PageLayoutWithTopBarAndBotBar from "./components/layouts/PageLayoutWithTopBarAndBotBar";
import HomePage from "./components/pages/home/HomePage";
import AnimePage from "./components/pages/anime/AnimePage";
import PageLayoutWithBotBar from "./components/layouts/PageLayoutWithBotBar";
import HomeSuggestionsPopularPage from "./components/pages/home/HomeSuggestionsPopularPage";
import HomeSuggestionsNewestPage from "./components/pages/home/HomeSuggestionsNewestPage";
import HomeSuggestionsFilterPage from "./components/pages/home/HomeSuggestionsFilterPage";
import AnimeSelectSourcePage from "./components/pages/anime/AnimeSelectSourcePage";
import StreamsListPage from "./components/pages/streams/StreamsListPage";
import ProfilePage from "./components/pages/profile/ProfilePage";
import MyPage from "./components/pages/my/MyPage";
import MyFullFolder from "./components/pages/my/MyFullFolder";
import StreamsCreatePage from "./components/pages/streams/StreamsCreatePage";
import StreamsCreateSelectNamePage from "./components/pages/streams/StreamsCreateSelectNamePage";
import StreamsCreateSelectAnimePage from "./components/pages/streams/StreamsCreateSelectAnimePage";
import StreamsCreateSelectTranslationPage from "./components/pages/streams/StreamsCreateSelectTranslationPage";
import StreamsCreateSelectEpisodePage from "./components/pages/streams/StreamsCreateSelectEpisodePage";
import EditProfilePage from "./components/pages/profile/EditProfilePage";
import MyPageFolders from "./components/pages/my/MyPageFolders";
import MyPageFoldersCreate from "./components/pages/my/MyPageFoldersCreate";
import StreamsCreateSelectPassPage from "./components/pages/streams/StreamsCreateSelectPassPage";
import StreamsWatchPage from "./components/pages/streams/StreamsWatchPage";
import NotificationsPage from "./components/pages/NotificationsPage";
import SelectionPage from "./components/pages/SelectionPage";
import UserMenuPage from "./components/pages/usermenu/UserMenuPage";
import UserMenuLevelPage from "./components/pages/usermenu/UserMenuLevelPage";
import UserMenuPremiumPage from "./components/pages/usermenu/UserMenuPremiumPage";
import UserMenuSettingsPage from "./components/pages/usermenu/UserMenuSettingsPage";
import UserMenuSettingsNotificationsPage from "./components/pages/usermenu/UserMenuSettingsNotificationsPage";
import Home2Page from "./components/pages/home/Home2Page";
import AnimeSelectTranslationPage from "./components/pages/anime/AnimeSelectTranslationPage";
import AnimeFoldersPage from "./components/pages/anime/AnimeFoldersPage";
import AnimeInfoPage from "./components/pages/anime/AnimeInfoPage";
import HomeFullListPage from "./components/pages/home/HomeFullListPage";
import UserMenuSettingsViewPage from "./components/pages/usermenu/UserMenuSettingsViewPage";
import CommentPage from "./components/pages/comments/CommentPage";
import CommentTargetPage from "./components/pages/comments/CommentTargetPage";
import UserMenuCodeActivatePage from "./components/pages/usermenu/UserMenuCodeActivatePage";
import NewSelectionPage from "./components/pages/NewSelectionPage";
import UserMenuBetaPage from "./components/pages/usermenu/UserMenuBetaPage";
import AnimeWatchTogether from "./components/pages/anime/AnimeWatchTogether";
import SerialPage from "./components/pages/serial/SerialPage";
import PaymentPage from "./components/pages/PaymentPage";
import MyPageHistory from "./components/pages/my/MyPageHistory";
import QRAuthPage from "./components/pages/QRAuthPage";

function App() {
    let wsRef = useRef<WebSocket | null>(null)

    let [searchOpen, setSearchOpen] = useState(false)

    let [theme, setTheme] = useState(
        localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
    )

    useEffect(() => {
        document.documentElement.dataset.theme = theme
    }, [theme]);

    return (
        <PageLayoutWithBotBar setSearchOpen={setSearchOpen} wsRef={wsRef}>
            <Routes>

                {/* Home page */}
                <Route path={"/"} element={
                    <PageLayoutWithTopBarAndBotBar searchOpen={searchOpen} setSearchOpen={setSearchOpen}>
                        <Home2Page/>
                    </PageLayoutWithTopBarAndBotBar>}/>
                {/* Home page */}
                <Route path={"/list/:type"} element={
                    <HomeFullListPage/>}/>

                {/* Home page */}
                <Route path={"/feed"} element={
                    <PageLayoutWithTopBarAndBotBar searchOpen={searchOpen} setSearchOpen={setSearchOpen}>
                        <HomePage/>
                    </PageLayoutWithTopBarAndBotBar>}/>

                {/*<Route path={"/event"} element={*/}
                {/*    <EventPage/>}/>*/}

                <Route path={"/hub"} element={
                    <AnimeWatchTogether/>}/>

                {/* Serial page */}
                <Route path={"/serial/:id"} element={
                    <SerialPage/>}/>

                {/* Anime page */}
                <Route path={"/anime/:id"} element={
                    <AnimePage/>}/>

                {/* Anime page */}
                <Route path={"/anime/:id/translation"} element={
                    <AnimeSelectTranslationPage/>}/>

                {/* Anime page */}
                <Route path={"/anime/:id/folders"} element={
                    <AnimeFoldersPage/>}/>

                {/* Anime page */}
                <Route path={"/anime/:id/info"} element={
                    <AnimeInfoPage serialPage={false}/>}/>

                {/* Anime page */}
                <Route path={"/anime/:id/play"} element={
                    <AnimeSelectSourcePage wsRef={wsRef}/>}/>

                <Route path={"/comments/:target/:id"} element={
                    <CommentTargetPage/>}/>

                <Route path={"/comments/:id"} element={
                    <CommentPage/>}/>

                {/* Populars list page */}
                <Route path={"/list/populars"} element={
                    <HomeSuggestionsPopularPage/>}/>

                {/* Newest list page */}
                <Route path={"/list/newest"} element={
                    <HomeSuggestionsNewestPage/>}/>

                {/* Filter page */}
                <Route path={"/filter"} element={
                    <HomeSuggestionsFilterPage/>}/>

                {/* Streams list page */}
                <Route path={"/streams"} element={
                    <StreamsListPage/>}/>

                {/* Streams list page */}
                <Route path={"/streams/:tag"} element={
                    <StreamsWatchPage/>}/>
                <Route path={"/streams/create"} element={
                    <StreamsCreatePage/>}/>
                {/* Streams list page */}
                <Route path={"/streams/create/title"} element={
                    <StreamsCreateSelectNamePage/>}/>
                {/* Streams list page */}
                <Route path={"/streams/create/pass"} element={
                    <StreamsCreateSelectPassPage/>}/>
                {/* Streams list page */}
                <Route path={"/streams/create/anime"} element={
                    <StreamsCreateSelectAnimePage/>}/>
                {/* Streams list page */}
                <Route path={"/streams/create/translation"} element={
                    <StreamsCreateSelectTranslationPage/>}/>
                {/* Streams list page */}
                <Route path={"/streams/create/episode"} element={
                    <StreamsCreateSelectEpisodePage/>}/>

                {/* Profile page */}
                <Route path={"/profile/:username"} element={
                    <ProfilePage/>}/>
                {/* Profile page */}
                <Route path={"/profile/:username/edit"} element={
                    <EditProfilePage/>}/>

                {/* My page */}
                <Route path={"/my"} element={
                    <PageLayoutWithTopBarAndBotBar searchOpen={searchOpen} setSearchOpen={setSearchOpen}>
                        <MyPage/>
                    </PageLayoutWithTopBarAndBotBar>
                }/>

                {/* My page */}
                <Route path={"/my/history"} element={
                    <MyPageHistory/>
                }/>

                {/* My page */}
                <Route path={"/my/folders"} element={
                    <MyPageFolders/>
                }/>
                {/* My page */}
                <Route path={"/my/folders/create"} element={
                    <MyPageFoldersCreate/>
                }/>

                {/* My page */}
                <Route path={"/my/folders/:title"} element={
                    <MyFullFolder/>}/>


                <Route path={"/notifications"} element={
                    <NotificationsPage/>}/>

                <Route path={"/selection/new"} element={
                    <NewSelectionPage/>}/>

                <Route path={"/selection/:id"} element={
                    <SelectionPage/>}/>

                <Route path={"/menu"} element={
                    <UserMenuPage/>}/>

                <Route path={"/menu/level"} element={
                    <UserMenuLevelPage/>}/>

                <Route path={"/menu/premium"} element={
                    <UserMenuPremiumPage/>}/>

                <Route path={"/menu/activate"} element={
                    <UserMenuCodeActivatePage/>}/>

                <Route path={"/menu/settings"} element={
                    <UserMenuSettingsPage/>}/>

                <Route path={"/menu/beta"} element={
                    <UserMenuBetaPage/>}/>

                <Route path={"/menu/settings/notifications"} element={
                    <UserMenuSettingsNotificationsPage/>}/>

                <Route path={"/menu/settings/view"} element={
                    <UserMenuSettingsViewPage changeTheme={setTheme}/>}/>

                <Route path={"/payment/:username/:itemId"} element={
                    <PaymentPage/>}/>

                <Route path={"/auth/qr/:code"} element={
                    <QRAuthPage/>}/>

            </Routes>
        </PageLayoutWithBotBar>
    );
}

export default App;
