export function calcPlayerSize(windowSize: any): any {
    let width = windowSize.width * 0.95;
    let height = width / 16 * 9;

    if (height > windowSize.height * 0.5) {
        height = windowSize.height * 0.5;
        width = height / 9 * 16;
    }

    return {width, height}
}