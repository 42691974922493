import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Anime} from "../types";
import {searchAnime} from "../funcs/api/anime";

type SearchData = {
    query: string;
    data: Anime[];
};

const UseSearch = (initialQuery?: string) => {

    const [searchPrevResults, setSearchPrevResults] = useState<SearchData[]>([]);

    const [searchResult, setSearchResult] = useState<Anime[]>([]);

    const [isSearchFind, setIsSearchFind] = useState<boolean>(false)

    const [searchText, setSearchText] = useState<string>(initialQuery ?? "")

    const controller = useRef<AbortController>()

    const searchInterval = useRef(setTimeout(() => ""));

    useEffect(() => {

        if (controller.current) {
            controller.current?.abort()
        }

        const existingData = searchPrevResults.find((result) => result.query === searchText);

        if (existingData){
            setSearchResult(existingData.data)
            return;
        }

        clearTimeout(searchInterval.current);

        searchInterval.current = setTimeout(() => {
            if (searchText.length !== 0) {
                let search = searchAnime(searchText)

                controller.current = search.abortController

                search.animeData.then(r => {
                    setSearchResult(r);
                    setIsSearchFind(false)
                    setSearchPrevResults((prevResults) => [...prevResults, { query: searchText, data: r }])
                }).catch(err => {
                    console.log(err);
                    setIsSearchFind(false)
                })

            } else {
                setSearchResult([]);
                setIsSearchFind(false)
            }
        
        }, 200);

        if (searchText.length !== 0) {
            setIsSearchFind(true)
        }
        if (searchText.length == 0) {
            setIsSearchFind(false)

        }

    }, [searchPrevResults, searchText])

    return [searchResult, isSearchFind, searchText, setSearchText] as [Anime[], boolean, string, Dispatch<SetStateAction<string>>]
    
};

export default UseSearch;