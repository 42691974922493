import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {BACKEND} from "../../../consts";
import Error from "../../Error";
import AutoFadeTopBar from "../../AutoFadeTopBar";
import axios from "../../../core/axios";

const UserMenuCodeActivatePage = () => {
    let nav = useNavigate()

    let [code, setCode] = useState('')

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [err, setErr] = useState('')

    useEffect(() => {
        (async () => {
            if (!cookies.access) {
                let domain = window.location.host.includes('fun') ? 'anitype.fun' : 'anitype.ru';
                window.location.href = `https://${domain}/auth?redirectTo=${encodeURIComponent(`https://m.${domain}/`)}`
                return
            }
        })()
    }, [])

    async function activate(code: string) {
        setCode('')
        axios({
            url: `app2/codes/sub/activate/${code}`
        })
            .then(r => {
                setErr('Код активирован!')
            })
            .catch(e => {
                if (e?.response?.data?.detail) {
                    setErr(e?.response?.data?.detail)
                } else {
                    setErr('Произошла ошибка.')
                }
            })
    }

    return (
        <>
            <Error message={err} setMessage={setErr}/>

            <AutoFadeTopBar title={'Активация кода'} alwaysShown={true}/>

            <div className="select_page_content"
                 style={{paddingTop: 20, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>

                <input type="text" maxLength={16} placeholder={"Введите код"} value={code}
                       onChange={e => setCode(e.target.value)}/>

                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <button className="anime_fast_button to" onClick={() => activate(code)}>Активировать</button>
                </div>


            </div>
        </>
    );
};

export default UserMenuCodeActivatePage;

