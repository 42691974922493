export const BACKEND = "https://anitype.site/"

export const KODIK_API_URL = "https://kodikapi.com/"
export const KODIK_PUBLIC_TOKEN = "3bd0a27dfccd284c54f4889f4a7d6453"


export const genres = ['Безумие', 'Боевые искусства', 'Вампиры', 'Военное', 'Гарем', 'Гурман', 'Демоны', 'Детектив',
    'Детское', 'Дзёсей', 'Драма', 'Игры', 'Исторический', 'Комедия', 'Космос', 'Машины', 'Меха', 'Музыка', 'Пародия',
    'Повседневность', 'Полиция', 'Приключения', 'Психологическое', 'Работа', 'Романтика', 'Самураи', 'Сверхъестественное',
    'Спорт', 'Супер сила', 'Сэйнэн', 'Сёдзё', 'Сёдзё-ай', 'Сёнен', 'Сёнен-ай', 'Триллер', 'Ужасы', 'Фантастика', 'Фэнтези',
    'Хентай', 'Школа', 'Экшен', 'Эротика', 'Этти']

export const NICKNAME_COSTS = [
    {
        length: 5,
        strict: false,
        price: 199,
        text: 'от 5 символов'
    },
    {
        length: 4,
        strict: true,
        price: 999,
        text: '4 символа'
    },
    {
        length: 3,
        strict: true,
        price: 2999,
        text: '3 символа'
    },
    {
        length: 2,
        strict: true,
        price: 49999,
        text: '2 символа'
    },
    {
        length: 1,
        strict: true,
        price: 249999,
        text: '1 символ'
    },
]


export const PROJECT_HISTORY = [
    {
        date: '14.05.2023',
        title: 'появилась идея благодаря тиктокам другого аниме-проекта',
        picture: ''
    },
    {
        date: '15.05.2023',
        title: 'начинаю делать сайт и снимаю об этом тикток',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/1.png'
    },
    {
        date: '15.05.2023',
        title: 'радуюсь первому комментарию, что хоть кто-то зайдет',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/2.png'
    },
    {
        date: '16.05.2023',
        title: 'так выглядел сайт тогда',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/3.png'
    },
    {
        date: '16.05.2023',
        title: 'а так выглядела страница просмотра',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/4.png'
    },
    {
        date: '18.05.2023',
        title: 'радуюсь первым 25 пользователям. вскоре этот тикток собрал больше 100 тыс. просмотров и дал огромную мотивацию',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/5.png'
    },
    {
        date: '19.05.2023',
        title: 'уже тогда была идея сделать совместный просмотр. на фото страница друзей, где отображается кто что смотрит',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/6.png'
    },
    {
        date: '19.05.2023',
        title: 'создал канал по просьбам пользователей. сначала не видел в этом смысл',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/7.png'
    },
    {
        date: '20.05.2023',
        title: 'добавили второй плеер со всеми озвучками.',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/8.png'
    },
    {
        date: '21.05.2023',
        title: 'решили дать возможность комьюнити разрабатывать проект вместе с нами',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/9.png'
    },
    {
        date: '21.05.2023',
        title: 'встречаем легенду, сильно повлиявшую на проект.',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/10.png'
    },
    {
        date: '23.05.2023',
        title: 'dutix предложил концепт нового дизайна',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/11.png'
    },
    {
        date: '24.05.2023',
        title: 'решили позаимствовать всё у кинопоиска. в этот момент к разработке присоединяется второй ключевой разработчик - nnveter (aka korzhik)',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/12.png'
    },
    {
        date: '26.05.2023',
        title: 'копируем абсолютно всё',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/13.png'
    },
    {
        date: '27.05.2023',
        title: 'совместного просмотра пока нет, поэтому смотрим в дискорде',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/14.png'
    },
    {
        date: '02.06.2023',
        title: 'разрабатываем совместный просмотр',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/15.png'
    },
    {
        date: '03.06.2023',
        title: 'уже почти был готов новый сайт с дизайном похожим на кинопоиск',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/16.png'
    },
    {
        date: '05.06.2023',
        title: 'придумали подписку. тогда она стоила 69р в месяц и почти ничего не давала',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/17.png'
    },
    {
        date: '06.06.2023',
        title: 'выпускаем крупное обновление по дизайну',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/19.png'
    },
    {
        date: '07.06.2023',
        title: 'такие бонусы давала подписка',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/20.png'
    },
    {
        date: '09.06.2023',
        title: 'добавили комментарии к аниме',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/21.png'
    },
    {
        date: '10.06.2023',
        title: 'а через день добавили подборки с аниме',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/22.png'
    },
    {
        date: '15.06.2023',
        title: 'добавили папки и сменили основной цвет сайта (чтобы хоть чем то отличаться от кинопоиска)',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/23.png'
    },
    {
        date: '17.06.2023',
        title: 'а по этой фотокарточке можно узнать какой онлайн тогда был',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/24.png'
    },
    {
        date: '17.06.2023',
        title: 'добавили чат в совместный просмотр',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/25.png'
    },
    {
        date: '08.07.2023',
        title: 'теряю интерес и иду делать сервер в майнкрафте',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/26.png'
    },
    {
        date: '02.07.2023',
        title: '02.07 - нам блокируют сайт. как оказалось позже, это было из-за рекламы в одном из плееров',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/27.png'
    },
    {
        date: '03.07.2023',
        title: 'меняем домен. был anitype.online, теперь anitype.fun',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/28.png'
    },
    {
        date: '11.07.2023',
        title: 'обновили подборки',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/30.png'
    },
    {
        date: '12.08.2023',
        title: 'в ходе обновления случайно что-то сломали, и пришлось откатить базу данных. первый крупный феил',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/31.png'
    },
    {
        date: '17.08.2023',
        title: 'обновили дизайн совместного просмотра',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/29.png'
    },
    {
        date: '19.08.2023',
        title: 'добавили рекомендации, которые позже убрали.',
        picture: ''
    },
    {
        date: '06.11.2023',
        title: 'появилась идея переделывать аниме в 4К с помощью нейронных сетей. первый тест на отрезке качеством 360p',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/32.png'
    },
    {
        date: '07.11.2023',
        title: 'экспериментируем с разными нейронками и настройками. ищем лучшее качество',
        picture: ''
    },
    {
        date: '07.11.2023',
        title: 'набираем людей с мощными видеокартами',
        picture: ''
    },
    {
        date: '09.11.2023',
        title: 'выпускаем первую серию в 4К.',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/33.png'
    },
    {
        date: '09.11.2023',
        title: 'улучшением качества занимались inspiredscout и MAXXOS',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/34.png'
    },
    {
        date: '13.11.2023',
        title: 'устроили аукцион на следующее аниме в 4К',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/36.png'
    },
    {
        date: '15.11.2023',
        title: 'немного изменили дизайн, чтобы не быть копией кинопоиска',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/35.png'
    },
    {
        date: '16.11.2023 -',
        title: 'продолжаем делать аниме в 4К',
        picture: ''
    },
    {
        date: '31.12.2023',
        title: 'отмечаем новый год с более чем 14 аниме в 4К',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/37.png'
    },
    {
        date: '10.02.2024',
        title: 'разрабатываем мобильное приложение',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/38.png'
    },
    {
        date: '01.04.2024',
        title: 'проводим 1-апрельский ивент в ходе которого, можно было отправить друзьям скримеры',
        picture: 'https://s3.timeweb.com/24399392-anitype-videos/test/birthday2024/history/39.png'
    },
    {
        date: 'что будет дальше?',
        title: '???',
        picture: ''
    },
]

export const EVENT_GAME_ENABLED = true