import React, {useEffect, useState} from 'react';
import {Anime} from "../../types";
import AnimePlayButtonBlock from "./AnimePlayButtonBlock";

const AnimeFlyPlayBlock = ({anime}: {
    anime: Anime
}) => {
    let [show, setShow] = useState(false)

    function handleScroll() {
        const currentScrollPosition = window.scrollY;
        if (currentScrollPosition > 515) {
            setShow(true)
        } else setShow(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={"anime_fixed_play_block"} style={{
            bottom: show ? 70 : -70
        }}>
            <AnimePlayButtonBlock anime={anime}/>
        </div>
    );
};

export default AnimeFlyPlayBlock;