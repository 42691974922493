import React, {useEffect, useState} from 'react';
import '../styles/event_page.css'
import axios from "../core/axios";
import {BACKEND} from "../consts";
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";

const UseJokeMenu = ({setOpen, username}: {
    setOpen: (value: boolean) => void,
    username: string
}) => {
    let nav = useNavigate()

    let [result, setResult] = useState('')

    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [inventory, setInventory] = useState<any[]>([])
    let [shopItems, setShopItems] = useState<any[]>([])

    async function doJoke(key: string, username: string) {
        axios({
            url: BACKEND + `events/my/inventory/usejoke?username=${username}&key=${key}`
        })
            .then(r => setOpen(false))
            .catch(e => {
                let msg = e.response.data
                if (typeof msg != "string") msg = JSON.stringify(e.response.data)
                if (msg?.length < 3) msg = "Произошла ошибка. Возможно пользователь оффлайн."
                setResult(msg)
            })
    }

    async function fetchData() {
        if (cookies.access) {
            // axios({
            //     url: BACKEND + 'users/me'
            // })
            //     .then(r => setUserInfo(r.data))
            //     .catch(ignored => ignored)
            axios({
                url: BACKEND + 'events/store'
            })
                .then(r => setShopItems(r.data))
                .catch(ignored => ignored)

            axios({
                url: BACKEND + 'events/my/inventory'
            })
                .then(r => {
                    setInventory(r.data)
                    if (r.data.length === 0) nav('/event')
                })
                .catch(ignored => ignored)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className={"use_joke_menu_bg"}>
            <div className="use_joke_menu">
                {
                    result && <p style={{
                        maxWidth: 300
                    }}>{result}</p>
                }

                <div className={"anime_play_block_services_popup_service_back"} onClick={() => {
                    setOpen(false)
                }}>
                    <FontAwesomeIcon icon={faArrowLeftLong}/>
                    <p>Назад</p>
                </div>

                {
                    inventory.filter(v => !shopItems.find(s => s.key === v.key)?.title?.includes('щит')).map(v =>
                        <div className="use_joke_menu_el" onClick={() => {
                            doJoke(v.key, username)
                        }}>
                            <p>{shopItems.find(s => s.key === v.key)?.title}</p>
                        </div>)
                }
                {
                    inventory.length === 0 &&
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Loader/>
                    </div>
                }
            </div>
        </div>
    );
};

export default UseJokeMenu;