export function pluralizeResponses(num: number): string {
    const cases = [2, 0, 1, 1, 1, 2];
    return num + " " + ["ответ", "ответа", "ответов"][(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
}

export function pluralizeEpisodes(n: number) {
    let absN = Math.abs(n);
    absN %= 100;
    if (absN >= 5 && absN <= 20) {
        return 'эпизодов';
    }
    absN %= 10;
    if (absN === 1) {
        return 'эпизод';
    }
    if (absN >= 2 && absN <= 4) {
        return 'эпизода';
    }
    return 'эпизодов';
}