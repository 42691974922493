import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectUserInfo} from "../lib/redux";
import {Comment} from "../types";
import {getDeleteCommentAbility} from "../api/comments";
import axios from "../core/axios";
import {useNavigate} from "react-router-dom";


const CommentMoreButton = ({comment}: {
    comment?: Comment
}) => {
    let nav = useNavigate()

    let userInfo = useSelector(selectUserInfo)

    let [shown, setShown] = useState(false)

    async function deleteComment() {
        axios({
            url: `comments/${comment?.id}`,
            method: 'delete'
        }).catch(e => e)
    }

    return (
        <>
            {
                getDeleteCommentAbility(userInfo, comment) && <>
                    <FontAwesomeIcon icon={faEllipsisVertical} onClick={() => setShown(!shown)}/>

                    <div className={shown ? "top_right_abs_menu" : "top_right_abs_menu hidden"}>

                        <div className="top_right_abs_menu_el" onClick={() => {
                            setShown(false)
                            deleteComment()
                            if (comment?.target?.includes('anime')) {
                                nav(`/anime/${comment?.target?.split('anime')?.pop()}`)
                            } else {
                                nav(`/profile/${userInfo?.username}`)
                            }
                        }}>
                            <p>Удалить</p>
                        </div>

                    </div>
                </>
            }
        </>
    );
};

export default CommentMoreButton;