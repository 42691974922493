import React, {useState} from 'react';
import AutoFadeTopBar from "../../AutoFadeTopBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTableCellsLarge} from "@fortawesome/free-solid-svg-icons/faTableCellsLarge";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ViewListImg from "../../assets/ViewListImg";
import ViewCardsImg from "../../assets/ViewCardsImg";
import SunSvg from "../../assets/SunSvg";
import MoonSvg from "../../assets/MoonSvg";

const UserMenuSettingsViewPage = ({changeTheme}: {
    changeTheme: (new_theme: string) => void
}) => {
    let [viewMode, setViewMode] = useState<'cards' | 'column'>(
        localStorage.getItem('list_view_mode') === 'cards' ? 'cards' : 'column'
    )
    let [theme, setTheme] = useState<'dark' | 'light'>(
        localStorage.getItem('theme') === 'light' ? "light" : 'dark'
    )

    return (
        <>
            <AutoFadeTopBar title={`Вид`} alwaysShown={true}/>

            <div className="select_page_content spc">

                <div className="settings_view_pos_style">
                    <div className={viewMode === 'column' ? 'active' : ''} onClick={() => {
                        setViewMode('column')
                        localStorage.setItem('list_view_mode', 'column')
                    }}>
                        <ViewListImg size={170}/>
                        <p>Список</p>
                    </div>
                    <div className={viewMode === 'cards' ? 'active' : ''} onClick={() => {
                        setViewMode('cards')
                        localStorage.setItem('list_view_mode', 'cards')
                    }}>
                        <ViewCardsImg size={170}/>
                        <p>Карточки</p>
                    </div>
                </div>

                <div className="settings_view_pos_style">
                    <div className={theme === 'light' ? 'active' : ''} onClick={() => {
                        setTheme("light")
                        changeTheme('light')
                        localStorage.setItem('theme', 'light')
                    }} style={{paddingTop: 20}}>
                        <div className="settings_view_pos_style_bx">
                            <SunSvg size={100} color={'#000'}/>

                        </div>
                        <p>Светлая (beta)</p>
                    </div>
                    <div className={theme === 'dark' ? 'active' : ''} onClick={() => {
                        setTheme("dark")
                        changeTheme('dark')
                        localStorage.setItem('theme', 'dark')
                    }} style={{paddingTop: 20}}>
                        <div className="settings_view_pos_style_bx">
                            <MoonSvg size={100} color={'#000'}/>
                        </div>

                        <p>Темная</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserMenuSettingsViewPage;