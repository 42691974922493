import React, {useEffect, useState} from 'react';
import '../styles/toggler.css'

interface Props {
    def: boolean,
    cb: any
}

const Toggler = (props: Props) => {
    let [active, setActive] = useState<boolean>(props.def)

    useEffect(() => {
        setActive(props.def)
    }, [props.def]);

    return (
        <div className={active ? "toggler active" : "toggler"} onClick={() => {
            props.cb(!active)
            setActive(!active)
        }}>
            <div className={active ? "toggler_c active" : "toggler_c"}></div>
        </div>
    );
};

export default Toggler;