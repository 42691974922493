import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {Anime, Selection} from "../types";
import {getAnimeIds, getFoldersMy, getSavedSelections} from "../api/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import Loader from "./Loader";
import {proxyImg} from "../funcs/poster";
import {getSystemStatusFolder} from "../funcs/folders";
import {faBookmark} from "@fortawesome/free-solid-svg-icons/faBookmark";
import {faBoxesStacked, faFaceFrownOpen, faNoteSticky} from "@fortawesome/free-solid-svg-icons";
import M3OutlinedButton from "./m3/M3OutlinedButton";

const MyPageSelectionsSavedBlock = () => {
    let nav = useNavigate()
    let [cookies, setCookie, removeCookie] = useCookies(['access', 'refresh', 'refreshTime'])

    let [items, setItems] = useState<Selection[]>([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (async () => {
            if (!cookies.access) return;
            setIsLoading(true)

            let itemsS = await getSavedSelections(0, 10);
            setItems(itemsS)
            setIsLoading(false)
        })()


    }, [cookies.access])


    return (
        <div className={"my_page_block"}>
            <p className="my_page_block_title">
                <div>
                    {/*<FontAwesomeIcon icon={faBookmark} />*/}
                    <FontAwesomeIcon icon={faNoteSticky} />
                    <span className={"my_page_block_title_text"}>Сохраненные подборки</span>
                </div>
                {/*<p className="my_page_block_title_show_all blue_button" onClick={() => {}}*/}
                {/*   style={{filter: 'blur(1px)'}}>Посмотреть все</p>*/}

                <M3OutlinedButton onClick={() => {

                }} text={'Посмотреть все'} disabled={true}/>
            </p>
            {
                isLoading ? <div className="my_page_block_list_loading">
                        <Loader/>
                    </div> :
                    <div className="my_page_block_list">
                        {
                            items?.map(el =>
                                <div className="home_block_list_selection_el" key={el.id}
                                     onClick={() => nav(`/selection/${el.id}`)}>

                                    <div className="home_block_list_selection_el_poster">
                                        <div className="home_block_list_selection_el_poster_img" style={{
                                            backgroundImage: `url('${'https://s2.anitype.site/media/selection/' + el?.id}')`
                                        }}/>

                                        <div className="home_block_list_selection_el_poster_grad"></div>

                                        <div className="home_block_list_selection_el_poster_info">
                                            <p className="home_block_list_selection_el_title">{el.title}</p>
                                            <p className="home_block_list_selection_el_desc">{el.text}</p>
                                        </div>
                                    </div>

                                </div>)
                        }
                        {
                            items?.length === 0 &&
                            <div className="my_page_block_list_not_found">
                                <FontAwesomeIcon icon={faFaceFrownOpen} />
                                <p>Нет элементов</p>
                            </div>
                        }
                    </div>
            }

        </div>
    );
};

export default MyPageSelectionsSavedBlock;